(function() {
	var remarketApp = angular.module('remarketApp');

	var individualCreationService = function($http, $rootScope, Upload) {
		var service = this;

		function validate(individual, documentFile) {
			var valid = true;
			if (!documentFile && !individual.documentFile) {
				remarketApp.error('Você deve enviar, digitalizado, um documento com foto atual.');
				valid = false;
			}

			return valid;
		}

		service.saveIndividual = function(individual) {
			var postIndividual = JSON.parse(JSON.stringify(individual));
			$http.post('/api/individual/' + postIndividual.id, postIndividual).then(function() {
				remarketApp.success('Dados da empresa salvos com sucesso.');
			}, remarketApp.handleError);
		};

		service.finalizeDataStep = function(individual, documentFile) {
			if (!validate(individual, documentFile)) {
				return;
			}

			//Using the same service as companies
			Upload.upload({
				url: '/api/company/contract/upload',
				method: 'POST',
				fields: {
					"Content-Type": documentFile.type != '' ? documentFile.type : 'application/octet-stream',
				},
				data: {
					'contract': documentFile
				}
			}).then(function(response) {
					individual.document = response.config.url + response.config.fields.key;
					service.individual = individual;

					remarketApp.go('landingPage.individualAddress');
				}, function () {
					remarketApp.error('Ocorreu um erro ao tentar enviar o documento.');
			});
		};

		service.finalizeAddressStep = function (individual) {
			service.individual = individual;
			
			$http.post('/api/individual/create', service.individual).then(function(response) {
				service.individual = response.data;
				remarketApp.getUser().askCompanyData = false;
				remarketApp.success('Em até 48 horas iremos validar sua conta e lhe informar o resultado por e-mail. ' +
					'Por favor, aguarde.');
				remarketApp.logout();
			}, remarketApp.handleError);
		};

		service.getIndividual = function(callback, individualId) {
			if (individualId) {
				return loadindividual(individualId, callback);
			}
			if (service.individual) {
				return callback(service.individual);
			}
			//Still using the company as a way to keep some data
			$http.get('/api/company/my-company').then(function (response) {
				service.individual = response.data;
				callback(service.individual);
			}, remarketApp.handleError);
		};

		$rootScope.$on('remarket-logout', function() {
			delete service.individual;
		});
	};
	individualCreationService.$inject = ['$http', '$rootScope', 'Upload'];
	remarketApp.service('individualCreationService', individualCreationService);
}());