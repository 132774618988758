(function() {
	var remarketApp = angular.module('remarketApp');

	var termsOfUseController = function($http) {
		var ctrl = this;

		ctrl.accept = function () {
			$http.post('/api/company/accept-terms-of-use/' + remarketApp.currentUser.companyId).then(function () {
				remarketApp.success('Vamos começar!');
				
				if (remarketApp.getUser().companyType != 'INDIVIDUAL_BUYER') {
					remarketApp.go('landingPage.companyData');
				} else {
					remarketApp.go('landingPage.individualData');
				}
				
				remarketApp.getUser().showTerms = false;
			}, remarketApp.handleError);
		};

		ctrl.decline = function () {
			remarketApp.error('Para utilizar o sistema, você deve estar de acordo com os termos de uso.');
			remarketApp.logout();
		};
	};
	termsOfUseController.$inject = ['$http'];
	remarketApp.controller('termsOfUseController', termsOfUseController);

	var termsOfUseStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.termsOfUseBuyer', {
			url: '/terms-of-use-buyer',
			templateUrl: 'view/public/login/terms-of-use-buyer.html',
			controller: termsOfUseController,
			controllerAs: 'termsOfUseController'
		});
	};
	termsOfUseStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(termsOfUseStateProvider);
}());