(function() {
	var remarketApp = angular.module('remarketApp');

	var principalService = function($http, $state, $localStorage, $sessionStorage, $location,
									$rootScope, $firebaseAuth) {
		var service = this;
		service.user = null;

		service.authenticate = function(user) {
			$http({
				method: 'POST',
				url: '/api/webapp/users/authentication',
				data: user
			}).then(function(response) {
				setSessionInfo(response.data, user.email);
			}, function(error) {
				remarketApp.handleError(error);
			});
		}

		var setSessionInfo = function(user, email) {
			service.user = user;
			service.user.email = email;

			remarketApp.currentUser = service.user;
			$http.defaults.headers.common.Authorization = 'Bearer ' + service.user.jws;

			$http({
				method: 'GET',
				url: '/api/auctions/token'
			}).then(function(response) {
				var auth = $firebaseAuth(firebase.auth());
				auth.$signInWithCustomToken(response.data.token).catch(function(error) {
					console.error("Authentication failed:", error);
				});
			}, function(error) {
				remarketApp.handleError(error);
			});

			// hold access token on browser or session
			user.rememberMe ? $localStorage.jws = service.user.jws : $sessionStorage.jws = service.user.jws;

			$rootScope.$broadcast('remarket-login', service.user);

			//Will redirect if any data is needed to fulfill the profile
			redirectToSignUpCompletion();
		}

		function redirectToSignUpCompletion() {
			// show terms of use, if needed
			if (service.user.showTerms) {
				event.preventDefault();
				if(service.userIsSeller()) {
					return $state.go('landingPage.termsOfUseSeller');
				} else {
					return $state.go('landingPage.termsOfUseBuyer');
				}
			}
			// show company data form, if needed
			if (service.user.askCompanyData) {
				event.preventDefault();

				if (service.user.companyType != 'INDIVIDUAL_BUYER') {
					return $state.go('landingPage.companyData');
				} else {
					return $state.go('landingPage.individualData');
				}
			}
		}

		service.userIsSeller = function() {
			let sellerTypes = ['DEALER', 'FLEET_OWNER'];
			return sellerTypes.indexOf(service.user.companyType) > -1;
		}

		service.userIsBuyer = function() {
			let buyerTypes = ['STORE', 'INDIVIDUAL_BUYER'];
			return buyerTypes.indexOf(service.user.companyType) > -1;
		}

		service.logout = function() {
			delete $localStorage.token;
			delete $sessionStorage.token;
			delete service.user;

			let path = $location.url();

			//The only accessible addresses without an open session are new-password and internal-user
			if (path.lastIndexOf('/new-password') != 0 && path.lastIndexOf('/internal-user') != 0) {
				$state.go('landingPage.login');
			}

			$rootScope.$broadcast('remarket-logout', service.user);
		};

		service.validateToken = function() {
			let rememberMe = false;
			
			// retrieving token from storage
			let jws = $localStorage.jws;
			if (jws) {
				rememberMe = true;
			} else {
				jws = $sessionStorage.jws;
			}

			if (jws) {
				// first we set to all requests, if it is a good jws it is fine
				// if it is a bad jws, 401 will be returned, and it is fine as well
				$http.defaults.headers.common.Authorization = 'Bearer ' + jws;
				// get email from jws

				$http({
					method: 'POST',
						url: '/api/webapp/users/authentication/validate',
						data: {
							'jws': jws
						}
				}).then(function(response) {
					let user = response.data;
					user.jws = jws;
					setSessionInfo(user);
				}, function(error) {
					remarketApp.handleError(error);
					service.logout(false);
				});
			} else {
				service.logout(false);
			}
		};

		service.getUser = function() {
			return service.user;
		};

		service.isAdmin = function() {
			return service.user ? service.user.profileType == 'ADMIN' : false;
		};

		$rootScope.$on('$stateChangeStart', function(event, toState) {
			remarketApp.showingPanel = toState.name.lastIndexOf('landingPage.panel', 0) == 0;
			if (service.user && remarketApp.showingPanel) {
				//Will redirect if any data is needed to fulfill the profile
				redirectToSignUpCompletion();
			}
		});
	};
	principalService.$inject = ['$http', '$state', '$localStorage', '$sessionStorage', '$location',
		'$rootScope', '$firebaseAuth'];
	remarketApp.service('principalService', principalService);

	remarketApp.run(['principalService', '$location', function(principalService, $location) {
		remarketApp.authenticate = principalService.authenticate;
		remarketApp.logout = principalService.logout;
		remarketApp.getUser = principalService.getUser;
		remarketApp.isAdmin = principalService.isAdmin;
		principalService.validateToken();
	}]);
}());
