(function() {
	var remarketApp = angular.module('remarketApp');

	var remarketHelperService = function($state, $localStorage, $sessionStorage, growl, tmhDynamicLocale, envConfig) {
		var service = this;

		tmhDynamicLocale.set('pt-br');

		service.handleError = function(errorResponse) {
			if (errorResponse.status == '401') {
				delete $localStorage.token;
				delete $sessionStorage.token;
				$state.go('landingPage.login');
				if (errorResponse.config.url == '/api/webapp/users/authenticate') {
					return service.error(errorResponse.data.message);
				}
				service.error('Para realizar esta ação, por favor, se identifique.');
			} else if (errorResponse.data.messages && errorResponse.data.messages.length > 0) {
				for (var i = 0; i < errorResponse.data.messages.length; i++) {
					service.error(errorResponse.data.messages[i]);
				}
			} else if (errorResponse.data.message) {
				service.error(errorResponse.data.message);
			} else {
				service.error('Ocorreu um erro na sua requisição.');
			}
		};

		service.success = function(message) {
			growl.success(message);
		};

		service.info = function(message) {
			growl.info(message);
		};

		service.warning = function(message) {
			growl.warning(message);
		};

		service.error = function(message) {
			growl.error(message);
		};

		service.go = function (state, params) {
			$state.go(state, params);
		};
	};
	remarketHelperService.$inject = ['$state', '$localStorage', '$sessionStorage', 'growl', 'tmhDynamicLocale', 'envConfig'];
	remarketApp.service('remarketHelperService', remarketHelperService);

	remarketApp.run(['remarketHelperService', 'envConfig', function(remarketHelperService, envConfig) {
		moment.locale('pt-BR');
		remarketApp.handleError = remarketHelperService.handleError;
		remarketApp.success = remarketHelperService.success;
		remarketApp.info = remarketHelperService.info;
		remarketApp.warning = remarketHelperService.warning;
		remarketApp.error = remarketHelperService.error;
		remarketApp.go = remarketHelperService.go;
		remarketApp.env = envConfig.env;
		remarketApp.clientInstance = envConfig.clientInstance;
		
		remarketApp.isDevelopment =  function () {
			return remarketApp.env == 'development';
		};
		
		remarketApp.isHomolog =  function () {
			return remarketApp.env == 'homolog';
		};
		
		remarketApp.isProduction =  function () {
			return remarketApp.env == 'production';
		};
	}]);
}());
