(function () {
	var remarketApp = angular.module('remarketApp');

	var tradesController = function($http, $scope, enumService) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			$http.get('/api/trades/').then(function(response) {
				ctrl.trades = response.data;
				_.forEach(ctrl.trades, function (trade) {
					trade.currentVehicle = 0;
					trade.selectedStatus = enumService.findTradeStatusByValue(trade.tradeStatus);
				})
			}, remarketApp.handleError);
		}

		ctrl.previousVehicle = function(trade) {
			if (trade.currentVehicle == 0) {
				trade.currentVehicle = trade.vehicles.length;
			}
			trade.currentVehicle--;
		};

		ctrl.nextVehicle = function(trade) {
			trade.currentVehicle++;
			if (trade.currentVehicle == trade.vehicles.length) {
				trade.currentVehicle = 0;
			}
		};

		ctrl.openVehicleDetails = function(trade) {
			document.body.style.overflow = 'hidden';
			ctrl.vehicleDetailsId = trade.vehicles[trade.currentVehicle].id;
		};

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
		};

		ctrl.statusSelected = function(option, trade) {
			if (trade.tradeStatus == option.value) {
				return;
			}
			$http.post('/api/trades/update-status', {
				tradeId: trade.id,
				tradeStatus: option.value
			}).then(function() {
				remarketApp.success('Status atualizado com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.tradeStatus = enumService.tradeStatus;

		$scope.$on('remarket-login', init);
		init();
	};
	tradesController.$inject = ['$http', '$scope', 'enumService'];
	remarketApp.controller('tradesController', tradesController);

	var inspectionsStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiationsHistory', {
			url: '/negotiation/admin/history',
			templateUrl: 'view/panel/negotiation/admin/trade/trades.html',
			controller: tradesController,
			controllerAs: 'tradesController'
		});
	};
	inspectionsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionsStateProvider);
}());