(function() {
	var remarketApp = angular.module('remarketApp');

	var registerController = function($http) {
		var ctrl = this;
		ctrl.storeUser = {};

		ctrl.companyUser = {};

		ctrl.registerCompanyUser = function () {
			register(ctrl.companyUser);
		};

		ctrl.registerStoreUser = function () {
			register(ctrl.storeUser);
		};

		ctrl.showSellerRegister = function() {
			return !remarketApp.clientInstance;
		}

		function validateUser(user) {
			var ok = true;
			if (user.companyType == 'SELLER' && !user.companyType) {
				remarketApp.error('Informe se a empresa é do tipo concessionária ou frotista.');
				ok = false;
			}
			if (user.userSystemProfileType == 'BUYER' && !user.companyType) {
				remarketApp.error('Informe se o perfil é do tipo lojista ou pessoa física.');
				ok = false;
			}
			if (!user.name || user.name.length < 5) {
				remarketApp.error('O nome deve conter ao menos 5 caracateres.');
				ok = false;
			}
			if (!user.cpf || user.cpf.length != 11) {
				remarketApp.error('O cpf deve ser válido e estar no formato 000.000.000-00.');
				ok = false;
			}
			if (!user.email || user.email.length < 5) {
				remarketApp.error('O e-mail deve estar no formato exemplo@exemplo.com.');
				ok = false;
			}
			return ok;
		}

		function register(user) {
			if (!validateUser(user)) {
				return;
			}
			$http.post('/api/webapp/users/registration', user).then(function() {
				remarketApp.success('Acabamos de lhe enviar um e-mail com instruções para finalizar o cadastro.');
				remarketApp.go('landingPage.login');
			}, remarketApp.handleError);
		}
	};
	registerController.$inject = ['$http'];
	remarketApp.controller('registerController', registerController);

	var registerStateProvider = function ($stateProvider) {
		$stateProvider.state('landingPage.register', {
			url: '/register',
			templateUrl: 'view/public/register/register.html',
			controller: registerController,
			controllerAs: 'registerController'
		});
	};
	registerStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(registerStateProvider);
}());