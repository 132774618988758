(function() {
	var remarketApp = angular.module('remarketApp');

	var userProfileTypeEnumService = function() {
		var service = this;

		var profileTypes = [
			{ value: 'ADMIN', description: 'Administrador' },
			{ value: 'ACCOUNT_ADMIN', description: 'Administrador da Conta' },
			{ value: 'INSPECTION_DETAILS', description: 'Acesso Laudos' },
			{ value: 'INTERNAL_BUYER', description: 'Comprador Interno' },
			{ value: 'INSPECTIONER', description: 'Inspecionador' }
		];

		service.getProfileTypes = function() {
			return profileTypes;
		}

		service.findByType = function(type) {
			for (var i = 0; i < profileTypes.length; i++) {
				if (profileTypes[i].value == type) {
					return profileTypes[i];
				}
			}
			return 'Tipo desconhecido';
		};
	};
	remarketApp.service('userProfileTypeEnumService', userProfileTypeEnumService);


	var profileTypeFilter = function(userProfileTypeEnumService) {
		return function(value) {
			let profileType = userProfileTypeEnumService.findByType(value);
			return profileType.description || 'Tipo desconhecido';
		}
	};
	profileTypeFilter.$inject = ['userProfileTypeEnumService'];
	remarketApp.filter('profileTypeFilter', profileTypeFilter);
}());