(function() {
	var remarketApp = angular.module('remarketApp');
	
	function starRatingController() {
		var ctrl = this;
		ctrl.max = 3;

		function getStars(status) {
			ctrl.description = 'Estado de conservação: ';
			if (status == 'BAD' || status == 'BROKEN') {
				ctrl.description += 'ruim';
				return 1;
			}
			if (status == 'REGULAR' || status == 'SMASHED' || status == 'GRATED') {
				ctrl.description += 'regular';
				return 2;
			}
			if (status == 'GOOD' || status == 'PAINTING') {
				ctrl.description += 'bom';
				return 3;
			}
			ctrl.description = 'Não encontrado';
			return 0;
		}

		function updateStars() {
			ctrl.stars = [];
			var rate = getStars(ctrl.ratingValue);
			for (var i = 0; i < ctrl.max; i++) {
				ctrl.stars.push({
					filled: i < rate
				});
			}
		}

		ctrl.$onChanges = function (changedObject) {
			if (changedObject.ratingValue && changedObject.ratingValue.currentValue) {
				if (!changedObject.ratingValue.isFirstChange()) {
					updateStars();
				}
			}
		};
	}

	remarketApp.component('starRating', {
		bindings: {
			ratingValue: '<'
		},
		templateUrl: 'component/star-rating/star-rating.html',
		controller: starRatingController
	});

}());