(function () {
	var remarketApp = angular.module('remarketApp');

	var vehiclesInspectionsController = function($http, $scope, inspectionService) {
		var ctrl = this;
		
		ctrl.company = null;
		ctrl.location = null;
		ctrl.plate = null;
		ctrl.inspectionOrigin = null;
		ctrl.inspectionStatus = null;
		ctrl.sold = null;
		ctrl.status = null;
		ctrl.description = null;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			ctrl.load();
			
			if (remarketApp.isAdmin()) {
				ctrl.viewDescription = 'Central de gerenciamento de vistorias.';
			} else {
				ctrl.viewDescription = 'Gerencie suas vistorias a partir da listagem abaixo, ou crie uma ' +
					'nova vistoria.';
			}
			ctrl.emptyList = 'Nenhuma vistoria encontrada.';
		}
		
		ctrl.pagination = function() {
			ctrl.load();
		}
		
		ctrl.setOrigin = function(origin) {
			ctrl.inspectionOrigin = origin;
			ctrl.load();
		}
		
		ctrl.setStatus = function(status) {
			ctrl.inspectionStatus = status;
			ctrl.load();
		}

		ctrl.setVehicleStatus = function(status) {
			ctrl.status = status;
			ctrl.load();
		}

		ctrl.setSold = function(sold) {
			ctrl.sold = sold;
			ctrl.load();
		}
		
		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}
		
		ctrl.load = function() {
			ctrl.vehicles = {};
			
			$http.get('/api/vehicles/findAllVehiclesPaginated', {
				params: {
					company: ctrl.company,
					location: ctrl.location,
					plate: ctrl.plate,
					description: ctrl.description,
					status: ctrl.inspectionStatus,
					sold: ctrl.sold,
					status: ctrl.status,
					origin: ctrl.inspectionOrigin,
					page: ctrl.currentPage, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.vehicles = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		};
		
		ctrl.deleteNewvehicle = function(id) {
			$http.post('/api/vehicles/deleteNewvehicle/' + id).then(function(response) {
				remarketApp.success('Vistoria removida com sucesso.');
				ctrl.load();
			}, remarketApp.handleError);
		}
		
		ctrl.showVisualizeButton = function(vehicle) {
			return vehicle.inspectionStatus == 'PENDING_PAYMENT' || vehicle.inspectionStatus == 'SCHEDULED';
		};
		
		ctrl.showVisualizeVehicleDetailsButton = function(vehicle) {
			return vehicle.inspectionStatus == 'INSPECTED' && vehicle.inspectionOrigin == 'GETEK';
		}; 

		ctrl.showVisualizeMobileButton = function(vehicle) {
			return vehicle.inspectionStatus == 'INSPECTED' && vehicle.inspectionOrigin == 'MOBILE';
		}; 
		
		ctrl.showReviewButton = function(vehicle) {
			return (vehicle.inspectionStatus == 'INSPECTED' || vehicle.inspectionStatus == 'PARTIALLY_INSPECTED') && vehicle.inspectionOrigin == 'GETEK';
		}; 
		
		ctrl.showApprovalButton = function(vehicle) {
			return vehicle.inspectionStatus == 'PENDING_APPROVAL' && vehicle.inspectionOrigin == 'GETEK';
		};  

		ctrl.showNoResultsLine = function() {
			return !ctrl.vehicles || ctrl.vehicles.length == 0;
		}
		
		ctrl.schedulevehicle = function (inspectionId) {
			inspectionService.loadvehicle(vehicleId, function() {
				remarketApp.go('landingPage.panel.vehicleScheduling', {
					inspectionId: inspectionId
				});
			});
		};
		
		ctrl.closeMobileInspectionDetails = function() {
			document.body.style.overflow = 'hidden';
			ctrl.mobileInspectionId = null;
		}

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
		}

		$scope.$on('remarket-login', init);
		init();
	};
	vehiclesInspectionsController.$inject = ['$http', '$scope', 'inspectionService'];
	remarketApp.controller('vehiclesInspectionsController', vehiclesInspectionsController);

	var vehiclevehiclesStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspections-vehicles', {
			url: '/inspections-vehicles',
			templateUrl: 'view/panel/inspection/list/vehicles/inspections.html',
			controller: vehiclesInspectionsController,
			controllerAs: 'vehiclesInspectionsController'
		});
	};
	vehiclevehiclesStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(vehiclevehiclesStateProvider);
}());