(function() {
	var remarketApp = angular.module('remarketApp');
	
	var dialogCtrl = function($scope, $mdDialog) {
		var ctrl = this;
		
		var init = function () {
			console.log("init");
			$scope.showAdvanced = function(ev) {
				$mdDialog.show({
					controller : DialogController,
					parent : angular.element(document.body),
					targetEvent : ev,
					clickOutsideToClose : true
				});
			};

			function DialogController($scope, $mdDialog) {
				$scope.hide = function() {
					$mdDialog.hide();
				};

				$scope.cancel = function() {
					$mdDialog.cancel();
				};
			};
		};
	};
	dialogCtrl.$inject = ['$scope', '$mdDialog'];
	
//	remarketApp.component('f-dialog', {
//		controller: dialogCtrl,
//		templateUrl : 'component/dialog/dialog.html'
//	});
	
	remarketApp.controller('AppCtrl', dialogCtrl);
}());