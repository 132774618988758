(function() {
	var remarketApp = angular.module('remarketApp');

	var fadeInCtrl = function() {
		return {
			restrict: 'A',
			link: function(scope, element) {
				var imgTag = element[0];

				scope.$watch(function() {
					return element.attr('src');
				}, function() {
					imgTag.className = imgTag.className.replace('ng-hide-add', '');
					imgTag.className += " ng-hide-remove";
				});

				imgTag.addEventListener('load', function() {
					imgTag.className = imgTag.className.replace('ng-hide-remove', '');
					imgTag.className += " ng-hide-add";
				});
			}
		}
	};
	remarketApp.directive('fadeIn', fadeInCtrl);
}());