(function() {
	var remarketApp = angular.module('remarketApp');
	var usersInternalController = function($http, growl, enumService) {
		var ctrl = this;

		function init() {
			ctrl.users = {};
			$http.get('/api/webapp/users-internal/').then(function(response) {
				ctrl.users = response.data;
			}, remarketApp.handleError);
		}

		ctrl.getUserProfileTypeDescription = enumService.getUserProfileTypeDescription;

		init();
	};
	usersInternalController.$inject = ['$http', 'growl', 'enumService'];
	remarketApp.controller('usersInternalController', usersInternalController);

	var userStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.users-internal', {
				url: '/users-internal',
				templateUrl: 'view/panel/users-internal/users-internal.html',
				controller: usersInternalController,
				controllerAs: 'usersInternalController'
			});
	};
	userStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(userStateProvider);
}());
