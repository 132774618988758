(function() {
	var remarketApp = angular.module('remarketApp');

	var auctionCtrl = function($firebaseObject, $scope, $interval, envConfig) {
		var ctrl = this;
		//ver onde colcoar i global
		var contMarket = 24;
		var contClient = 24;
		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.notification = 'Carregando lotes...';
			ctrl.auctionRunning = false;
			ctrl.showCountDown = false;
			ctrl.numberOfItems = 0;
			ctrl.numberOfItemsMM = 0;

			firebase.database()
					.ref(envConfig.envFirebase)
					.child('auction')
					.on('value', function(snapshot) {
				if (!ctrl.auctionRunning) {
					ctrl.auctionRunning = snapshot.exists();
				}
				ctrl.auction = snapshot.val();
				ctrl.auctionLoaded = true;
				
				
				loadAuction();
			});
			

			firebase.database()
					.ref(envConfig.envFirebase)
					.child('client-auction')
					.on('value', function(snapshot) {
				if (!ctrl.auctionRunning) {
					ctrl.auctionRunning = snapshot.exists();
				}

				ctrl.clientAuctions = snapshot.val();
				ctrl.clientAuctionsLoaded = true;
				
				if(ctrl.numberOfItems <=0){
					loadClientAuctions();
				}else if(ctrl.numberOfItems < 24){
					contClient = contClient - ctrl.numberOfItems;
					loadClientAuctions();
				}
			});
			
		}

		function loadAuction() {
			ctrl.auctionItems = [];
			if (ctrl.auctionLoaded && ctrl.auction && ctrl.auction.auctionItemsIds) {
				var index = 0;
				ctrl.numberOfItems = ctrl.auction.auctionItemsIds.length;
				ctrl.numberOfItemsMM = ctrl.auction.auctionItemsIds.length;
				_.forEach(ctrl.auction.auctionItemsIds, function(auctionItemId) {
					var auctionItem = $firebaseObject(firebase.database()
						.ref(envConfig.envFirebase).child('auctionItem/' + auctionItemId));
					if(index < contMarket){
						
						auctionItem.$loaded().then(function() {
							var isIndividualBuyer = remarketApp.getUser().companyType == 'INDIVIDUAL_BUYER';
							//Removes items that should not be presented to individual buyers
							if (isIndividualBuyer && !auctionItem.showToIndividuals) {
								return;
							}
	
							ctrl.auctionItems.push(auctionItem);
							updateNotification();
						});
					}

					reloadDescription();
					
					index++;
				});
								
			}
			if(contMarket >= ctrl.auction.auctionItemsIds.length){
				ctrl.numberOfItems = ctrl.auction.auctionItemsIds.length;
				//loadClientAuctions();
			}
		}
		


		function loadClientAuctions() {
			ctrl.clientAuctionItems = [];
			if (ctrl.clientAuctionsLoaded && ctrl.clientAuctions) {
				var index = 0;
				Object.entries(ctrl.clientAuctions).forEach(
					([auctionKey, auctionValue]) => {
						Object.entries(auctionValue.items).forEach(
							([key, value]) => {

								//procurar break
								if(index < contClient){
									
									var clientAuctionItem = $firebaseObject(firebase.database()
										.ref(envConfig.envFirebase).child('client-auction/' + auctionKey + '/items/' + key));
	
									clientAuctionItem.$loaded().then(function() {
										var isIndividualBuyer = remarketApp.getUser().companyType == 'INDIVIDUAL_BUYER';
										//Removes items that should not be presented to individual buyers
										if (isIndividualBuyer && !clientAuctionItem.showToIndividuals) {
											return;
										}
	
										ctrl.clientAuctionItems.push(clientAuctionItem);
										updateNotification();
									});	
	
									
									reloadDescription();
								}
								
								index++;
							}
							);
							
					}
				);
				ctrl.numberOfItems = ctrl.numberOfItems + contClient;
				contClient = contClient + index;
			}
		}
		
		function reloadDescription() {
			if (!ctrl.auctionRunning) {
				ctrl.notification = 'Nenhum lote em negociação';
				return;
			}

			if (ctrl.auction) {
				ctrl.startDate = new Date(ctrl.auction.startDate);
				ctrl.endDate = new Date(ctrl.auction.endDate);

				updateCountdown();
				$interval(updateCountdown, 1000);
			}

			if (ctrl.numberOfItems > 0) {
				updateNotification();

				ctrl.showCountDown = true;
			} else {
				ctrl.notification = 'Nenhum lote em negociação';
			}
		}

		function updateNotification() {
			if (ctrl.numberOfItems == 1) {
				ctrl.notification = '1 lote em negociação';
			} else {
				ctrl.notification = ctrl.numberOfItems + ' lotes em negociação';
			}
		}

		function updateCountdown() {
			var diff = moment.duration(ctrl.auction.endDate - (new Date()).getTime());
			
			var diffDays = diff.days();
			var labelDays = (diffDays > 1 ? ' dias, ': ' dia, '); 
			
			
			ctrl.countdown = 'Negociações encerram em ' + diffDays + labelDays + diff.hours() + ' horas, ' + diff.minutes()
				+ ' minutos e ' + diff.seconds() + ' segundos.';
		}

		ctrl.loadClientAuctionsMore =function loadClientAuctionsMore() {
			
			if(contMarket < ctrl.numberOfItemsMM){
				if (ctrl.auctionLoaded && ctrl.auction && ctrl.auction.auctionItemsIds) {
					var index = 0;
					
	
					_.forEach(ctrl.auction.auctionItemsIds, function(auctionItemId) {
						var auctionItem = $firebaseObject(firebase.database()
							.ref(envConfig.envFirebase).child('auctionItem/' + auctionItemId));
							if(index >= contMarket && index < contMarket+12){
								
								auctionItem.$loaded().then(function() {
									var isIndividualBuyer = remarketApp.getUser().companyType == 'INDIVIDUAL_BUYER';
									//Removes items that should not be presented to individual buyers
									if (isIndividualBuyer && !auctionItem.showToIndividuals) {
										return;
									}
			
									ctrl.auctionItems.push(auctionItem);
									updateNotification();
								});
								reloadDescription();
							}
							
							if (index > contMarket+12)
							{
								return;
							}
							if(index < contMarket+12){

								index++;
							}
								
					});
					contMarket = index
				}
				
			}else{
				if(ctrl.numberOfItemsMM>24){
					ctrl.clientAuctionItems = [];
				}
				if (ctrl.clientAuctionsLoaded && ctrl.clientAuctions) {
					var index = 0;
					Object.entries(ctrl.clientAuctions).forEach(
						([auctionKey, auctionValue]) => {
							Object.entries(auctionValue.items).forEach(
								([key, value]) => {
	
									//procurar break
									if(index >= contClient && index < contClient+12){
										
										var clientAuctionItem = $firebaseObject(firebase.database()
											.ref(envConfig.envFirebase).child('client-auction/' + auctionKey + '/items/' + key));
		
										clientAuctionItem.$loaded().then(function() {
											var isIndividualBuyer = remarketApp.getUser().companyType == 'INDIVIDUAL_BUYER';
											//Removes items that should not be presented to individual buyers
											if (isIndividualBuyer && !clientAuctionItem.showToIndividuals) {
												return;
											}
		
											ctrl.clientAuctionItems.push(clientAuctionItem);
											updateNotification();
										});	
		
										
										reloadDescription();
									}
									
									if (index > contClient+12)
									{
										return;
									}
									if(index < contClient+12){
	
										index++;
									}
									
								}
								);
							contClient =  index;
							ctrl.numberOfItems += contClient;
						}
					);
				}
			}
				
				//ctrl.clientAuctionItems = [];
			
		}
		$scope.$on('remarket-login', init);

		init();
		
	};
	auctionCtrl.$inject = ['$firebaseObject', '$scope', '$interval', 'envConfig'];
	remarketApp.controller('auctionCtrl', auctionCtrl);

	var auctionStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.auction', {
			url: '/auction',
			templateUrl: 'view/panel/auction/auction.html',
			controller: auctionCtrl,
			controllerAs: 'auctionCtrl'
		});
	};
	auctionStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionStateProvider);
}());
