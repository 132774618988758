(function() {
	var remarketApp = angular.module('remarketApp');

	var negotiationsCtrl = function($http, $scope) {
		var ctrl = this;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 5;
		
		ctrl.startDate = null;
		ctrl.endDate = null;
		ctrl.status = null;
		ctrl.type = null;
		
		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			ctrl.load();
		}
		
		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}
		
		ctrl.pagination = function() {
			ctrl.load();
		}
		
		ctrl.setStatus = function(status) {
			ctrl.status = status;
			ctrl.load();
		}
		
		ctrl.setType = function(type) {
			ctrl.type = type;
			ctrl.load();
		}
		
		ctrl.load = function() {
			if (ctrl.startDate != null && ctrl.endDate != null) {
				
			}
			
			$http.get('/api/auctions/paginated', {
						params: {
							 startDate: ctrl.startDate,
							 endDate: ctrl.endDate,
							 status: ctrl.status,
							 type: ctrl.type,
						  	 page: ctrl.currentPage - 1, 
						  	 size: ctrl.itemsPerPage
						}
					  })
			.then(function(response) {
				ctrl.auctions = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		};
		
		$scope.$on('remarket-login', init);
		
		init();
	}

	negotiationsCtrl.$inject = ['$http', '$scope'];
	remarketApp.controller('negotiationsCtrl', negotiationsCtrl);

	var negotiationsStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiation-list', {
			url: '/negotiation/list',
			templateUrl: 'view/panel/negotiation/list/negotiation-list.html',
			controller: negotiationsCtrl,
			controllerAs: 'negotiationsCtrl'
		});
	};
	negotiationsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(negotiationsStateProvider);
}());