(function () {
	var remarketApp = angular.module('remarketApp');

	var auctionItemsCtrl = function($http, $scope, auctionItemEnum) {
		var ctrl = this;

		ctrl.status = null;
		ctrl.company = null;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.auctionStatusTypes = auctionItemEnum.statusTypes;

			ctrl.load();

			ctrl.updateTradableCount();

			if (remarketApp.isAdmin()) {
				ctrl.viewDescription = 'Central de gerenciamento de vendas.';
				ctrl.emptyList = 'Nenhuma venda encontrada.';
			} else {
				ctrl.viewDescription = 'Gerencie suas vendas a partir da listagem abaixo, ou crie uma nova venda.';
				ctrl.emptyList = 'Você ainda não criou nenhuma venda.';
			}
		}

		$scope.$on('remarket-login', init);

		ctrl.updateTradableCount = function() {
			$http.get('/api/vehicles/tradable/count').then(function(response) {
				ctrl.notTrading = response.data.total;
			}, remarketApp.handleError);
		};

		ctrl.pagination = function() {
			ctrl.load();
		}

		ctrl.setCompany = function(company) {
			ctrl.company = company;
			ctrl.load();
		}
		
		ctrl.setStatus = function(status) {
			ctrl.status = status;
			ctrl.load();
		}
		
		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}

		ctrl.load = function() {
			$http.get('/api/auctions/items/', {
				params: {
					company: ctrl.company,
					status: ctrl.status,
					page: ctrl.currentPage, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.auctionItems = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		}
		
		ctrl.remove = function(auctionItem) {
			$http.delete('/api/auctions/items/' + auctionItem.id).then(function(response) {
				ctrl.load();
				ctrl.updateTradableCount();
				remarketApp.success('Venda removida com sucesso.');
			}, remarketApp.handleError);
		};
		
		ctrl.removable = function(auctionItem) {
			if (remarketApp.isAdmin()) {
				return false;
			}
			return auctionItem.status == 'NEW' || auctionItem.status == 'SCHEDULED'; 
		};

		ctrl.removeScheduling = function(auctionItem) {
			if (! remarketApp.isAdmin()) return;
			// admins only
			var auctionItemId = auctionItem.auctionItemId;
			$http.post('/api/auctions/auction-items/remove-scheduling/' + auctionItemId).then(function(response) {
				ctrl.auctionItems = response.data;
				remarketApp.success('Agendamento removido com sucesso.');
			}, remarketApp.handleError);
		};

		init();
	};
	auctionItemsCtrl.$inject = ['$http', '$scope', 'auctionItemEnum'];
	remarketApp.controller('auctionItemsCtrl', auctionItemsCtrl);

	var auctionItemsConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.auction-items', {
			url: '/auction-items',
			templateUrl: 'view/panel/auction-item/auction-items.html',
			controller: auctionItemsCtrl,
			controllerAs: 'auctionItemsCtrl'
		});
	};
	auctionItemsConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionItemsConfigStateProvider);
}());
