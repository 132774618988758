(function() {
	var remarketApp = angular.module('remarketApp');

	var columnFilterCtrl = function($scope, $element, $q, $attrs, $window) {
		var ctrl = this;

		ctrl.toggleFilter = function () {
			ctrl.hide = !ctrl.hide;
			if (ctrl.hide) {
				ctrl.tooltip = 'Mostrar filtro';
			} else {
				ctrl.tooltip = 'Esconder filtro';
			}
		}

		ctrl.hide = true;
		ctrl.tooltip = 'Mostrar filtro';
	};
	
	columnFilterCtrl.$inject = ['$scope', '$element', '$q', '$attrs', '$window'];

	remarketApp.component('columnFilter', {
		bindings: {
			filter: '=',
			hide: '@',
			tooltip: '@'
		},
		templateUrl: 'component/column-filter/column-filter.html',
		controller: columnFilterCtrl
	});
}());