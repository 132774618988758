angular.module("remarketApp.config", [])

.constant("envConfig", {
	"env": "production",
	"envFirebase": "prd",
	"iuguKey": "70E11E7B9652435B9E4353345C480D84",
	"firebaseApiKey": "AIzaSyBLhIPfPiS2Tpi7cTbuwDZKHkBDY_HKPoQ",
	"firebaseAuthDomain": "motormarket-app.firebaseapp.com",
	"firebaseDatabaseURL": "https://motormarket-app.firebaseio.com",
	"firebaseProjectId": "motormarket-app",
	"firebaseStorageBucket": "motormarket-app.appspot.com",
	"firebaseMessagingSenderId": "367454622723",
	"addressServicePort": "https://motormarket.com.br",
	"auctionServicePort": "https://motormarket.com.br",
	"companyServicePort": "https://motormarket.com.br",
	"configurationServicePort": "https://motormarket.com.br",
	"getekServicePort": "https://motormarket.com.br",
	"inspectionServicePort": "https://motormarket.com.br",
	"notificationServicePort": "https://motormarket.com.br",
	"userServicePort": "https://motormarket.com.br",
	"vehicleServicePort": "https://motormarket.com.br",
	"fipeServicePort": "https://motormarket.com.br"
})

;