(function() {
	var remarketApp = angular.module('remarketApp');

	var expandIconCtrl = function() {
		return {
			restrict: 'E',
			templateUrl: 'directive/expand-icon/expand-icon.html'
		}
	};
	remarketApp.directive('expandIcon', expandIconCtrl);
}());