(function() {
	var remarketApp = angular.module('remarketApp');

	var auctionItemScheduling = function($http, $scope, $stateParams, auctionItemService, $filter, $state) {
		var ctrl = this;
		ctrl.minimumPrice = 0;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.spare = $state.is('landingPage.panel.spare-auction-items-scheduling-batch');

			if ($stateParams.auctionItemId) {
				$http.get('/api/auctions/items/edit/' + $stateParams.auctionItemId).then(function (response) {
					ctrl.auctionItem = response.data;
					ctrl.selectedAuction = ctrl.auctionItem.auction;

					for (var i = 0; i < ctrl.auctionItem.vehicles.length; i++) {
						ctrl.minimumPrice += ctrl.auctionItem.vehicles[i].price;
					}

					$http.get('/api/auctions/scheduled').then(function (response) {
						ctrl.auctions = response.data;
						for (var i = 0; i < ctrl.auctions.length; i++) {
							ctrl.auctions[i].description = 'Período: ' + formatDate(ctrl.auctions[i].startDate) + ' - ' + formatDate(ctrl.auctions[i].endDate);

							if (ctrl.auctionItem.auction && ctrl.auctionItem.auction.id == ctrl.auctions[i].id) {
								ctrl.selectedAuction = ctrl.auctions[i];
							}
						}
					}, remarketApp.handleError);
				}, remarketApp.handleError);
			} else {
				ctrl.auctionItem = {
					vehicles: auctionItemService.getSelectedVehicles()
				};
				$http.get('/api/auctions/scheduled').then(function (response) {
					ctrl.auctions = response.data;
					for (var i = 0; i < ctrl.auctions.length; i++) {
						ctrl.auctions[i].description = 'Período: ' + formatDate(ctrl.auctions[i].startDate) + ' - ' + formatDate(ctrl.auctions[i].endDate);
					}
				}, remarketApp.handleError);
			}
		}

		function formatDate(strDate) {
			let date = new Date(strDate + 'T00:00:00');

			return $filter('date')(date, 'dd/MM/yy');
		}

		$scope.$on('remarket-login', init);

		ctrl.setSelectedAuction = function(selectedAuction) {
			ctrl.selectedAuction = selectedAuction;
		};

		ctrl.clearSelectedAuction = function() {
			ctrl.selectedAuction = null;
		};

		ctrl.wizardSteps = auctionItemService.wizardSteps;

		ctrl.wizardSteps[0].onClick = function() {
			remarketApp.go('landingPage.panel.auction-items-edit-pricing', {
				auctionItemId: $stateParams.auctionItemId
			});
		};

		ctrl.goBack = function() {
			if (ctrl.spare) {
				remarketApp.go('landingPage.panel.spare-auction-items-new-batch');
			} else {
				remarketApp.go('landingPage.panel.auction-items-edit-batch', {
					auctionItemId: $stateParams.auctionItemId
				});
			}
		};

		ctrl.wizardSteps[1].onClick = ctrl.goBack;

		ctrl.schedule = function() {
			if (ctrl.spare) {
				auctionItemService.createSpareAuctionItems(ctrl.selectedAuction);
			} else {
				auctionItemService.schedule(ctrl.selectedAuction, ctrl.auctionItem);
			}
		};
		
		ctrl.showVisualizeVehicleDetailsButton = function(vehicle) {
			return vehicle.origin == 'GETEK';
		}
		
		ctrl.showVisualizeMobileButton = function(vehicle) {
			return vehicle.origin == 'MOBILE';
		}

		ctrl.loadVehicleDetails = function(vehicle) {
			if (vehicle.vehicleId == undefined) {
				ctrl.vehicleDetailsId = vehicle.id;
			} else {
				ctrl.vehicleDetailsId = vehicle.vehicleId;
			}
		};
		
		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
			ctrl.vehicleMobileDetailsId = null;
		};
		
		ctrl.loadMobileInspectionDetails = function(vehicle) {
			document.body.style.overflow = 'hidden';
			ctrl.vehicleMobileDetailsId = vehicle.externalInspectionId;
		};
		
		init();
	};
	auctionItemScheduling.$inject = ['$http', '$scope', '$stateParams', 'auctionItemService', '$filter', '$state'];
	remarketApp.controller('auctionItemScheduling', auctionItemScheduling);

	var auctionItemSchedulingConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.auction-items-scheduling-batch', {
			url: '/auction-items/scheduling/:auctionItemId',
			templateUrl: 'view/panel/auction-item/auction-item-scheduling.html',
			controller: auctionItemScheduling,
			controllerAs: 'auctionItemScheduling'
		}).state('landingPage.panel.spare-auction-items-scheduling-batch', {
			url: '/auction-items/spare/scheduling',
			templateUrl: 'view/panel/auction-item/auction-item-scheduling.html',
			controller: auctionItemScheduling,
			controllerAs: 'auctionItemScheduling'
		});
	};
	auctionItemSchedulingConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionItemSchedulingConfigStateProvider);
}());