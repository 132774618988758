(function () {
	var remarketApp = angular.module('remarketApp');

	var profileController = function($http, $scope) {
		var ctrl = this;
		ctrl.profile = {};
		ctrl.newPassword = {};

		function init() {
			if (remarketApp.getUser()) {
				$http.get('/api/webapp/users/' + remarketApp.getUser().id).then(function (response) {
					ctrl.profile = response.data;
				}, remarketApp.handleError);
			}
		}
		
		ctrl.saveProfile = function() {
			var userId = remarketApp.getUser().id;
			$http.post('/api/webapp/users/' + userId, ctrl.profile).then(function () {
				remarketApp.success('Dados pessoais salvos com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.savePassword = function() {
			$http.post('/api/webapp/users/change-password', ctrl.newPassword).then(function (response) {
				ctrl.newPassword = {};
				remarketApp.success('Senha alterada com sucesso.');
			}, remarketApp.handleError);
		};

		init();
	};
	profileController.$inject = ['$http', '$scope'];
	remarketApp.controller('profileController', profileController);

	var profileStateProvider = function ($stateProvider) {
		$stateProvider
			.state('landingPage.panel.profile', {
				url: '/profile',
				templateUrl: 'view/panel/profile/profile.html',
				controller: profileController,
				controllerAs: 'profileController'
			});
	};
	profileStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(profileStateProvider);
}());