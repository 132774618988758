(function() {
	var remarketApp = angular.module('remarketApp');

	var companyAddressController = function(companyCreationService, $http) {
		var ctrl = this;
		ctrl.company = {};
		
		function init() {
			companyCreationService.getCompany(function(company) {
				ctrl.company = company;
			});
		}

		ctrl.fulfillAddress = function() {
			if (!ctrl.company.zipAddress || ctrl.company.zipAddress.length != 8) {
				return remarketApp.info('O CEP deve ser válido e estar no formato 00000-000.');
			}

			$http.get('/api/address/' + ctrl.company.zipAddress).then(function (response) {
				ctrl.cityState = response.data.city.name + ' - ' + response.data.city.state.name;
				ctrl.company.streetAddress = response.data.street;
				ctrl.company.district = response.data.district;
				ctrl.company.cityId = response.data.city.id;
				remarketApp.info('Endereço carregado com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.save = function() {
			companyCreationService.finalizeAddressStep(ctrl.company);
		};

		ctrl.back = function() {
			remarketApp.go('landingPage.companyData');
		};

		init();
	};
	companyAddressController.$inject = ['companyCreationService', '$http'];	remarketApp.controller('companyAddressController', companyAddressController);

	var companyAddressStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.companyAddress', {
			url:  '/company-address',
			templateUrl: 'view/public/company/company-address.html',
			controller: companyAddressController,
			controllerAs: 'companyAddressController',
			onEnter: companyAddressController.init
		});
	};
	companyAddressStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(companyAddressStateProvider);
}());