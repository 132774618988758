(function() {
	var remarketApp = angular.module('remarketApp');

	var guardAction = function ($rootScope) {
		return {
			link: function (scope, element, attr) {
				// ask for confirmation to decide whether the guarded action is run or not
				var guardAction = function() {
					scope.$eval(attr.guardAction);
				};
				element.bind('click',function () {
					if (element[0].getAttribute("disabled")) {
						return;
					}
					$rootScope.$broadcast('remarket-confirm-action', {
						action : guardAction,
						title: attr.guardTitle,
						message: attr.guardMessage
					});
				});
			}
		};
	};
	guardAction.$inject = ['$rootScope'];
	remarketApp.directive('guardAction', guardAction);
}());