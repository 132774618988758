(function() {
    var remarketApp = angular.module('remarketApp');

    var embedSrcCtrl = function($sce) {
        return {
            restrict: 'A',
            scope: {
                embedSrc: '='
            },
            link: function(scope, element, attrs) {
                scope.$watch('embedSrc', function(val) {
                    $sce.trustAsResourceUrl(val);
                    element.attr('src', val);
                });
            }
        }
    };
    embedSrcCtrl.$inject = ['$sce'];     
    remarketApp.directive('embedSrc', embedSrcCtrl);
}());