(function () {
	var remarketApp = angular.module('remarketApp');

	var internalAuctionVisualize = function($http, $scope, $stateParams, auctionItemService, $filter, $state) {
		var ctrl = this;
		ctrl.vehiclesSelectedForSale = [];
		ctrl.minimumPrice = 0;

		ctrl.title = '';
		
		ctrl.auction = {
			startDate: null,
			endDate: null,
			auctionItemVehicles: []
		};

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			$http.get('/api/internal-auctions/' + $stateParams.negotiationId).then(function(response) {
				ctrl.auction = response.data;
			}, remarketApp.handleError);
		}
		
		ctrl.goBack = function() {
			return remarketApp.go('landingPage.panel.negotiations-internal');
		};
		
		ctrl.loadVehicleDetails = function(vehicle) {
			ctrl.vehicleDetailsId = vehicle.id;
		};

		ctrl.closeVehicleDetails = function() {
			ctrl.vehicleDetailsId = null;
		};

		$scope.$on('remarket-login', init);
		init();
	};
	internalAuctionVisualize.$inject = ['$http', '$scope', '$stateParams', 'auctionItemService', '$filter', '$state'];
	remarketApp.controller('internalAuctionVisualize', internalAuctionVisualize);

	var auctionItemBatchConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.internal-auction-items-visualize', {
			url: '/auction-items/internal/visualize/:negotiationId',
			templateUrl: 'view/panel/negotiation/internal/items/internal-auction-items-visualize.html',
			controller: internalAuctionVisualize,
			controllerAs: 'internalAuctionVisualize'
		});
	};
	
	auctionItemBatchConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionItemBatchConfigStateProvider);
}());