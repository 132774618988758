(function() {
	var remarketApp = angular.module('remarketApp');

	var individualDataController = function(individualCreationService, $scope) {
		var ctrl = this;
		ctrl.individual = {};

		ctrl.save = function() {
			individualCreationService.finalizeDataStep(ctrl.individual, ctrl.documentFile);
		};

		function init() {
			individualCreationService.getIndividual(function(individual) {
				var individualType = ctrl.individual.individualType;
				ctrl.individual = individual || { individualType: individualType };
				delete ctrl.individual.interests;
			});
			
			if (remarketApp.getUser()) {
				ctrl.individual.individualType = remarketApp.getUser().individualType;
			}
		}

		// $scope.$on('remarket-login', function(event, user){
		// 	ctrl.individual.individualType = user.individualType;
		// });

		init();
	};
	
	individualDataController.$inject = ['individualCreationService', '$scope'];
	remarketApp.controller('individualDataController', individualDataController);

	var individualDataStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.individualData', {
			url:  '/individual-data',
			templateUrl: 'view/public/individual/individual-data.html',
			controller: individualDataController,
			controllerAs: 'individualDataController'
		});
	};
	
	individualDataStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(individualDataStateProvider);
}());