(function() {
	var remarketApp = angular.module('remarketApp');

	var termsOfUseController = function($http) {
		var ctrl = this;

		ctrl.accept = function () {
			$http.post('/api/company/accept-terms-of-use/' + remarketApp.currentUser.companyId).then(function () {
				remarketApp.success('Vamos começar!');
				remarketApp.go('landingPage.companyData');
				remarketApp.getUser().showTerms = false;
			}, remarketApp.handleError);
		};

		ctrl.decline = function () {
			remarketApp.error('Para utilizar o sistema, você deve estar de acordo com os termos de uso.');
			remarketApp.logout();
		};
	};
	termsOfUseController.$inject = ['$http'];
	remarketApp.controller('termsOfUseController', termsOfUseController);

	var termsOfUseStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.termsOfUseSeller', {
			url: '/terms-of-use-seller',
			templateUrl: 'view/public/login/terms-of-use-seller.html',
			controller: termsOfUseController,
			controllerAs: 'termsOfUseController'
		});
	};
	termsOfUseStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(termsOfUseStateProvider);
}());