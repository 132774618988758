(function() {
	var remarketApp = angular.module('remarketApp');
	
	var confirmDialogCtrl = function($scope, $timeout) {
		var ctrl = this;
		
		ctrl.cancel = hide;

		ctrl.confirm = function() {
			ctrl.action();
			hide();
		};

		function show(action, title, message) {
			document.body.style.overflow = 'hidden';
			ctrl.title = title || 'Você tem certeza?';
			ctrl.message = message || 'Você tem certeza que deseja executar esta ação?';
			ctrl.display = true;
			ctrl.action = action;
			$scope.$digest();
			$timeout(function() {
				ctrl.animate = true;
			});
		}

		function hide() {
			ctrl.animate = false;
			$timeout(function() {
				ctrl.display = false;
				document.body.style.overflow = 'visible';
			}, 400);
		}

		$scope.$on('remarket-confirm-action', function(event, data) {
			show(data.action, data.title, data.message);
		});
	};
	confirmDialogCtrl.$inject = ['$scope', '$timeout'];
	remarketApp.component('confirmDialog', {
		bindings: {
			title: '@',
			message: '@',
			icon: '@',
			onClick: '&'
		},
		templateUrl: 'directive/confirm-dialog/confirm-dialog.html',
		controller: confirmDialogCtrl
	});
}());