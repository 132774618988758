(function () {
	var remarketApp = angular.module('remarketApp');

	var internalNegotiationCtrl = function($http, $scope) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			ctrl.load();
		}

		ctrl.load = function() {
			$http.get('/api/internal-auctions/').then(function(response) {
				ctrl.negotiations = response.data;
			}, remarketApp.handleError);
			
			$http.get('/api/vehicles/tradable/count').then(function(response) {
				ctrl.notTrading = response.data.total;
			}, remarketApp.handleError);
		}
		
		ctrl.showVisualizeButton = function(negotiation) {
			return negotiation.status != 'SCHEDULED';
		}
		
		ctrl.showEditButton = function(negotiation) {
			return negotiation.status == 'SCHEDULED' || negotiation.status == 'NEW';
		}
		
		ctrl.showRemoveButton = function(negotiation) {
			return negotiation.status == 'SCHEDULED' || negotiation.status == 'NEW';
		}
		
		ctrl.deleteScheduledAuction = function(id) {
			$http.delete('/api/internal-auctions/' + id).then(function(response) {
				remarketApp.success('Negociação removida com sucesso.');
				ctrl.negotiations = response.data;
			}, remarketApp.handleError);
		}
		
		$scope.$on('remarket-login', init);
		init();
	};
	remarketApp.controller('internalNegotiationCtrl', internalNegotiationCtrl);

	var internalNegotiationsStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiations-internal', {
			url: '/negotiations/internal',
			templateUrl: 'view/panel/negotiation/internal/internal-negotiation.html',
			controller: internalNegotiationCtrl,
			controllerAs: 'internalNegotiationCtrl'
		});
	};
	internalNegotiationsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(internalNegotiationsStateProvider);
}());