(function() {
	var remarketApp = angular.module('remarketApp');

	var auctionItemCtrl = function($scope, $http, auctionItemInterestsService) {
		var ctrl = this;
		
		var init = function () {
			updateUserOwnsHighestBid();
			ctrl.vehiclesLength = ctrl.item.vehicles ? ctrl.item.vehicles.length : 0;
			ctrl.selectedVehicle = 0;
			ctrl.subItemEnabled = ctrl.vehiclesLength > 1;
			ctrl.minimumBidDifference = null;
			ctrl.showLabelBid = false;
			ctrl.showCoveredConditionalBidLabel = false;
			ctrl.showUserOwnsConditionalBidLabel = false;
			
			$http.get('/api/configuration/auction').then(function(response) {
				ctrl.minimumBidDifference = response.data.minimumBidDifference;
				updateUI();
			}, remarketApp.handleError);
			
			if (ctrl.vehiclesLength > 1) {
				ctrl.description = 'Lote com ' + ctrl.vehiclesLength + ' veículos.';
			} else {
				ctrl.description = 'Lote com 1 veículo.'
			}

			loadPictures();
			if (ctrl.pictures) {
				ctrl.currentPicture = ctrl.pictures[0];
			}
			
			updateUI();

			$scope.$watch(function() { return ctrl.item.highestBid }, function() {
				updateUI();
				
				if (!ctrl.item.vehicles) {
					ctrl.closeVehicleDetails();
				}
			});
			
			$scope.$watch(function() { return ctrl.item.highestConditionalBid }, function() {
				updateUI();
				
				if (!ctrl.item.vehicles) {
					ctrl.closeVehicleDetails();
				}
			});

			$scope.$watch(function() { return ctrl.item.programmedBids }, function() {
				ctrl.programmedBidConfigured = false;
				if (ctrl.item.programmedBids && ctrl.item.programmedBids.length >= 1) {
					var programmedBid = _.find(ctrl.item.programmedBids, function(programmedBid) {
						return programmedBid.userId == remarketApp.getUser().id;
					});
					ctrl.programmedBidConfigured = programmedBid != null;
					if (ctrl.programmedBidConfigured) {
						ctrl.programmedBid = programmedBid.maxBid;
					} else {
						ctrl.programmedBid = null;
						updateProgrammedBidValue();
					}
				}
				
				updateUI();
			});
		};

		ctrl.showThumbnail = function() {
			if (!ctrl.currentPicture) {
				return;
			}

			if (!ctrl.currentPicture.thumbnailURL) {
				return ctrl.currentPicture.url;
			} else {
				return ctrl.currentPicture.thumbnailURL;
			}
		}

		var loadPictures = function() {
			var selectedItem = ctrl.item.vehicles[ctrl.selectedVehicle];
			ctrl.pictures = selectedItem.pictures;
		}

		var getThumbnails = function(item) {
			var thumbnails = [];
			for (i = 0; i < item.pictures.length; i++) {
				if (item.pictures[i].thumbnailURL) {
					thumbnails.push(item.pictures[i].thumbnailURL);
				} else {
					thumbnails.push(item.pictures[i].url);
				}
			}

			return thumbnails;
		}
		
		var updateUI = function() {
			updateHasBids();
			updateBidsValues();
			updateProgrammedBidValue();
			updateUserOwnsHighestBid();
			updateUserOwnsHighestConditionalBid();
		}

		ctrl.maskPlate = function(plate) {
			return plate.substring(0, 3) + "***" + plate.substring(6, 7);
		}
		
		var updateBidsValues = function() {
			if (ctrl.item.highestBid) {
				ctrl.bidValue = ctrl.item.minimumPrice + ctrl.minimumBidDifference;
			} else {
				ctrl.bidValue = ctrl.item.minimumPrice;
			}
			
//			if (ctrl.item.conditionalPrice) {
//				if (ctrl.item.highestConditionalBid) {
//					ctrl.conditionalBidValue = ctrl.item.conditionalPrice + ctrl.minimumBidDifference;
//				} else {
//					ctrl.conditionalBidValue = ctrl.item.conditionalPrice;
//				}
//			}
		}

		var updateProgrammedBidValue = function() {
			if (!ctrl.programmedBid || ctrl.programmedBid <= ctrl.item.minimumPrice + ctrl.minimumBidDifference) {
				ctrl.programmedBid = ctrl.item.minimumPrice+ ctrl.minimumBidDifference;
			}
		};

		var updateUserOwnsHighestBid = function () {
			ctrl.userOwnsHighestBid = ctrl.item.highestBid && ctrl.item.highestBid.userId == remarketApp.getUser().id;
		};
		
		var updateHasBids = function() {
			ctrl.hasBids = ctrl.item && ctrl.item.highestBid;
		};
		
		var updateUserOwnsHighestConditionalBid = function () {
			if (ctrl.item.highestConditionalBid) {
				if (ctrl.item.highestConditionalBid.userId == remarketApp.getUser().id) {
					ctrl.userOwnsHighestConditionalBid = true;
					ctrl.showCoveredConditionalBidLabel = false;
				} else {
					ctrl.userOwnsHighestConditionalBid = false;
					
					auctionItemInterestsService.getInterests().$loaded().then(function() {
						if (_.contains(auctionItemInterestsService.getInterests().ids, ctrl.item.id)) {
							ctrl.showCoveredConditionalBidLabel = true;
						} else {
							ctrl.showCoveredConditionalBidLabel = false;
						}
					});
				}
			} else {
				ctrl.userOwnsHighestConditionalBid = false;
				ctrl.showCoveredConditionalBidLabel = false;
			}
		};
		
		ctrl.isGetekInspection = function() {
			return ctrl.item.vehicles[ctrl.selectedVehicle].origin == 'GETEK';
		}
		
		ctrl.disableConditionalPriceField = function() {
			return !ctrl.item.conditionalPrice ||
					(ctrl.item.conditionalPrice + ctrl.minimumBidDifference >= ctrl.item.minimumPrice) || 
					ctrl.userOwnsHighestConditionalBid || 
					ctrl.hasBids;
		}
	
		ctrl.tooltipButtonConditionalBid = function () {
			if (ctrl.item.sold) {
				return 'Este lote foi vendido.';
			}
			
			if (ctrl.hasBids) {
				return 'Este lote já tem ofertas. Participe da negociação.';
			}
			
			if (ctrl.userOwnsHighestConditionalBid) {
				return 'A última oferta é sua.';
			}
		}
		
		ctrl.sendNextConditionalBid = function() {
			if (ctrl.item.sold || ctrl.hasBids || ctrl.userOwnsHighestConditionalBid) {
				remarketApp.error('Este item não pode receber mais ofertas.');
				return;
			}
			
			if (!ctrl.conditionalBidValue) {
				remarketApp.error('Informe um valor condicional.');
				return;
			}

			if (!ctrl.item.highestConditionalBid && ctrl.conditionalBidValue < ctrl.item.conditionalPrice) {
				remarketApp.error('Valor mínimo não atingido.');
				return;
			}
			
			if (ctrl.item.highestConditionalBid && ctrl.item.highestConditionalBid.price >= ctrl.conditionalBidValue) {
				remarketApp.error('A oferta não cobre o valor atual para ofertas condicionais.');
				return;
			}
			
			if (ctrl.conditionalBidValue && ctrl.conditionalBidValue >= ctrl.item.minimumPrice) {
				remarketApp.error('A oferta condicional ultrapassou o valor das ofertas normais do veículo. ' +
								  'Por favor, utilize o campo "Próxima Oferta" e participe da negociação.');
				return;
			}
			
			if (ctrl.item.highestConditionalBid && ((ctrl.conditionalBidValue - ctrl.item.highestConditionalBid.price) < ctrl.minimumBidDifference)) {
				remarketApp.error('As ofertas devem ter uma diferença de R$ ' + ctrl.minimumBidDifference + ' entre uma e outra. ' + 
								  'Você poderia oferecer R$' + (ctrl.item.highestConditionalBid.price+ ctrl.minimumBidDifference) + '.');
				return;
			}
			
			ctrl.item.conditionalPrice = ctrl.conditionalBidValue;
			
			ctrl.item.highestConditionalBid = {
				userId: remarketApp.getUser().id,
				price: ctrl.item.conditionalPrice
			};

			ctrl.item.$save();
			auctionItemInterestsService.addInterest(ctrl.item);
			updateUI();
		};

		ctrl.buyNow = function() {
			if (ctrl.item.sold) {
				return;
			}

			ctrl.item.minimumPrice = ctrl.item.buyNowPrice;
			ctrl.item.highestBid = {
				userId: remarketApp.getUser().id,
				price: ctrl.item.buyNowPrice
			};
			
			ctrl.item.sold = true;
			ctrl.item.$save();
		};

		ctrl.openVehicleDetails = function() {
			document.body.style.overflow = 'hidden';
			
			if (ctrl.isGetekInspection()) {
				ctrl.vehicleDetailsId = ctrl.item.vehicles[ctrl.selectedVehicle].id;
			} else {
				ctrl.vehicleMobileDetailsId = ctrl.item.vehicles[ctrl.selectedVehicle].externalInspectionId;
			}
		};

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
			ctrl.vehicleMobileDetailsId = null;
		};

		ctrl.goToVehicle = function(index) {
			//ctrl.pictures = ctrl.item.vehicles[index].pictures;
			
			ctrl.selectedVehicle = index;
			
			loadPictures();
			ctrl.currentPicture = ctrl.pictures[0];
			ctrl.currentItem = 0;		
		};

		ctrl.goToPicture = function(index) {
			ctrl.currentPicture = ctrl.pictures[index];
		};
		
		ctrl.sendNextBid = function() {
			if (ctrl.item.sold || (ctrl.item.highestBid && ctrl.item.highestBid.userId == remarketApp.getUser().id) || !ctrl.bidValue) {
				return;
			}
			
			if (ctrl.bidValue < ctrl.item.minimumPrice) {
				remarketApp.error('A oferta não cobre a oferta atual.');
				return;
			}

			if (ctrl.bidValue >= ctrl.item.buyNowPrice) {
				ctrl.buyNow();
				return;
			}
			
			ctrl.item.minimumPrice = ctrl.bidValue;
			
			ctrl.item.highestBid = {
				userId: remarketApp.getUser().id,
				price: ctrl.item.minimumPrice
			};
			updateProgrammedBidValue();
			ctrl.item.$save();
			updateUserOwnsHighestBid();
			auctionItemInterestsService.addInterest(ctrl.item);
		};
		
		ctrl.addToProgrammedBid = function() {
			if (ctrl.item.sold) {
				return;
			}
			ctrl.programmedBid = ctrl.programmedBid + ctrl.minimumBidDifference;
		};

		ctrl.subtractFromProgrammedBid = function() {
			if (ctrl.item.sold) {
				return;
			}
			ctrl.programmedBid = ctrl.programmedBid - ctrl.minimumBidDifference;
			if (ctrl.programmedBid < ctrl.item.minimumPrice + ctrl.minimumBidDifference) {
				ctrl.programmedBid = ctrl.item.minimumPrice + ctrl.minimumBidDifference;
			}
		};
		
		ctrl.percentageBelowFipePrice = function() {
			var percentage = (1 - ctrl.item.minimumPrice / ctrl.item.fipePrice) * 100;
			if (percentage < 0) {
				return percentage * -1;
			}

			return percentage;
		}

		ctrl.fipePercentageLabel = function() {
			if (ctrl.item.minimumPrice < ctrl.item.fipePrice) {
				return "Abaixo";
			} else if (ctrl.item.minimumPrice == ctrl.item.fipePrice) {
				return "Dif.";
			} else {
				return "Acima";
			}
		}

		ctrl.programBid = function() {
			if (ctrl.item.sold) {
				return;
			}
			if (!ctrl.item.programmedBids) {
				ctrl.item.programmedBids = [];
			}

			var sameBid = _.find(ctrl.item.programmedBids, function(programmedBid) {
				return programmedBid.maxBid == ctrl.programmedBid;
			}) != null;

			if (sameBid) {
				return remarketApp.error('O valor informado já foi configurado por outro usuário. Por favor, ' +
					'escolha um outro valor.');
			}

			ctrl.item.programmedBids.push({
				userId: remarketApp.getUser().id,
				maxBid: ctrl.programmedBid
			});
			ctrl.item.$save();
			remarketApp.success('Oferta programada configurada com sucesso.');
			ctrl.sendNextBid();
		};

		ctrl.removeProgrammedBid = function() {
			if (!ctrl.item.programmedBids) {
				return;
			}
			ctrl.item.programmedBids = _.reject(ctrl.item.programmedBids, function(programmedBid) {
				return programmedBid.userId == remarketApp.getUser().id;
			});
			ctrl.item.$save();
			remarketApp.success('Oferta programada removida com sucesso.');
		};
		
		init();
	};
	auctionItemCtrl.$inject = ['$scope', '$http', 'auctionItemInterestsService'];

	remarketApp.component('auctionItem', {
		bindings: {
			item: '<'
		},
		templateUrl: 'component/auction-item/auction-item.html',
		controller: auctionItemCtrl
	});
}());
