(function() {
	var remarketApp = angular.module('remarketApp');
	var vehiclesController = function($http) {
		var ctrl = this;

		ctrl.vehicleInspectionPlate = null;
		ctrl.vehicleInspectionId = null;

		ctrl.company = null;
		ctrl.description = null;
		ctrl.sold = null;
		ctrl.status = null;
		ctrl.plate = null;
		ctrl.currentPage = 2;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;

		function init() {
			ctrl.load();
		}

		ctrl.setSold = function(sold) {
			ctrl.sold = sold;
			ctrl.load();
		};

		ctrl.setType = function(type) {
			ctrl.type = type;
			ctrl.load();
		};

		ctrl.setStatus = function(status) {
			ctrl.status = status;
			ctrl.load();
		}

		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}

		ctrl.pagination = function() {
			ctrl.load();
		}

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
		}

		ctrl.closeMobileInspectionDetails = function() {
			ctrl.mobileInspectionId = null;
		}

		ctrl.showVisualizeButton = function(vehicle) {
			return vehicle.inspectionOrigin == 'GETEK';
		}

		ctrl.showVisualizeInspectionButton = function(vehicle) {
			return vehicle.plate.length == 7 && vehicle.inspectionDate == null;
		}

		ctrl.showVisualizeMobileButton = function(vehicle) {
			return vehicle.inspectionOrigin == 'MOBILE';
		}
		
		ctrl.closeSearchInspectionByIdVistoria = function() {
			ctrl.vehicleInspectionPlate = null;
			ctrl.vehicleInspectionId = null;
		}

		ctrl.searchInspectionByIdVistoria = function() {
			console.log(ctrl.vehicleInspectionPlate + " - " + ctrl.vehicleInspectionId);
			var itens = [{"idVistoria": ctrl.vehicleInspectionId, "placa": ctrl.vehicleInspectionPlate}];
			$http.post('/api/getek/run-getek-integration-with-itens', itens)
			.then(function(response) {
				remarketApp.success('Integração realizada com sucesso.');
				ctrl.load();
			}, error => console.log(error));
			ctrl.closeSearchInspectionByIdVistoria();
		}

		ctrl.load = function() {
			ctrl.vehicles = {};
			
			$http.get('/api/vehicles/findAllPaginated', {
				params: {
					company : ctrl.company,
					description : ctrl.description,
					plate : ctrl.plate,
					sold : ctrl.sold,
					status : ctrl.status,
					page: ctrl.currentPage, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.vehicles = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		};
		
		ctrl.changeStatusVehicle = function(id, status) {
			$http.get('/api/vehicles/changeStatus', {
				params: {
					vehicleId: id,
					changeTo: status
				}
			}).then(function(response) {
				ctrl.load();
			}, remarketApp.handleError);
		};

		init();
	};
	vehiclesController.$inject = ['$http'];
	remarketApp.controller('vehiclesController', vehiclesController);

	var vehiclesStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.vehicles', {
				url: '/vehicles',
				templateUrl: 'view/panel/vehicle/list/vehicle-list.html',
				controller: vehiclesController,
				controllerAs: 'vehiclesController'
			});
	};
	vehiclesStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(vehiclesStateProvider);
}());
