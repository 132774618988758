(function () {
	var remarketApp = angular.module('remarketApp');

	var inspectionsController = function($http, $scope, inspectionService) {
		var ctrl = this;
		
		ctrl.company = null;
		ctrl.location = null;
		ctrl.plate = null;
		ctrl.origin = null;
		ctrl.status = null;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			ctrl.load();
			
			if (remarketApp.isAdmin()) {
				ctrl.viewDescription = 'Central de gerenciamento de vistorias.';				
			} else {
				ctrl.viewDescription = 'Gerencie suas vistorias a partir da listagem abaixo, ou crie uma ' +
					'nova vistoria.';
			}
			ctrl.emptyList = 'Nenhuma vistoria encontrada.';
		}
		
		ctrl.pagination = function() {
			ctrl.load();
		}
		
		ctrl.setOrigin = function(origin) {
			ctrl.origin = origin;
			ctrl.load();
		}
		
		ctrl.setStatus = function(status) {
			ctrl.status = status;
			ctrl.load();
		}
		
		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}
		
		ctrl.load = function() {
			ctrl.inspections = {};
			
			$http.get('/api/webapp/inspections/findAllPaginated', {
				params: {
					company: ctrl.company,
					location: ctrl.location,
					plate: ctrl.plate,
					status: ctrl.status,
					origin: ctrl.origin,
					page: ctrl.currentPage, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.inspections = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		};
		
		ctrl.simulate = function(id) {
			$http.post('/api/webapp/inspection/admin/simulate/' + id).then(function(response) {
				remarketApp.success('Simulação da vistoria realizada com sucesso.');
				ctrl.load();
			}, remarketApp.handleError);
		};
		
		ctrl.deleteNewInspection = function(id) {
			$http.post('/api/webapp/inspections/deleteNewInspection/' + id).then(function(response) {
				remarketApp.success('Vistoria removida com sucesso.');
				ctrl.load();
			}, remarketApp.handleError);
		}
		
		ctrl.showSimulateInspection = function(inspection) {
			var nonProd = remarketApp.isDevelopment() || remarketApp.isHomolog();
			return nonProd && remarketApp.isAdmin() && inspection.status == 'SCHEDULED';
		};
		
		ctrl.showVisualizeButton = function(inspection) {
			return inspection.status == 'PENDING_PAYMENT' || inspection.status == 'SCHEDULED';
		};
		
		ctrl.showVisualizeMobileButton = function(inspection) {
			return inspection.status == 'INSPECTED' && inspection.origin == 'MOBILE';
		}; 
		
		ctrl.showReviewButton = function(inspection) {
			return (inspection.status == 'INSPECTED' || inspection.status == 'PARTIALLY_INSPECTED') && inspection.origin == 'GETEK';
		}; 
		
		ctrl.showApprovalButton = function(inspection) {
			return inspection.status == 'PENDING_APPROVAL' && inspection.origin == 'GETEK';
		};  
		
		ctrl.showBankSlipButton = function(inspection) {
			return !remarketApp.isAdmin() && inspection.status == 'PENDING_PAYMENT' && inspection.bankSlipUrl;
		};
		
		ctrl.showDeleteButton = function(inspection) {
			return inspection.status == 'NEW';
		}

		ctrl.showNoResultsLine = function() {
			return !ctrl.inspections || ctrl.inspections.length == 0;
		}

		ctrl.updatePaymentStatus = function(inspection) {
			$http.post('/api/webapp/inspections/checkouts/update-status/' + inspection.id).then(function() {
				remarketApp.success('Status atualizado.');
				init();
			}, remarketApp.handleError);
		};

		ctrl.showUpdatePaymentStatusButton = function(inspection) {
			return remarketApp.isAdmin() && inspection.status == 'PENDING_PAYMENT' && inspection.bankSlipUrl;
		};
		
		ctrl.scheduleInspection = function (inspectionId) {
			inspectionService.loadInspection(inspectionId, function() {
				remarketApp.go('landingPage.panel.inspectionScheduling', {
					inspectionId: inspectionId
				});
			});
		};
		
		ctrl.closeMobileInspectionDetails = function() {
			ctrl.mobileInspectionId = null;
		};

		ctrl.goToVehicleView = function() {
			remarketApp.go('landingPage.panel.inspections.vehicles');
		}
		
		$scope.$on('remarket-login', init);
		init();
	};
	inspectionsController.$inject = ['$http', '$scope', 'inspectionService'];
	remarketApp.controller('inspectionsController', inspectionsController);

	var inspectionsStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspections', {
			url: '/inspections',
			templateUrl: 'view/panel/inspection/list/inspections/inspections.html',
			controller: inspectionsController,
			controllerAs: 'inspectionsController'
		});
	};
	inspectionsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionsStateProvider);
}());
