(function() {
	var remarketApp = angular.module('remarketApp');
	
	var userController = function($stateParams, $http, userProfileTypeEnumService) {
		var ctrl = this;

		function init() {
			ctrl.profileTypes = userProfileTypeEnumService.getProfileTypes();

			if ($stateParams.userId) {
				ctrl.title = 'Edição de usuário';
				ctrl.description = 'Utilize o formulário abaixo para editar o usuário.';
				$http.get('/api/webapp/users/' + $stateParams.userId).then(function(response) {
					ctrl.user = response.data;
					ctrl.newUser = true;
				}, remarketApp.handleError);
				return;
			}
			ctrl.title = 'Novo usuário';
			ctrl.description = 'Utilize o formulário abaixo para criar um novo usuário.';
			ctrl.passwordDescription = 'Novos usuários receberão um e-mail para criarem uma nova senha.';
			ctrl.user = {};
		}

		ctrl.saveUser = function() {
			var ok = true;
			if (!ctrl.user.name || ctrl.user.name.length < 5) {
				remarketApp.error('O nome deve conter ao menos 5 caracteres.');
				ok = false;
			}
			if (!ctrl.user.cpf || ctrl.user.cpf.length != 11) {
				remarketApp.error('O cpf deve ser válido e estar no formato 000.000.000-00.');
				ok = false;
			}
			if (!ctrl.user.email || ctrl.user.email.length < 5) {
				remarketApp.error('O e-mail deve estar no formato exemplo@exemplo.com.');
				ok = false;
			}
			if (!ok) {
				return;
			}

			var userId = ctrl.user.id == null ? '' : ctrl.user.id;
			$http.post('/api/webapp/users/' + userId, ctrl.user).then(function() {
				if (ctrl.newUser) {
					remarketApp.success('Usuário alterado com sucesso.');
				} else {
					remarketApp.success('Usuário criado com sucesso.');
				}
				remarketApp.go('landingPage.panel.users');
			}, remarketApp.handleError);
		};

		init();
	};
	userController.$inject = ['$stateParams', '$http', 'userProfileTypeEnumService'];
	remarketApp.controller('userController', userController);

	var userFormStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.users-new', {
				url: '/users/new',
				templateUrl: 'view/panel/user/user.html',
				controller: userController,
				controllerAs: 'userController'
			}).state('landingPage.panel.users-edit', {
				url: '/users/edit/:userId',
				templateUrl: 'view/panel/user/user.html',
				controller: userController,
				controllerAs: 'userController'
			});
	};
	userFormStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(userFormStateProvider);
}());
