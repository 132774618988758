(function() {
	var remarketApp = angular.module('remarketApp');

	var imageZoomCtrl = function() {
		return {
			restrict: 'A',
			link: function(scope, element) {
				var imgTag = element[0];

				scope.$watch(function() {
					return element.attr('src');
				}, function(newValue) {
					imageZoomContainer.style.backgroundImage = 'url(' + newValue + ')';
				});
				
				var imageZoomContainer = document.createElement('div');
				imageZoomContainer.className = 'image-zoom-container hidden';
				imageZoomContainer.style.pointerEvents = 'none';

				imgTag.parentNode.insertBefore(imageZoomContainer, imgTag);

				imgTag.addEventListener('mouseover', function() {
					imgTag.addEventListener('mousemove', updateZoom);
					imageZoomContainer.className = 'image-zoom-container show';
				});

				imgTag.addEventListener('mouseout', function() {
					imgTag.removeEventListener('mousemove', updateZoom);
					imageZoomContainer.className = 'image-zoom-container hidden';
				});

				function updateZoom(event) {
					imageZoomContainer.style.left = (event.clientX - 100) + 'px';
					imageZoomContainer.style.top = (event.clientY - 100) + 'px';

					var rect = imgTag.getBoundingClientRect();

					var howDeepX = (event.clientX - rect.left);
					var howDeepY = (event.clientY - rect.top);
					var deepXPerc = howDeepX / rect.width;
					var deepYPerc = howDeepY / rect.height;

					var bgSizes = window.getComputedStyle(imageZoomContainer).getPropertyValue("background-size").split(' ');
					var bgWidth = bgSizes[0].replace('px', '');
					var bgHeight = bgSizes[1].replace('px', '');
					var bgLeft = bgWidth * deepXPerc * -1 + 100;
					var bgTop = bgHeight * deepYPerc * -1 + 100;

					imageZoomContainer.style.backgroundPosition = bgLeft + 'px ' + bgTop + 'px';
				}
			}
		}
	};
	remarketApp.directive('imageZoom', imageZoomCtrl);
}());