(function() {
	var remarketApp = angular.module('remarketApp');

	var negotiationDetailsCtrl = function($http, $stateParams, $scope) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			
			$http.get('/api/auctions/' + $stateParams.auctionId).then(function(response) {
				ctrl.auction = response.data;
			}, remarketApp.handleError);
			
			return;
		}
		
		ctrl.toggleDetailLine = function(item) {
			if (item.expanded === undefined) {
				item.expanded = true;
			} else {
				item.expanded = !item.expanded;
			}
		}
		
		ctrl.goBack = function() {
			return remarketApp.go('landingPage.panel.negotiation-list');
		}

		$scope.$on('remarket-login', init);

		init();
	};
	negotiationDetailsCtrl.$inject = ['$http', '$stateParams', '$scope'];
	remarketApp.controller('negotiationDetailsCtrl', negotiationDetailsCtrl);

	var negotiationDetailsStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiation-details', {
			url: '/negotiation/details/:auctionId',
			templateUrl: 'view/panel/negotiation/details/negotiation-details.html',
			controller: negotiationDetailsCtrl,
			controllerAs: 'negotiationDetailsCtrl'
		});
	};
	negotiationDetailsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(negotiationDetailsStateProvider);
}());