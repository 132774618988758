(function () {
	var remarketApp = angular.module('remarketApp');

	var inspectionAddressCtrl = function($scope, $stateParams, $http, inspectionService) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			if ($stateParams.inspectionId) {
				inspectionService.loadInspection($stateParams.inspectionId, function(inspection) {
					ctrl.inspection = inspection;
				});
				return;
			}
			ctrl.inspection = inspectionService.newInspection();
		}

		ctrl.fulfillAddress = function() {
			if (!ctrl.inspection.zipAddress || ctrl.inspection.zipAddress.length != 8) {
				return remarketApp.info('O CEP deve ser válido e estar no formato 00000-000.');
			}
			$http.get('/api/address/' + ctrl.inspection.zipAddress).then(function (response) {
				ctrl.cityState = response.data.city.name + ' - ' + response.data.city.state.name;
				ctrl.inspection.streetAddress = response.data.street;
				ctrl.inspection.cityName = response.data.city.name;
				ctrl.inspection.state = response.data.city.state.abbreviation;
				ctrl.inspection.cityId = response.data.city.id;
				ctrl.inspection.district = response.data.district;
				remarketApp.info('Endereço carregado com sucesso.');
			}, remarketApp.handleError);
		};
		
		ctrl.getCityAndState = function() {
			if (!ctrl.inspection || !ctrl.inspection.cityName) {
				return '';
			}
			return ctrl.inspection.cityName + '/' + ctrl.inspection.state;
		};

		ctrl.next = function() {
			inspectionService.wizardSteps[1].onClick();
		};
		
		ctrl.wizardSteps = inspectionService.wizardSteps;

		$scope.$on('remarket-login', init);

		init();
	};
	inspectionAddressCtrl.$inject = ['$scope', '$stateParams', '$http', 'inspectionService'];
	remarketApp.controller('inspectionAddressCtrl', inspectionAddressCtrl);

	var inspectionAddressStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspections-address-new', {
			url: '/inspections/address/new',
			templateUrl: 'view/panel/inspection/inspection-address.html',
			controller: inspectionAddressCtrl,
			controllerAs: 'inspectionAddressCtrl'
		}).state('landingPage.panel.inspections-address-edit', {
			url: '/inspections/address/edit/:inspectionId',
			templateUrl: 'view/panel/inspection/inspection-address.html',
			controller: inspectionAddressCtrl,
			controllerAs: 'inspectionAddressCtrl'
		});
	};
	inspectionAddressStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionAddressStateProvider);
}());