(function() {
	var remarketApp = angular.module('remarketApp');

	var backButtonCtrl = function($scope, $element, $q, $attrs, $window) {
		var ctrl = this;

		ctrl.busy = remarketApp.busy;

		ctrl.message = $attrs.message || 'Voltar';

		ctrl.click = function() {
			if (ctrl.busy.busy > 0) {
				return;
			}
			if (ctrl.to && $window.history.length > 1) {
				$window.history.back();
				return;
			} else if (ctrl.to) {
				remarketApp.go(ctrl.to, ctrl.params);
				return;
			}  
			
			ctrl.onClick();
		};

		ctrl.icon = $attrs.icon || 'fa fa-angle-double-left';
	};
	backButtonCtrl.$inject = ['$scope', '$element', '$q', '$attrs', '$window'];

	remarketApp.component('backButton', {
		bindings: {
			to: '@',
			params: '<',
			message: '@',
			icon: '@',
			class: '@',
			onClick: '&'
		},
		templateUrl: 'component/back-button/back-button.html',
		controller: backButtonCtrl
	});
}());