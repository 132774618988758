 (function() {
	var remarketApp = angular.module('remarketApp', [
		'remarketApp.config', 'ngAnimate', 'ngSanitize', 'ui.router', 'ngStorage',
		'angular-loading-bar', 'angular-growl', 'bng-selector', 'tmh.dynamicLocale',
		'ui.utils.masks', 'ngFileUpload', 'datePicker', '720kb.tooltips', 'ngMask', 'pikaday',
		'firebase', 'ui.bootstrap', 'ngTouch', 'ngFileSaver'
	]);

	remarketApp.value('cgBusyDefaults',{
		message:'Processando'
	});

	var remarketConfig = function($urlRouterProvider, $locationProvider, growlProvider,
								  $urlMatcherFactoryProvider, $httpProvider, envConfig, pikaday) {
		Iugu.setAccountID(envConfig.iuguKey);

		if (envConfig.env != 'production') {
			Iugu.setTestMode(true);
		}

		$locationProvider.html5Mode(true);

		// For any unmatched url, redirect to /landingPage
		$urlRouterProvider.otherwise('/');

		$urlMatcherFactoryProvider.caseInsensitive(true);
		$urlMatcherFactoryProvider.strictMode(false);

		// internet explorer holds cache, so we need to tell it not to cache
		$httpProvider.defaults.headers.common['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
		$httpProvider.defaults.headers.common['Pragma'] = 'no-cache';

		// url interceptor
		$httpProvider.interceptors.push('urlInterceptor');

		// remarket busy solution
		$httpProvider.interceptors.push('busyInterceptor');

		$httpProvider.defaults.useXDomain = true;
		//$httpProvider.defaults.withCredentials = true;

		// growl time to live
//		growlProvider.globalTimeToLive(-1); //Will wait until user decides to close the notification
		growlProvider.globalTimeToLive(5000);
		growlProvider.globalDisableCountDown(true);

		// no icons
		growlProvider.globalDisableIcons(true);

		// pikaday config
		var locales = {
			pt: {
				previousMonth : 'Mês anterior',
				nextMonth     : 'Próximo mês',
				months        : ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto",
					"Setembro", "Outubro", "Novembro", "Dezembro"],
				weekdays      : ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
				weekdaysShort : ["D", "S", "T", "Q", "Q", "S", "S"]
			}
		};

		pikaday.setConfig({
			i18n: locales.pt,
			locales: locales
		});

		Highcharts.setOptions({
			lang: {
				drillUpText: '◁ Voltar'
			}
		});
		
		// Initialize Firebase
		var config = {
			apiKey: envConfig.firebaseApiKey,
		    authDomain: envConfig.firebaseAuthDomain,
		    databaseURL: envConfig.firebaseDatabaseURL,
		    projectId: envConfig.firebaseProjectId,
		    storageBucket: envConfig.firebaseStorageBucket,
		    messagingSenderId: envConfig.firebaseMessagingSenderId
		};
		
		firebase.initializeApp(config);
	};

	remarketConfig.$inject = ['$urlRouterProvider', '$locationProvider', 'growlProvider',
		'$urlMatcherFactoryProvider', '$httpProvider', 'envConfig', 'pikadayConfigProvider'];
	
	remarketApp.config(remarketConfig);
}());
