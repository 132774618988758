(function() {
	var remarketApp = angular.module('remarketApp');
	var panelController = function($http, $firebaseArray, $scope, envConfig) {
		var ctrl = this;
		delete ctrl.notifications;

		ctrl.getUser = function() {
			return remarketApp.currentUser;
		};

		ctrl.logout = function() {
			remarketApp.logout();
		};

		ctrl.delete = function(notification) {
			notification.deleted = true;
			$http.delete('/api/notifications/' + notification.$id).then(function(response) {
				ctrl.auctionItems = response.data;
			}, remarketApp.handleError);
		};
		
		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			var ref = firebase.database().ref(envConfig.envFirebase).child("notifications/" + remarketApp.getUser().id);

			var notificationRef = ref.orderByChild("deleted").equalTo(false);
			ctrl.emptyNotification = 'Carregando notificações...';
			ctrl.notifications = $firebaseArray(notificationRef);
			ctrl.notifications.$loaded().then(function() {
				ctrl.emptyNotification = 'Não há nenhuma nova notificação.';
			}).catch(function() {
				ctrl.emptyNotification = 'Erro ao carregar lista de notificações.';
				console.error("Error:", error);
			});

			ref.on('child_changed', function() {
				remarketApp.success('Notificação removida.');
			});
		}

		$scope.$on('remarket-login', init);

		init();
	};
	panelController.$inject = ['$http', '$firebaseArray', '$scope', 'envConfig'];
	remarketApp.controller('panelController', panelController);

	var panelStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel', {
				url: '/panel',
				templateUrl: 'view/panel/panel.html',
				controller: panelController,
				controllerAs: 'panelController'
			});
	};
	panelStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(panelStateProvider);
}());
