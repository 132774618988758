(function() {
	var remarketApp = angular.module('remarketApp');
	
	var auctionItemInterestsService = function($firebaseObject, envConfig) {
		var service = this;
		
		var auctionItemInterests = $firebaseObject(firebase.database()
			.ref(envConfig.envFirebase).child('auctionItemInterests/' + remarketApp.getUser().id));

		auctionItemInterests.$loaded().then(function(data) {
			if (auctionItemInterests.ids == null) {
				auctionItemInterests.ids = [];
			}
		});
		
		service.addInterest = function(auctionItem) {
			var contains = _.some(auctionItemInterests.ids, function (item) {
				return item == auctionItem.id;
			});
			if (contains) return;
			if (auctionItemInterests.ids == null) {
				auctionItemInterests.ids = [];
			}
			auctionItemInterests.ids.push(auctionItem.id);
			auctionItemInterests.$save();
		};

		service.removeInterest = function(auctionItem) {
			_.remove(auctionItemInterests, function(item) {
				return item.id = auctionItem.id;
			});
			auctionItemInterests.$save();
		};
		
		service.getInterests = function() {
			return auctionItemInterests; 
		}
	};
	auctionItemInterestsService.$inject = ['$firebaseObject', 'envConfig'];
	remarketApp.service('auctionItemInterestsService', auctionItemInterestsService);
}());