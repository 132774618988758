(function() {
	var remarketApp = angular.module('remarketApp');
	
	var paginatorCtrl = function($attrs) {
		var ctrl = this;
		
		ctrl.length = $attrs.length || 1;
		ctrl.currentItem = 0;

		ctrl.previousItem = function() {
			if (ctrl.length <= 1) return;
			if (ctrl.currentItem == 0) {
				ctrl.currentItem = ctrl.length - 1;
			} else {
				ctrl.currentItem--;
			}
			ctrl.goTo({index: ctrl.currentItem});
		};
		
		ctrl.nextItem = function() {
			if (ctrl.length <= 1) return;
			if (ctrl.currentItem >= ctrl.length - 1) {
				ctrl.currentItem = 0;
			} else {
				ctrl.currentItem++;
			}
			ctrl.goTo({index: ctrl.currentItem});
		};
	};
	paginatorCtrl.$inject = ['$attrs'];

	remarketApp.component('paginator', {
		bindings: {
			length: '@',
			goTo: '&'
		},
		templateUrl: 'component/paginator/paginator.html',
		controller: paginatorCtrl
	});
}());