(function() {
	var remarketApp = angular.module('remarketApp');

	var auctionItemEnum = function() {
		service = this;

		service.statusTypes = [
			{
				value: 'NEW',
				description: 'Novo'
			},
			{
				value: 'SCHEDULED',
				description: 'Agendado'
			},
			{
				value: 'RUNNING',
				description: 'Em negociação'
			},
			{
				value: 'SOLD',
				description: 'Vendido'
			},
			{
				value: 'NOT_SOLD',
				description: 'Não vendido'
			},
		];
	};
	remarketApp.service('auctionItemEnum', auctionItemEnum);

	var auctionItemStatus = function (auctionItemEnum) {
		return function (value) {
			for (var i = 0; i < auctionItemEnum.statusTypes.length; i++) {
				if (auctionItemEnum.statusTypes[i].value == value) {
					return auctionItemEnum.statusTypes[i].description;
				}
			}
		};
	};
	auctionItemStatus.$inject = ['auctionItemEnum'];
	remarketApp.filter('auctionItemStatus', auctionItemStatus);
}());