(function() {
	var remarketApp = angular.module('remarketApp');

	var companyController = function(companyCreationService, $scope, $http, fipeService, $stateParams) {
		var ctrl = this;
		ctrl.company = {};

		ctrl.segments = [];

		ctrl.setSelectedSegments = function (interests) {
			ctrl.company.interests = [];
			for (var i = 0; i < interests.length; i++) {
				ctrl.company.interests.push(interests[i].value);
			}
		};

		ctrl.setSelectedBrands = function (brands) {
			ctrl.company.brands = [];
			for (var i = 0; i < brands.length; i++) {
				ctrl.company.brands.push(brands[i].Value);
			}
		};

		ctrl.save = function() {
			companyCreationService.saveCompany(ctrl.company);
		};

		ctrl.fulfillAddress = function() {
			if (!ctrl.company.zipAddress || ctrl.company.zipAddress.length != 8) {
				return remarketApp.info('O CEP deve ser válido e estar no formato 00000-000.');
			}
			$http.get('/api/address/' + ctrl.company.zipAddress).then(function (response) {
				ctrl.cityState = response.data.city.name + ' - ' + response.data.city.state.name;
				ctrl.company.streetAddress = response.data.street;
				ctrl.company.city = response.data.city.name;
				ctrl.company.state = response.data.city.state.id;
				remarketApp.info('Endereço carregado com sucesso.');
			}, remarketApp.handleError);
		};
		
		ctrl.activateCompany = function() {
			companyCreationService.activateCompany(ctrl.company);
		};

		ctrl.deactivateCompany = function() {
			companyCreationService.deactivateCompany(ctrl.company);
		};
		
		ctrl.showInterestsSelection = function() {
			return ctrl.company.companyType == 'STORE';
		}
		
		ctrl.showBrandsSelection = function() {
			return ctrl.company.companyType == 'DEALER';
		}
		
		ctrl.showSegmentsSelection = function() {
			return ctrl.company.companyType == 'FLEET_OWNER';
		}

		ctrl.checkedExemptInspection = function() {
			ctrl.company.inspectionItemPrice = null;
		}

		ctrl.isSeller = function() {
			return ctrl.company.companyType == 'FLEET_OWNER' || ctrl.company.companyType == 'DEALER';
		}

		function init() {
			ctrl.availableBrands = fipeService.getBrands();

			if (ctrl.availableBrands == null) {
				$scope.$on('remarket-brands-loaded', function(event, brands){
					ctrl.availableBrands = brands;
				});
			}

			companyCreationService.getCompany(function (company) {
				ctrl.company = company;
				ctrl.preSelectedSegments = [];
				ctrl.segments = [
					{ value: 'AUTOMOBILE', description: 'Automóveis' },
					{ value: 'TRUCK', description: 'Caminhões' },
					{ value: 'HEAVY_MACHINERY', description: 'Máquinas pesadas' }
				];
				
				if (ctrl.company.interests) {
					for (var s = 0; s < ctrl.company.interests.length; s++) {
						ctrl.preSelectedSegments.push({
							value: ctrl.company.interests[s]
						});
					}
				}
				
				if (ctrl.company.brands) {
					ctrl.preSelectedBrands = [];
					for (var b = 0; b < ctrl.company.brands.length; b++) {
						ctrl.preSelectedBrands.push({
							Value: ctrl.company.brands[b]
						});
					}
				}
				
				if (ctrl.company.city) {
					ctrl.cityState = ctrl.company.city + ' - ' + ctrl.company.state;
				}
			}, $stateParams.companyId);
		}

		init();
	};
	companyController.$inject = ['companyCreationService', '$scope', '$http', 'fipeService', '$stateParams'];
	remarketApp.controller('companyController', companyController);

	var companyStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.company', {
			url:  '/company',
			templateUrl: 'view/panel/company/company.html',
			controller: companyController,
			controllerAs: 'companyController'
		}).state('landingPage.panel.company-edit', {
			url:  '/company/:companyId',
			templateUrl: 'view/panel/company/company.html',
			controller: companyController,
			controllerAs: 'companyController'
		});
	};
	companyStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(companyStateProvider);
}());