(function() {
	var remarketApp = angular.module('remarketApp');

	var vehicleDetailsCtrl = function($attrs, $http, $scope, $window) {
		var ctrl = this;
		ctrl.addMotorMarketDocDays = $attrs.addMotorMarketDocDays;
		ctrl.showDocuments = $attrs.showDocuments;
		
		if (!ctrl.addMotorMarketDocDays) {
			ctrl.addMotorMarketDocDays = false;
		}
		
		if (!ctrl.showDocuments) {
			ctrl.showDocuments = true;
		}
		
		$scope.$watch(function() {
			return ctrl.vehicleId;
		}, function() {
			if (ctrl.vehicleId) {
				clearPictures();
				$http.get('/api/vehicles/details/' + ctrl.vehicleId + '?addMotorMarketDocDays=' + ctrl.addMotorMarketDocDays + 
																	   '&showDocuments=' + ctrl.showDocuments).then(function(response) {
					ctrl.vehicle = response.data;
					loadPictures();
				}, remarketApp.handleError);
			}
		});
		
		ctrl.saveComplements = function() {
			var car = {
				"id": ctrl.vehicleId,
				"observation": ctrl.vehicle.observation,
				"daysDocumentationPreparation": ctrl.vehicle.daysDocumentationPreparation,
			};
			
			$http.post('/api/vehicles/complement/' + ctrl.vehicleId, car).then(function(response) {
				remarketApp.success('Complementos alterados com sucesso. ');
			}, remarketApp.handleError);
		};

		ctrl.goBack = function() {
			ctrl.vehicle = null;
			ctrl.onClose();
		};

		ctrl.goToPicture = function(index) {
			ctrl.currentPicture = ctrl.pictures[index];
		};
		
		ctrl.goToDamagePicture = function(index) {
			ctrl.currentDamagePicture = ctrl.damagePictures[index].url;
			ctrl.currentDamage = ctrl.damagePictures[index];
		};

		ctrl.goToDocument = function(index) {
			ctrl.currentDocument = ctrl.documents[index];
		};

		ctrl.hasDamagePhotos = function() {
			return ctrl.damagePictures && ctrl.damagePictures.length > 0;
		}
		
		function clearPictures() {
			delete ctrl.pictures;
			delete ctrl.currentPicture;

			delete ctrl.documents;
			delete ctrl.currentDocument;
		}

		function loadPictures() {
			var showableDocuments = _.filter(_.pluck(ctrl.vehicle.documents, "url"), isPicture);
			
			ctrl.documents = _.union(_.pluck(ctrl.vehicle.estimates, "url"), showableDocuments);

			if (!_.isEmpty(ctrl.vehicle.estimates)) {
				var estimatesURL = _.pluck(ctrl.vehicle.estimates, "url");
				ctrl.documents = _.union(estimatesURL, showableDocuments);
			} else {
				var showableInspections = _.filter(ctrl.vehicle.inspections, isPicture);
				ctrl.documents = _.union(showableInspections, showableDocuments);				
			}

			ctrl.currentDocument = ctrl.documents[0];

			ctrl.pictures = _.filter(ctrl.vehicle.pictures, isNotDamagePicture);
			ctrl.pictures = _.map(ctrl.pictures, 'url');
			ctrl.damagePictures = _.filter(ctrl.vehicle.pictures, isDamagePicture);
			
			ctrl.currentPicture = ctrl.pictures[0];
			
			if (!_.isEmpty(ctrl.damagePictures)) {
				ctrl.currentDamagePicture = ctrl.damagePictures[0].url;
				ctrl.currentDamage = ctrl.damagePictures[0];
			}
		}

		function isDamagePicture(picture) {
			return isPicture(picture.url) && (picture.observation && picture.observation.length > 0);
		}
		
		function isNotDamagePicture(picture) {
			return isPicture(picture.url) && (!picture.observation || picture.observation.length == 0);
		}
		
		function isPicture(url) {
			return  url.substr(-3).toLowerCase() == "jpg" ||
				   url.substr(-4).toLowerCase() == "jpeg" ||
				   url.substr(-3).toLowerCase() == "png" ||
				   url.substr(-3).toLowerCase() == "bmp";
		}

		ctrl.currentDocumentIsPicture = function() {
			if (ctrl.currentDocument) {
				return isPicture(ctrl.currentDocument);
			}
			
			return false;
		}

		ctrl.currentDocumentIsPDF = function() {
			if (ctrl.currentDocument) {
				return ctrl.currentDocument.substr(-3).toLowerCase() == "pdf";
			}
			
			return false;
		}

		ctrl.enlarge = function() {
			if (ctrl.currentDocumentIsPicture()) {
				ctrl.fullPhoto = ctrl.currentDocument;
			} else {
				$window.open(ctrl.currentDocument, '_blank');
			}
		};
		
		ctrl.closeMessage = $attrs.closeMessage || 'Voltar';
		ctrl.closeIcon = $attrs.closeIcon || 'fa fa-angle-double-left';
		
		if ($attrs.edit) {
			ctrl.edit = $attrs.edit;
		} else {
			ctrl.edit = false;
		}
	};
	vehicleDetailsCtrl.$inject = ['$attrs', '$http', '$scope', '$window'];

	remarketApp.component('vehicleDetails', {
		bindings: {
			vehicleId: '<',
			onClose: '&',
			closeMessage: '<',
			closeIcon: '@',
			edit: '<',
			addMotorMarketDocDays: '<',
			showDocuments: '<'
		},
		templateUrl: 'view/panel/vehicle/details/vehicle-details.html',
		controller: vehicleDetailsCtrl
	});
}());
