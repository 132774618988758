(function() {
	var remarketApp = angular.module('remarketApp');

	var negotiationAdminCtrl = function($http, $firebaseObject, $scope, envConfig, $timeout) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.emptyNotification = 'Carregando lotes...';
			ctrl.auctionRunning = false;

			ctrl.loadingData = true;
			$http.get('/api/dashboard/admin/current-auction').then(function(response) {
				ctrl.dashboardData = response.data;
				ctrl.loadingData = false;
				
				if (ctrl.dashboardData.auctionRunning) {
					loadCurrentPerModelChart();
					loadCurrentPerStateAndCity();
					firebase.database().ref(envConfig.envFirebase).child('auction').once('value').then(function(snapshot) {
						ctrl.auctionRunning = snapshot.exists();
						ctrl.auction = snapshot.val();
						reloadDescription();
					});
				} else {
					ctrl.emptyNotification = 'Não há nenhum evento de venda em andamento no momento.'
				}
			}, remarketApp.handleError);
		}

		function reloadDescription() {
			ctrl.auctionItems = [];
			if (!ctrl.auctionRunning) {
				ctrl.emptyNotification = 'Nenhum lote em negociação.';
				return;
			}
			ctrl.startDate = new Date(ctrl.auction.startDate);
			ctrl.endDate = new Date(ctrl.auction.endDate);

			var numberOfItems = ctrl.auction.auctionItemsIds == null ? 0 :ctrl.auction.auctionItemsIds.length;
			ctrl.emptyNotification = numberOfItems + ' lotes em negociação';

			if (numberOfItems > 0) {
				_.forEach(ctrl.auction.auctionItemsIds, function(auctionItemId) {
					var auctionItem = $firebaseObject(firebase.database()
						.ref(envConfig.envFirebase).child('auctionItem/' + auctionItemId));

					auctionItem.$loaded().then(function() {
						auctionItem.currentVehicle = 0;
						ctrl.auctionItems.push(auctionItem);
					});

					auctionItem.$watch(function() {
						auctionItem.currentVehicle = 0;
						auctionItem.newPrice = true;
						$timeout(function() {
							auctionItem.newPrice = false;
						}, 500);
					});
				});
			}
		}

		function loadCurrentPerStateAndCity() {
			var yAxis = {
				title: {
					text: 'Quantidade'
				}
			};

			var drilldown = {
				series: []
			};

			_.each(ctrl.dashboardData.currentPerState.data, function (state) {
				state.y = state.value;
				state.drilldown = state.name;
				var stateDrilldown = {
					name: state.name,
					id: state.name,
					data: []
				};
				drilldown.series.push(stateDrilldown);

				_.each(ctrl.dashboardData.currentPerCity.data, function (city) {
					city.y = city.value;
					if (city.group == state.name) {
						stateDrilldown.data.push([city.name, city.value]);
					}
				});
			});

			var series = [{
				data: ctrl.dashboardData.currentPerState.data
			}];

			loadColumnChart('current-per-state', 'Vendas por região', 'Clique em uma região para ver detalhes', null,
				yAxis, series, true, drilldown);
		}

		function loadCurrentPerModelChart() {
			var yAxis = {
				title: {
					text: 'Quantidade'
				}
			};
			_.each(ctrl.dashboardData.currentPerModel.data, function (entry) {
				entry.y = entry.value;
			});
			var series = [{
				data: ctrl.dashboardData.currentPerModel.data
			}];

			loadColumnChart('current-per-model', 'Negociações por modelo', null,
				ctrl.dashboardData.currentPerModel.categories, yAxis, series, true);
		}

		function loadColumnChart(elementId, title, subtitle, categories, yAxis, series, disableLegend, drilldown) {
			var chart = {
				chart: {
					backgroundColor: null,
					height: 300,
					type: 'column'
				},
				title: {
					text: title
				},
				subtitle: {
					text: subtitle
				},
				tooltip: {
					formatter: function() {
						return 'Veículos em negociação' + ': ' + this.y;
					}
				},
				xAxis: {
					type: 'category',
					categories: categories
				},
				yAxis: yAxis,
				legend: {
					labelFormatter: function () {
						if (this.name.length > 20) {
							return this.name.substr(0,20) + '...';
						}
						return this.name;
					},
					enabled: !disableLegend
				},
				series: series,
				drilldown: drilldown,
				credits: {
					enabled: false
				}
			};
			if (!drilldown) {
				delete chart.drilldown;
			}
			Highcharts.chart(elementId, chart);
		}

		ctrl.previousVehicle = function(auctionItem) {
			if (auctionItem.currentVehicle == 0) {
				auctionItem.currentVehicle = auctionItem.vehicles.length;
			}
			auctionItem.currentVehicle--;
		};

		ctrl.nextVehicle = function(auctionItem) {
			auctionItem.currentVehicle++;
			if (auctionItem.currentVehicle == auctionItem.vehicles.length) {
				auctionItem.currentVehicle = 0;
			}
		};

		ctrl.openVehicleDetails = function(auctionItem) {
			document.body.style.overflow = 'hidden';
			ctrl.vehicleDetailsId = auctionItem.vehicles[auctionItem.currentVehicle].id;
		};

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
		};

		$scope.$on('remarket-login', init);

		init();
	};
	negotiationAdminCtrl.$inject = ['$http', '$firebaseObject', '$scope', 'envConfig', '$timeout'];
	remarketApp.controller('negotiationAdminCtrl', negotiationAdminCtrl);

	var negotiationAdminStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiationAdmin', {
			url: '/negotiation/admin',
			templateUrl: 'view/panel/negotiation/admin/negotiation.html',
			controller: negotiationAdminCtrl,
			controllerAs: 'negotiationAdminCtrl'
		});
	};
	negotiationAdminStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(negotiationAdminStateProvider);
}());