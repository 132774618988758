(function () {
	var remarketApp = angular.module('remarketApp');

	var clientInstanceAuctionItemBatchCtrl = function($http, $scope, $stateParams, auctionItemService, $filter, $state) {
		var ctrl = this;
		ctrl.vehiclesSelectedForSale = [];
		ctrl.minimumPrice = 0;

		ctrl.title = '';
		
		ctrl.auction = {
			startDate: null,
			endDate: null,
			vehicles: []
		};

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			if (remarketApp.isAdmin()) {
				ctrl.title = 'Detalhes da venda';
			} else {
				ctrl.title = 'Configuração da venda';
			}
			
			if ($stateParams.negotiationId) {
				$http.get('/api/auctions/client/' + $stateParams.negotiationId).then(function(response) {
					ctrl.auction = response.data;
					
					if (!remarketApp.isAdmin()) {
						$http.get('/api/vehicles/tradable').then(function (response) {
							ctrl.vehicles = getSelectableVehicles(response.data);

							for (var i = 0; i < ctrl.auction.vehicles.length; i++) {
								ctrl.vehiclesSelectedForSale.push({
									vehicleId: ctrl.auction.vehicles[i].id,
									plate: ctrl.auction.vehicles[i].plate,
									price: ctrl.auction.vehicles[i].minimumPrice,
									buyNowPrice: ctrl.auction.vehicles[i].buyNowPrice,
									description: ctrl.auction.vehicles[i].description,
									origin: ctrl.auction.vehicles[i].origin,
									externalInspectionId: ctrl.auction.vehicles[i].externalInspectionId,
									showToIndividuals: ctrl.auction.vehicles[i].showToIndividuals
								});
							}
							delete ctrl.auction.vehicles;
						});
					} else {
						for (var i = 0; i < ctrl.auction.vehicles.length; i++) {
							ctrl.vehiclesSelectedForSale.push({
								vehicleId: ctrl.auction.vehicles[i].id,
								plate: ctrl.auction.vehicles[i].plate,
								price: ctrl.auction.vehicles[i].minimumPrice,
								buyNowPrice: ctrl.auction.vehicles[i].buyNowPrice,
								description: ctrl.auction.vehicles[i].description,
								origin: ctrl.auction.vehicles[i].origin,
								externalInspectionId: ctrl.auction.vehicles[i].externalInspectionId,
								showToIndividuals: ctrl.auction.vehicles[i].showToIndividuals
							});
						}
					}
				}, remarketApp.handleError);
			} else {
				$http.get('/api/vehicles/tradable').then(function (response) {
					ctrl.vehicles = getSelectableVehicles(response.data);

					if (ctrl.vehicles.length == 0) {
						remarketApp.warning('Não foram encontrados veículos vistoriados e prontos para serem negociados.');
					}
				}, remarketApp.handleError);
			}
			
			$http.get('/api/auctions/client/maxDuration').then(function(response) {
				ctrl.maxDurationNegotiation = response.data.maxDurationNegotiation;
			}, remarketApp.handleError);
			
			ctrl.wizardSteps[1].title = 'Veículos';
		}

		function getSelectableVehicles(vehicles) {
			var selectable = _.filter(vehicles, function(v) { return v.updatable; });
			
			for (var i = 0; i < selectable.length; i++) {
				selectable[i].selectorDescription = $filter('plate')(selectable[i].plate) + ' - ' + selectable[i].description;
			}

			return selectable;
		}

		$scope.$on('remarket-login', init);
		
		ctrl.setSelectedVehicles = function(selectedVehicles) {
			ctrl.selectedVehicles = selectedVehicles;
		};

		ctrl.addVehicles = function() {
			for (var i = 0; i < ctrl.selectedVehicles.length; i++) {
				var selectedVehicle = ctrl.selectedVehicles[i];
				var contains = _.findWhere(ctrl.vehiclesSelectedForSale, { plate: selectedVehicle.plate }) != null;
				if (!contains) {
					ctrl.vehiclesSelectedForSale.push(selectedVehicle);
					ctrl.minimumPrice += selectedVehicle.price;

					let index = _.findIndex(ctrl.vehicles, function(vehicle){ 
						return vehicle.plate == selectedVehicle.plate 
					});
					if (index > -1) {
						ctrl.vehicles.splice(index, 1);
					}
				}
			}
		};

		ctrl.removeVehicle = function(index) {
			ctrl.minimumPrice -= ctrl.vehiclesSelectedForSale[index].price;
			var vehicle = ctrl.vehiclesSelectedForSale[index];
			if (!vehicle.selectorDescription) {
				vehicle.selectorDescription = $filter('plate')(vehicle.plate) + ' - ' + vehicle.description;
			}

			ctrl.vehicles.push(vehicle);
			
			ctrl.vehicles = _.sortBy(ctrl.vehicles, "plate");

			ctrl.vehiclesSelectedForSale.splice(index, 1);
		};

		ctrl.loadVehicleDetails = function(vehicle) {
			ctrl.vehicleDetailsId = vehicle.vehicleId;
		};

		ctrl.closeVehicleDetails = function() {
			ctrl.vehicleDetailsId = null;
		};
		
		ctrl.minimumNegotiationStartDate = function() {
			return moment().add(1, 'days').toDate().setHours(0,0,0,0);
		}
		
		ctrl.goBack = function() {
			if (!ctrl.isEditable()) {
				return remarketApp.go('landingPage.panel.client-instance-auction-items');
			}
			if ($stateParams.negotiationId) {
				remarketApp.go('landingPage.panel.client-instance-auction-items-edit-pricing', {
					negotiationId: $stateParams.negotiationId
				});
			} else {
				remarketApp.go('landingPage.panel.client-instance-auction-items-new-pricing');
			}
		};

		ctrl.next = function() {
			var error = false;
			if (!ctrl.auction.startDate) {
				remarketApp.error("Informe a data de início da negociação");
				error = true;
			}
			
			if (!ctrl.auction.endDate) {
				remarketApp.error("Informe a data de término da negociação");
				error = true;
			}
			
			if (moment().isAfter(ctrl.auction.startDate)) {
				remarketApp.error("A data de início do leilão deve ser posterior a data atual");
				error = true;
			}
			
			var mStartDate = moment(ctrl.auction.startDate); 
			if (mStartDate.isAfter(ctrl.auction.endDate)) {
				remarketApp.error("A data de término do leilão deve ser igual ou posterior a data inicial");
				error = true;
			}

			var mEndDate = moment(ctrl.auction.endDate)
			
			var diff = moment.duration(mEndDate.diff(mStartDate));
			if (diff.days() > ctrl.maxDurationNegotiation) {
				remarketApp.error("A duração da negociação não deve ser maior do que " + maxDurationNegotiation + " dias.");
				error = true;
			}
			
			if (ctrl.vehiclesSelectedForSale.length == 0) {
				remarketApp.error("Ao menos um veículo deve ser selecionado.");
				error = true;
			}
			
			if (error) {
				return;
			}
			
			var clientInstanceNegotiationEndpoint = '/api/auctions/client/';
			if (ctrl.auction.id) {
				clientInstanceNegotiationEndpoint += ctrl.auction.id;
			}

			ctrl.auction.vehicles = [];
			for (var i = 0; i < ctrl.vehiclesSelectedForSale.length; i++) {
				ctrl.auction.vehicles.push({
					vehicleId: ctrl.vehiclesSelectedForSale[i].vehicleId,
					showToIndividuals: ctrl.vehiclesSelectedForSale[i].showToIndividuals
				});
			}

			$http.post(clientInstanceNegotiationEndpoint, ctrl.auction).then(function(response) {
				remarketApp.success('Negociação salva com sucesso.');
				remarketApp.go('landingPage.panel.negotiations-client-instance', {
					negotiationId: response.data.id
				});
			}, remarketApp.handleError);
		};
		
		ctrl.isEditable = function() {
			if (remarketApp.isAdmin()) {
				return false;
			}
			if (!ctrl.auction) {
				return true;
			}
			return !ctrl.auction.status || 
				ctrl.auction.status == 'NEW' || ctrl.auction.status == 'SCHEDULED';
		};
		
		ctrl.wizardSteps = auctionItemService.wizardSteps;
		ctrl.wizardSteps[0].onClick = ctrl.goBack;
		ctrl.wizardSteps[2].onClick = ctrl.next;

		init();
	};
	clientInstanceAuctionItemBatchCtrl.$inject = ['$http', '$scope', '$stateParams', 'auctionItemService', '$filter', '$state'];
	remarketApp.controller('clientInstanceAuctionItemBatchCtrl', clientInstanceAuctionItemBatchCtrl);

	var clienteInstanceAuctionItemBatchConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.client-instance-auction-items-new', {
			url: '/auction-items/client-instance/vehicles/new',
			templateUrl: 'view/panel/negotiation/client-instance/items/client-instance-auction-items.html',
			controller: clientInstanceAuctionItemBatchCtrl,
			controllerAs: 'clientInstanceAuctionItemBatchCtrl'
		}).state('landingPage.panel.client-instance-auction-items-edit', {
			url: '/auction-items/client-instance/vehicles/:negotiationId',
			templateUrl: 'view/panel/negotiation/client-instance/items/client-instance-auction-items.html',
			controller: clientInstanceAuctionItemBatchCtrl,
			controllerAs: 'clientInstanceAuctionItemBatchCtrl'
		});
	};
	clienteInstanceAuctionItemBatchConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(clienteInstanceAuctionItemBatchConfigStateProvider);
}());