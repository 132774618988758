(function() {
	var remarketApp = angular.module('remarketApp');
	var companiesController = function($http, companyCreationService) {
		var ctrl = this;
		
		ctrl.name = null;
		ctrl.type = null;
		ctrl.city = null;
		ctrl.state = null;
		ctrl.active = null;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;
		
		ctrl.activateCompany = function(company) {
			companyCreationService.activateCompany(company);
		};

		ctrl.deactivateCompany = function(company) {
			companyCreationService.deactivateCompany(company);
		};
		
		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}

		function init() {
			ctrl.load();
		}
		
		ctrl.pagination = function() {
			ctrl.load();
		}
		
		ctrl.setType = function(type) {
			ctrl.type = type;
			ctrl.load();
		}
		
		ctrl.setActive = function(active) {
			ctrl.active = active;
			ctrl.load();
		}
		
		ctrl.load = function() {
			ctrl.companies = {};
			
			$http.get('/api/company/admin', {
				params: {
					name: ctrl.name,
					type: ctrl.type,
					city: ctrl.city,
					state: ctrl.state,
					active: ctrl.active,
					page: ctrl.currentPage - 1, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.companies = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		};

		init();
	};
	companiesController.$inject = ['$http', 'companyCreationService'];
	remarketApp.controller('companiesController', companiesController);

	var companiesStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.companies', {
				url: '/companies',
				templateUrl: 'view/panel/company/companies.html',
				controller: companiesController,
				controllerAs: 'companiesController'
			});
	};
	companiesStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(companiesStateProvider);
}());
