(function() {
	var remarketApp = angular.module('remarketApp');

	var negotiationCtrl = function($firebaseObject, $scope, envConfig) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.emptyNotification = 'Carregando lotes...';
			ctrl.negotiationRunning = false;

			firebase.database().ref(envConfig.envFirebase).child('auction').once('value').then(function(snapshot) {
				ctrl.negotiationRunning = snapshot.exists();
				ctrl.auction = snapshot.val();
				reloadDescription();
			});
		}

		function reloadDescription() {
			ctrl.interests = [];
			if (!ctrl.negotiationRunning) {
				ctrl.emptyNotification = 'Nenhum lote em negociação';
				return;
			}
			ctrl.startDate = new Date(ctrl.auction.startDate);
			ctrl.endDate = new Date(ctrl.auction.endDate);

			firebase.database().ref(envConfig.envFirebase)
				.child('auctionItemInterests')
				.child(remarketApp.getUser().id)
				.on('value', function(snapshot) {
					ctrl.interests = [];
					if (!snapshot.exists()) {
						ctrl.emptyNotification = 'Nenhum lote em negociação';
						$scope.$digest();
						return;
					}

					var userInterests = snapshot.val();
					var numberOfItems = userInterests.ids == null ? 0 : userInterests.ids.length;

					ctrl.emptyNotification = numberOfItems + ' lotes em negociação';

					if (numberOfItems > 0) {
						_.forEach(userInterests.ids, function(auctionItemId) {
							var auctionItem = $firebaseObject(firebase.database()
								.ref(envConfig.envFirebase).child('auctionItem/' + auctionItemId));

							auctionItem.$loaded().then(function() {
								ctrl.interests.push(auctionItem);
							});
						});
					}
			});
		}

		$scope.$on('remarket-login', init);

		init();
	};
	negotiationCtrl.$inject = ['$firebaseObject', '$scope', 'envConfig'];
	remarketApp.controller('negotiationCtrl', negotiationCtrl);

	var negotiationStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiation', {
			url: '/negotiation',
			templateUrl: 'view/panel/negotiation/negotiation.html',
			controller: negotiationCtrl,
			controllerAs: 'negotiationCtrl'
		});
	};
	negotiationStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(negotiationStateProvider);
}());