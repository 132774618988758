(function() {
	var remarketApp = angular.module('remarketApp');
	var loginController = function($http, $scope) {
		var ctrl = this;
		ctrl.user = {};
		ctrl.message = {};

		ctrl.login = function () {
			remarketApp.authenticate(ctrl.user);
		};

		$scope.$on('remarket-login', function(event, user) {
			if (user.companyType == 'STORE') {
				remarketApp.go('landingPage.panel.auction');
			} else if (user.profileType == 'ADMIN') {
				remarketApp.go('landingPage.panel.admin-dashboard');
			} else if (user.profileType == 'INSPECTION_DETAILS') {
				remarketApp.go('landingPage.panel.inspection-search');
			} else if (user.profileType == 'INTERNAL_BUYER') {
				remarketApp.go('landingPage.panel.internal-auction');
			} else {
				remarketApp.go('landingPage.panel');
			}
		});

		ctrl.useClientLandingPage = function() {
			return remarketApp.clientInstance;
		}

		ctrl.sendMessage = function() {
			$http.post('/api/contact', ctrl.message).then(function() {
				remarketApp.success('Obrigado por entrar em contato. Analisaremos sua mensagem ' +
					'e responderemos o mais breve possível.');
				ctrl.message = {};
			}, remarketApp.handleError);
		};
	};
	loginController.$inject = ['$http', '$scope'];
	remarketApp.controller('loginController', loginController);

	var loginStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.login', {
				url: '',
				templateUrl: 'view/public/login/login.html',
				controller: loginController,
				controllerAs: 'stateCtrl'
			});
	};
	loginStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(loginStateProvider);
}());
