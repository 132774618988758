(function() {
	var remarketApp = angular.module('remarketApp');

	var enumService = function() {
		var service = this;

		var companyTypes = [
			{ value: 'DEALER', description: 'Concessionária' },
			{ value: 'FLEET_OWNER', description: 'Frotista' },
			{ value: 'STORE', description: 'Lojista' },
			{ value: 'INDIVIDUAL_BUYER', description: 'Pessoa Física' }
		];
		
		var bidTypes = [
			{ value: 'NORMAL', description: 'Normal' },
			{ value: 'BUY_NOW', description: 'Arremate Agora' },
			{ value: 'CONDITIONAL', description: 'Condicional' }
		];

		var inspectionStatus = [
			{
				value: 'NEW',
				description: 'Novo',
				help: 'Processo de vistoria em criação. Após preencher todos os detalhes, realize o pagamento ' +
				'da vistoria.'
			}, {
				value: 'PENDING_PAYMENT',
				description: 'Aguardando pagamento',
				help: 'Estamos aguardando o pagamento e a confirmação do mesmo para dar continuidade.'
			}, {
				value: 'PENDING_SCHEDULE',
				description: 'Em agendamento',
				help: 'Por favor, configure as datas desejadas para vistoria.'
			}, {
				value: 'PENDING_APPROVAL',
				description: 'Aguardando aprovação',
				help: 'Estamos aguardando a avaliação e aprovação da inspeção solicitada.'
			}, {
				value: 'SCHEDULED',
				description: 'Vistoria agendada',
				help: 'Aguarde a vistoria ser realizada para dar prosseguimento no processo de venda.'
			}, {
				value: 'PARTIALLY_INSPECTED',
				description: 'Parcialmente realizada',
				help: 'Os carros avaliados já podem ser anunciados.'
			}, {
				value: 'INSPECTED',
				description: 'Vistoria realizada',
				help: 'Agora é só aguardar o início do processo de venda.'
			}, {
				value: 'IN_PROGRESS',
				description: 'Em execução',
				help: 'A vistoria está sendo efetuada.'
			}, {
				value: 'WAITING',
				description: 'Aguardando Início',
				help: 'A vistoria está pronta para ser iniciada.'
			}, {
				value: 'COMPLETED',
				description: 'Vistoria realizada',
				help: 'Agora é só aguardar o início do processo de venda.'
			}
		];
		
		var inspectionOrigin = [
			{
				value: 'GETEK',
				description: 'Getek'
			}, {
				value: 'MOBILE',
				description: 'Mobile'
			}
		];
		
		var auctionStatus = [
			{
				value: 'SCHEDULED',
				description: 'Agendado',
				help: 'Este leilão está agendado e será iniciado na data desejada.'
			}, {
				value: 'RUNNING',
				description: 'Em andamento',
				help: 'Este leilão está ocorrendo neste momento.'
			}, {
				value: 'CLOSED',
				description: 'Encerrado',
				help: 'Este leilão foi finalizado.'
			}
		];

		service.tradeStatus = [
			{ value: 'PENDING_PAYMENT', description: 'Aguardando pagamento' },
			{ value: 'PENDING_DOCS', description: 'Gestão da documentação' },
			{ value: 'ACCOMPLISHED', description: 'Processo finalizado' }
		];
		
		service.getBidTypesDescription = function(type) {
			for (var i = 0; i < bidTypes.length; i++) {
				if (bidTypes[i].value == type) {
					return bidTypes[i].description;
				}
			}
			return 'Tipo desconhecido';
		};

		service.getCompanyTypeDescription = function(type) {
			for (var i = 0; i < companyTypes.length; i++) {
				if (companyTypes[i].value == type) {
					return companyTypes[i].description;
				}
			}
			return 'Tipo desconhecido';
		};

		service.getInspectionStatusDescription = function(status) {
			for (var i = 0; i < inspectionStatus.length; i++) {
				if (inspectionStatus[i].value == status) {
					return inspectionStatus[i].description;
				}
			}
			return 'Status desconhecido';
		};

		service.getInspectionStatusHelp = function(status) {
			for (var i = 0; i < inspectionStatus.length; i++) {
				if (inspectionStatus[i].value == status) {
					return inspectionStatus[i].help;
				}
			}
			return '?';
		};
		
		service.getInspectionOriginDescription = function(origin) {
			for (var i = 0; i < inspectionOrigin.length; i++) {
				if (inspectionOrigin[i].value == origin) {
					return inspectionOrigin[i].description;
				}
			}
			return 'Origem Desconhecida';
		};
		
		service.findAuctionStatusByValue = function(status) {
			for (var i = 0; i < auctionStatus.length; i++) {
				if (auctionStatus[i].value == status) {
					return auctionStatus[i].description;
				}
			}
			return 'Status desconhecido';
		};
		
		service.getAuctionStatusHelpByValue = function(status) {
			for (var i = 0; i < auctionStatus.length; i++) {
				if (auctionStatus[i].value == status) {
					return auctionStatus[i].help;
				}
			}
			return '?';
		};

		service.findTradeStatusByValue = function (value) {
			return _.find(service.tradeStatus, function(tradeStatus) {
				return tradeStatus.value == value;
			});
		}
	};
	remarketApp.service('enumService', enumService);
	
	var companyTypesHelp = function(enumService) {
		return function (value) {
			return enumService.getCompanyTypeDescription(value);
		};
	};
	companyTypesHelp.$inject = ['enumService'];
	remarketApp.filter('companyTypesHelp', companyTypesHelp);

	var inspectionStatus = function(enumService) {
		return function (value) {
			return enumService.getInspectionStatusDescription(value);
		};
	};
	inspectionStatus.$inject = ['enumService'];
	remarketApp.filter('inspectionStatus', inspectionStatus);
	
	var inspectionOrigin = function(enumService) {
		return function (value) {
			return enumService.getInspectionOriginDescription(value);
		};
	};
	inspectionOrigin.$inject = ['enumService'];
	remarketApp.filter('inspectionOrigin', inspectionOrigin);

	var inspectionStatusHelp = function(enumService) {
		return function (value) {
			return enumService.getInspectionStatusHelp(value);
		};
	};
	inspectionStatusHelp.$inject = ['enumService'];
	remarketApp.filter('inspectionStatusHelp', inspectionStatusHelp);
	
	var bidTypeHelp = function(enumService) {
		return function (value) {
			return enumService.getBidTypesDescription(value);
		};
	};
	bidTypeHelp.$inject = ['enumService'];
	remarketApp.filter('bidTypeHelp', bidTypeHelp);
	
	var auctionStatusFilter = function(enumService) {
		return function (value) {
			return enumService.findAuctionStatusByValue(value);
		};
	};
	auctionStatusFilter.$inject = ['enumService'];
	remarketApp.filter('auctionStatus', auctionStatusFilter);
	
	var auctionStatusHelp = function(enumService) {
		return function (value) {
			return enumService.getAuctionStatusHelpByValue(value);
		};
	};
	auctionStatusHelp.$inject = ['enumService'];
	remarketApp.filter('auctionStatusHelp', auctionStatusHelp);
}());