(function() {
	var remarketApp = angular.module('remarketApp');

	remarketApp.filter('afirmationFilter', function () {
		return function (input) {
			switch (input) {
				case true:
					return 'Sim';
					break;
				case false:
					return 'Não';
					break;
			}
		};
	});
}());