(function() {
	var remarketApp = angular.module('remarketApp');
	var newPasswordController = function($http, $stateParams) {
		var ctrl = this;

		ctrl.newPassword = {
			retrievePasswordUri: $stateParams.uuid
		};

		ctrl.saveNewPassword = function () {
			if (!ctrl.newPassword.newPassword || !ctrl.newPassword.confirmationPassword) {
				return remarketApp.warning('Preencha a nova senha e a confirmação de nova senha.');
			}
			if (ctrl.newPassword.newPassword != ctrl.newPassword.confirmationPassword) {
				return remarketApp.warning('A confirmação de nova senha não coincide com a nova senha.');
			}
			$http.post('/api/webapp/users/new-password', ctrl.newPassword).then(function(response) {
				var user = {
					email: response.data.email,
					password: ctrl.newPassword.newPassword
				};
				remarketApp.authenticate(user);
				remarketApp.success('Sua nova senha foi salva com sucesso.');
			}, remarketApp.handleError);
		};
	};
	newPasswordController.$inject = ['$http', '$stateParams'];
	remarketApp.controller('newPasswordController', newPasswordController);

	var newPasswordStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.newPassword', {
				url: '/new-password/:uuid',
				templateUrl: 'view/public/login/new-password.html',
				controller: newPasswordController,
				controllerAs: 'newPasswordCtrl'
			});
	};
	newPasswordStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(newPasswordStateProvider);
}());
