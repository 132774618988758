(function() {
	var remarketApp = angular.module('remarketApp');

	var clientInstanceAuctionItemPricingCtrl = function($scope, auctionItemService, $stateParams, $state) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			auctionItemService.loadPriceableVehicles(function(tradableVehicles) {
				ctrl.vehicles = tradableVehicles;
			});
			
			ctrl.wizardSteps[1].title = 'Veículos';
		}

		$scope.$on('remarket-login', init);

		ctrl.loadVehicleDetails = function(vehicle) {
			ctrl.vehicleDetailsId = vehicle.vehicleId;
		};

		ctrl.closeVehicleDetails = function() {
			ctrl.vehicleDetailsId = null;
		};

		ctrl.showVisualizeGetekButton = function(vehicle) {
			return vehicle.origin == 'GETEK';
		};
				
		ctrl.showVisualizeMobileButton = function(vehicle) {
			return vehicle.origin == 'MOBILE';
		};
		
		ctrl.closeMobileInspectionDetails = function() {
			ctrl.mobileInspectionId = null;
		}

		ctrl.next = function() {
			var updateList = _.filter(ctrl.vehicles, function(vehicle) {
				return vehicle.updatable;
			});

			if ($stateParams.negotiationId) {
				auctionItemService.savePricing(updateList, 'landingPage.panel.client-instance-auction-items-edit', {
					negotiationId: $stateParams.negotiationId
				});
			} else {
				auctionItemService.savePricing(updateList, 'landingPage.panel.client-instance-auction-items-new');
			}
		};

		ctrl.getMinimumPriceTooltip = function(vehicle) {
			if (vehicle.updatable) {
				return 'Valor mínimo desejado pelo veículo.';
			} else if (!remarketApp.isAdmin()) {
				return 'Alteração não permitida pois está vinculado a outra venda.';
			} else {
				return 'Alteração não permitida.';
			}
		};

		ctrl.getBuyNowPriceTooltip = function(vehicle) {
			if (vehicle.updatable) {
				return 'Valor para arremate instantâneo do veículo.';
			} else if (!remarketApp.isAdmin()) {
				return 'Alteração não permitida pois está vinculado a outra venda.';
			} else {
				return 'Alteração não permitida.';
			}
		};
		
		ctrl.getConditionalPriceTooltip = function(vehicle) {
			if (vehicle.updatable) {
				return 'Valor para ofertas condicionais.';
			} else if (!remarketApp.isAdmin()) {
				return 'Alteração não permitida pois está vinculado a outra venda.';
			} else {
				return 'Alteração não permitida.';
			}
		};

		ctrl.wizardSteps = auctionItemService.wizardSteps;
		ctrl.wizardSteps[1].onClick = ctrl.next;
		ctrl.wizardSteps[2].onClick = function() {
			if (!$stateParams.negotiationId) {
				remarketApp.error('Primeiro você deve selecionar quais os veículos você quer negociar.');
				ctrl.next();
			} else {
				var updateList = _.filter(ctrl.vehicles, function(vehicle) {
					return vehicle.updatable;
				});
				auctionItemService.savePricing(updateList, 'landingPage.panel.client-instance-auction-items-scheduling-batch', {
					negotiationId: $stateParams.negotiationId
				});
			}
		};

		init();
	};
	clientInstanceAuctionItemPricingCtrl.$inject = ['$scope', 'auctionItemService', '$stateParams', '$state'];
	remarketApp.controller('clientInstanceAuctionItemPricingCtrl', clientInstanceAuctionItemPricingCtrl);
	
	var auctionItemPricingConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.client-instance-auction-items-edit-pricing', {
			url: '/auction-items/client-instance-edit/pricing/:negotiationId',
			templateUrl: 'view/panel/negotiation/client-instance/items/client-instance-auction-item-pricing.html',
			controller: clientInstanceAuctionItemPricingCtrl,
			controllerAs: 'clientInstanceAuctionItemPricingCtrl'
		}).state('landingPage.panel.client-instance-auction-items-new-pricing', {
			url: '/auction-items/client-instance/new/pricing',
			templateUrl: 'view/panel/negotiation/client-instance/items/client-instance-auction-item-pricing.html',
			controller: clientInstanceAuctionItemPricingCtrl,
			controllerAs: 'clientInstanceAuctionItemPricingCtrl'
		});
	};
	auctionItemPricingConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionItemPricingConfigStateProvider);
}());