(function () {
	var remarketApp = angular.module('remarketApp');

	var individualProfileController = function($http, $scope) {
		var ctrl = this;
		ctrl.profile = {};
		ctrl.newPassword = {};

		function init() {
			loadProfile();
		}

		function loadProfile() {
			$http.get('/api/individual/').then(function (response) {
				ctrl.profile = response.data;
				ctrl.cityState = ctrl.profile.city + ' - ' + ctrl.profile.state;
			}, remarketApp.handleError);
		}
		
		ctrl.saveProfile = function() {
			var userId = remarketApp.getUser().id;
			$http.post('/api/individual/', ctrl.profile).then(function () {
				remarketApp.success('Dados pessoais salvos com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.savePassword = function() {
			$http.post('/api/users/new-password', ctrl.newPassword).then(function (response) {
				ctrl.profile = response.data;
				ctrl.profile.password = ctrl.newPassword.new;
				ctrl.newPassword = {};
				remarketApp.success('Senha alterada com sucesso.');
				remarketApp.authenticate(ctrl.profile);

				loadProfile();
			}, remarketApp.handleError);
		};

		ctrl.fulfillAddress = function() {
			if (!ctrl.profile.zipAddress || ctrl.profile.zipAddress.length != 8) {
				return remarketApp.info('O CEP deve ser válido e estar no formato 00000-000.');
			}
			$http.get('/api/address/' + ctrl.profile.zipAddress).then(function (response) {
				ctrl.cityState = response.data.city.name + ' - ' + response.data.city.state.name;
				ctrl.profile.streetAddress = response.data.street;
				ctrl.profile.city = response.data.city.name;
				ctrl.profile.state = response.data.city.state.id;
				ctrl.profile.cityId = response.data.city.id;
				remarketApp.info('Endereço carregado com sucesso.');
			}, remarketApp.handleError);
		};

		/*$scope.$on('remarket-login', function(event, user){
			ctrl.profile = user;
		});*/

		init();
	};
	individualProfileController.$inject = ['$http', '$scope'];
	remarketApp.controller('individualProfileController', individualProfileController);

	var profileStateProvider = function ($stateProvider) {
		$stateProvider
			.state('landingPage.panel.individualProfile', {
				url: '/profile/individual',
				templateUrl: 'view/panel/profile/individual/profile.html',
				controller: individualProfileController,
				controllerAs: 'individualProfileController'
			});
	};
	profileStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(profileStateProvider);
}());