(function() {
	var remarketApp = angular.module('remarketApp');

	var auctionItemPricingController = function($scope, auctionItemService, $stateParams, $state) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.spare = $state.is('landingPage.panel.spare-auction-items-new-pricing') ||
				$state.is('landingPage.panel.spare-auction-items-edit-pricing');

			if (ctrl.spare) {
				ctrl.wizardSteps[1].title = 'Veículos'
			} else {
				ctrl.wizardSteps[1].title = 'Lote'
			}

			auctionItemService.loadPriceableVehicles(function(priceableVehicles) {
				ctrl.vehicles = priceableVehicles;
			});
		}

		ctrl.loadVehicleDetails = function(vehicle) {
			ctrl.vehicleDetailsId = vehicle.vehicleId;
		};

		ctrl.closeVehicleDetails = function() {
			ctrl.vehicleDetailsId = null;
		};

		ctrl.next = function() {
			var updateList = _.filter(ctrl.vehicles, function(vehicle) {
				return vehicle.updatable;
			});

			if ($stateParams.auctionItemId) {
				auctionItemService.savePricing(updateList, 'landingPage.panel.auction-items-edit-batch', {
					auctionItemId: $stateParams.auctionItemId
				});
			} else if (ctrl.spare) {
				auctionItemService.savePricing(updateList, 'landingPage.panel.spare-auction-items-new-batch');
			} else if (!ctrl.spare) {
				auctionItemService.savePricing(updateList, 'landingPage.panel.auction-items-new-batch');
			}
		};

		ctrl.getMinimumPriceTooltip = function(vehicle) {
			if (vehicle.updatable) {
				return 'Valor mínimo desejado pelo veículo.';
			} else if (!remarketApp.isAdmin()) {
				return 'Alteração não permitida pois está vinculado a outra venda.';
			} else {
				return 'Alteração não permitida.';
			}
		};

		ctrl.getBuyNowPriceTooltip = function(vehicle) {
			if (vehicle.updatable) {
				return 'Valor para arremate instantâneo do veículo.';
			} else if (!remarketApp.isAdmin()) {
				return 'Alteração não permitida pois está vinculado a outra venda.';
			} else {
				return 'Alteração não permitida.';
			}
		};
		
		ctrl.getConditionalPriceTooltip = function(vehicle) {
			if (vehicle.updatable) {
				return 'Valor para ofertas condicionais.';
			} else if (!remarketApp.isAdmin()) {
				return 'Alteração não permitida pois está vinculado a outra venda.';
			} else {
				return 'Alteração não permitida.';
			}
		};
		
		ctrl.showVisualizeGetekButton = function(vehicle) {
			return vehicle.origin == 'GETEK';
		};
				
		ctrl.showVisualizeMobileButton = function(vehicle) {
			return vehicle.origin == 'MOBILE';
		};
		
		ctrl.closeMobileInspectionDetails = function() {
			ctrl.mobileInspectionId = null;
		}

		ctrl.wizardSteps = auctionItemService.wizardSteps;
		ctrl.wizardSteps[1].onClick = ctrl.next;
		ctrl.wizardSteps[2].onClick = function() {
			if (!$stateParams.auctionItemId) {
				remarketApp.error('Primeiro você deve selecionar quais os veículos você quer negociar.');
				ctrl.next();
			} else {
				var updateList = _.filter(ctrl.vehicles, function(vehicle) {
					return vehicle.updatable;
				});
				auctionItemService.savePricing(updateList, 'landingPage.panel.auction-items-scheduling-batch', {
					auctionItemId: $stateParams.auctionItemId
				});
			}
		};

		$scope.$on('remarket-login', init);
		init();
	};
	auctionItemPricingController.$inject = ['$scope', 'auctionItemService', '$stateParams', '$state'];
	remarketApp.controller('auctionItemPricingCtrl', auctionItemPricingController);
	
	var auctionItemPricingConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.auction-items-new-pricing', {
			url: '/auction-items/new/pricing',
			templateUrl: 'view/panel/auction-item/auction-item-pricing.html',
			controller: auctionItemPricingController,
			controllerAs: 'auctionItemPricingCtrl'
		}).state('landingPage.panel.auction-items-edit-pricing', {
			url: '/auction-items/edit/pricing/:auctionItemId',
			templateUrl: 'view/panel/auction-item/auction-item-pricing.html',
			controller: auctionItemPricingController,
			controllerAs: 'auctionItemPricingCtrl'
		}).state('landingPage.panel.spare-auction-items-new-pricing', {
			url: '/auction-items/spare/new/pricing',
			templateUrl: 'view/panel/auction-item/auction-item-pricing.html',
			controller: auctionItemPricingController,
			controllerAs: 'auctionItemPricingCtrl'
		}).state('landingPage.panel.spare-auction-items-edit-pricing', {
			url: '/auction-items/spare/edit/pricing/:auctionItemId',
			templateUrl: 'view/panel/auction-item/auction-item-pricing.html',
			controller: auctionItemPricingController,
			controllerAs: 'auctionItemPricingCtrl'
		});
	};
	auctionItemPricingConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionItemPricingConfigStateProvider);
}());