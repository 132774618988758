//This interceptor adds the localhost:8080 when the app calls a relative URL
(function() {
	var remarketApp = angular.module('remarketApp');
	var busyInterceptor = function() {
		var service = function($q) {
			remarketApp.busy = {
				unfinishedRequest: 0,
				busy: false
			};

			return {
				'request': function(config) {
					if (config.url && config.url.indexOf('/api') == 0) {
						remarketApp.busy.unfinishedRequest++;
						remarketApp.busy.busy = remarketApp.busy.unfinishedRequest > 0;
					}
					return config;
				},
				'response': function(response) {
					if (response.config.url && response.config.url.indexOf('/api') == 0) {
						remarketApp.busy.unfinishedRequest--;
						remarketApp.busy.busy = remarketApp.busy.unfinishedRequest > 0;
					}
					return response;
				},
				'responseError': function(rejection) {
					if (rejection.config && rejection.config.url && rejection.config.url.indexOf('/api') == 0) {
						remarketApp.busy.unfinishedRequest--;
						remarketApp.busy.busy = remarketApp.busy.unfinishedRequest > 0;
					}
					return $q.reject(rejection);
				}
			};
		};
		service.$inject = ['$q'];
		this.$get = service;
	};
	remarketApp.provider('busyInterceptor', busyInterceptor);
}());