(function() {
	var remarketApp = angular.module('remarketApp');

	var ajaxButtonCtrl = function($scope, $element, $q, $attrs) {
		var ctrl = this;
		ctrl.deriveClass = 'btn';

		ctrl.busy = remarketApp.busy;

		if ($attrs.block == 'true') {
			ctrl.deriveClass += ' btn-block';
		}

		if ($attrs.size) {
			ctrl.deriveClass += ' btn-' + $attrs.size;
		}

		if ($attrs.type) {
			ctrl.deriveClass += ' btn-' + $attrs.type;
		}

		if ($attrs.styleclass) {
			ctrl.deriveClass += ' ' + $attrs.styleclass;
		}

		if ($attrs.icon) {
			ctrl.icon += ' ' + $attrs.icon;
		}
	};
	ajaxButtonCtrl.$inject = ['$scope', '$element', '$q', '$attrs'];

	remarketApp.component('ajaxButton', {
		bindings: {
			message: '@',
			busymessage: '@',
			buttonclass: '@',
			block: '@',
			action: '&',
			styleclass: '@'
		},
		templateUrl: 'component/ajax-button/ajax-button.html',
		controller: ajaxButtonCtrl
	});
}());