 (function () {
	var remarketApp = angular.module('remarketApp');

	var inspectionCheckoutController = function($http, $scope, $stateParams, companyCreationService,
												cfpLoadingBar, inspectionService) {
		var ctrl = this;

		ctrl.minimumBankslipDate = moment().format('YYYY-MM-DD');
		ctrl.maximumBankslipDate = moment().add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');

		ctrl.paymentMethods = [
			{ value: 'CREDIT_CARD', description: 'Cartão de crédito' },
			{ value: 'BANK_SLIP', description: 'Boleto bancário' }
		];

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.inspectionDateLimit = moment().add(3, 'days').format("DD/MM");

			if (!remarketApp.isAdmin()) {
				$http.get('/api/configuration/inspection/prices').then(function(response) {
					ctrl.priceItemsInspection = response.data.priceItemsInspection;
					ctrl.priceDocManagement = response.data.priceDocManagement;
				});
			}

			inspectionService.loadInspection($stateParams.inspectionId, function(inspection) {
				ctrl.inspection = inspection;

				if (ctrl.inspection.inspectionItems == null ||
					ctrl.inspection.inspectionItems.length == 0) {
					remarketApp.error('Antes de realizar o pagamento você precisa informar ' +
						'quais serão os veículos vistoriados.');
					remarketApp.go('landingPage.panel.inspections-items-edit', {
						inspectionId: ctrl.inspection.id
					});
					return;
				}

				ctrl.bankslipCheckout = {
					inspectionId: ctrl.inspection.id,
					docManagement: false,
					paymentMethod: 'BANK_SLIP',
					dueDate: moment().add(3, 'days')
				};

				ctrl.checkout = {
					inspectionId: ctrl.inspection.id,
					docManagement: false,
					paymentMethod: ctrl.paymentMethods[0]
				};
				
				if (inspection.inspectionStatus == 'PENDING_PAYMENT' ||
					inspection.inspectionStatus == 'PENDING_SCHEDULE' ||
					inspection.inspectionStatus == 'PENDING_APPROVAL' ||
					inspection.inspectionStatus == 'SCHEDULED') {
					goToOverview();
				} else {
					goToDetails();
				}

				ctrl.calculateTotalItems();
				ctrl.calculateTotalPrice();

				ctrl.inspectionExempt = null;
				$http.get('/api/company/exempt/' + ctrl.inspection.companyId).then(function(response) {
					ctrl.inspectionExempt = response.data;
				});
			});
		}
		
		function goToDetails() {
			ctrl.step = 'DETAILS';
			ctrl.stepView = 'view/panel/inspection/checkout/inspection-checkout-form.html';
		}
		
		function goToOverview() {
			ctrl.step = 'OVERVIEW';
			ctrl.stepView = 'view/panel/inspection/checkout/inspection-checkout-overview.html';
			ctrl.paymentStatus = getPaymentStatusDescription(ctrl.inspection);
			ctrl.paymentMethod = getPaymentMethodDescription(ctrl.inspection);

			ctrl.inspectionLocation = ctrl.inspection.streetAddress + ', '
				+ ctrl.inspection.streetNumber;

			if (!ctrl.inspection.complement) {
				ctrl.inspectionLocation += ', ' + ctrl.inspection.complement;
			}

			if (!ctrl.inspection.district) {
				ctrl.inspectionLocation += ' - ' + ctrl.inspection.district;
			}

			ctrl.inspectionLocation += ' - ' + ctrl.inspection.cityName
				+ '/' + ctrl.inspection.state;

			ctrl.totalPrice = ctrl.inspection.inspectionCheckout.inspectionItemsPrice + ctrl.inspection.inspectionCheckout.docManagementsPrice;
		}

		function getPaymentMethodDescription(inspection) {
			if (inspection.inspectionCheckout.paymentMethod == 'BANK_SLIP') {
				return 'Boleto bancário';
			}
			if (inspection.inspectionCheckout.paymentMethod == 'CREDIT_CARD') {
				return 'Cartão de crédito';
			}
			if (inspection.inspectionCheckout.paymentMethod == 'INSPECTION_EXEMPTION') {
				return 'Inspeção isenta';
			}
		}

		function getPaymentStatusDescription(inspection) {
			switch (inspection.inspectionCheckout.paymentStatus) {
				case 'PENDING':
					return 'Pendente';
					break;
				case 'PAID':
					return 'Pago';
					break;
				case 'CANCELED':
					return 'Cancelado';
					break;
				case 'PARTIALLY_PAID':
					return 'Pago parcialmente';
					break;
				case 'REFUNDED':
					return 'Reembolsado';
					break;
				case 'EXPIRED':
					return 'Expirado';
					break;
				case 'AUTHORIZED':
					return 'Autorizado';
					break;
			}
		}
		
		ctrl.setPaymentMethod = function(paymentMethod) {
			ctrl.checkout.paymentMethod = paymentMethod;
			if (paymentMethod.value == 'BANK_SLIP') {
				companyCreationService.getCompany(function (company) {
					ctrl.bankslipCheckout.responsible = company.companyName;
					ctrl.bankslipCheckout.cpfCnpj = company.cnpj;
					ctrl.bankslipCheckout.phone = company.phone || company.mobile;
					ctrl.bankslipCheckout.email = remarketApp.getUser().email;
					ctrl.bankslipCheckout.streetAddress = company.streetAddress;
					ctrl.bankslipCheckout.streetNumber = company.streetNumber;
					ctrl.bankslipCheckout.city = company.city;
					ctrl.bankslipCheckout.state = company.state;
					ctrl.bankslipCheckout.zipAddress = company.zipAddress;
					ctrl.fullAddress = ctrl.bankslipCheckout.streetAddress + ', ' +
						ctrl.bankslipCheckout.streetNumber + ' - ' + ctrl.bankslipCheckout.city + '/' +
						ctrl.bankslipCheckout.state + ' - Brasil - ' + ctrl.bankslipCheckout.zipAddress;
				});
			}
		};
		
		ctrl.getNextButtonLabel = function() {
			if (ctrl.step == 'DETAILS') {
				if (ctrl.inspectionExempt && ctrl.calculateTotalPrice() == 0) {
					return 'Próximo';
				} else {
					return 'Forma de Pagamento';
				}
			} else if (ctrl.step == 'OVERVIEW' || ctrl.step == 'PAYMENT') {
				if (remarketApp.isAdmin() && ctrl.inspection.inspectionStatus == 'PENDING_APPROVAL') {
					return "Permitir Agendamento";
				}
				
				if (ctrl.inspectionExempt && !ctrl.checkout.docManagement) {
					return 'Enviar para aprovação';
				}

				if (ctrl.checkout.paymentMethod.value == 'BANK_SLIP') {
					return 'Gerar boleto';
				} else if (ctrl.checkout.paymentMethod.value == 'CREDIT_CARD') {
					return 'Pagar';
				}

				return 'Agendamento';
			}
			
		}

		ctrl.clearPaymentMethod = function() {
			delete ctrl.checkout.paymentMethod;
		};

		ctrl.paymentMethod = function() {
			if (ctrl.inspectionExempt && !ctrl.checkout.docManagement) {
				ctrl.checkout.paymentMethod = {value: 'INSPECTION_EXEMPTION', description: 'Inspeção Isenta'};
				ctrl.inspection.paymentMethod = {value: 'INSPECTION_EXEMPTION', description: 'Inspeção Isenta'};
			} else {
				ctrl.checkout.paymentMethod = ctrl.paymentMethods[0];
				ctrl.inspection.paymentMethod = ctrl.paymentMethods[0];
			}
			
			ctrl.step = 'PAYMENT';
			ctrl.stepView = 'view/panel/inspection/checkout/inspection-checkout-payment.html';
		};

		ctrl.pay = function() {
			if (ctrl.checkout.paymentMethod.value == 'CREDIT_CARD') {
				creditCardCheckout();
			} else if (ctrl.checkout.paymentMethod.value == 'BANK_SLIP') {
				bankSlipCheckout();
			} else if (ctrl.checkout.paymentMethod.value == 'INSPECTION_EXEMPTION') {
				exemptCheckout();
			} 
		};

		ctrl.resetCheckout = function() {
			inspectionService.resetCheckout(function () {
				ctrl.step = 'PAYMENT';
				ctrl.stepView = 'view/panel/inspection/checkout/inspection-checkout-payment.html';

				ctrl.checkout = {
					inspectionId: ctrl.inspection.id,
					docManagement: false,
					paymentMethod: ctrl.paymentMethods[0]
				};
			});
		};
		
		ctrl.goBack = function() {
			if (ctrl.step == 'DETAILS') {
				ctrl.wizardSteps[1].onClick();
			} else if (ctrl.step == 'OVERVIEW') {
				remarketApp.go('landingPage.panel.inspections');
			} else {
				goToDetails();
			}
		};

		ctrl.next = function() {
			if (ctrl.step == 'DETAILS') {
				ctrl.paymentMethod();
			} else if (remarketApp.isAdmin() && ctrl.inspection.inspectionStatus == 'PENDING_APPROVAL') {
				ctrl.continueExemptCheckout();		
			} else if (ctrl.inspectionExempt) {
				ctrl.pay();
			} else {
				ctrl.wizardSteps[3].onClick();
			}
		};
		
		ctrl.calculateTotalPrice = function() {
			ctrl.totalItemsInspection = 0.0;
			ctrl.totalDocManagement = 0.0;

			ctrl.calculateTotalItems();
			
			if (!ctrl.inspectionExempt) {
				ctrl.totalItemsInspection = ctrl.priceItemsInspection * ctrl.totalItemsQuantity;
			}
			
			if (ctrl.checkout.docManagement) {
				ctrl.totalDocManagement = ctrl.priceDocManagement * ctrl.totalItemsQuantity;
			}
			
			return ctrl.totalItemsInspection + ctrl.totalDocManagement;
		}

		ctrl.calculateTotalItems = function() {
			ctrl.totalItemsQuantity = 0;
			for (i = 0; i < ctrl.inspection.inspectionItems.length; i++) {
				ctrl.totalItemsQuantity += ctrl.inspection.inspectionItems[i].plates.length;
			}
		}
		
		ctrl.calculateLinePrice = function(inspectionItem) {
			var inspectionValue = 0.0,
				docManagementValue = 0.0;
		
			if (!ctrl.inspectionExempt) {
				inspectionValue = inspectionItem.plates.length * ctrl.priceItemsInspection;
			}
			
			if (ctrl.checkout.docManagement) {
				docManagementValue = inspectionItem.plates.length * ctrl.priceDocManagement;
			}
			
			return inspectionValue + docManagementValue;
		}

		ctrl.showContinueExemptCheckoutButton = function() {
			return remarketApp.isAdmin() && ctrl.inspection.inspectionStatus == 'PENDING_APPROVAL' && ctrl.inspectionExempt;
		};

		ctrl.showNextButton = function() {
			if (ctrl.inspection && ctrl.inspection.inspectionCheckout && ctrl.inspection.inspectionCheckout.paymentMethod) {
				if (ctrl.inspection.inspectionCheckout.paymentMethod == 'INSPECTION_EXEMPTION' && 
						ctrl.inspection.inspectionCheckout.paymentStatus == 'PENDING' && 
						!remarketApp.isAdmin()) {
					return false;
				}
			}

			return true;
		}
		
		ctrl.continueExemptCheckout = function() {
			$http.post('/api/webapp/inspections/checkouts/exempt/updateStatusToScheduling', ctrl.inspection.id).then(function() {
				remarketApp.go('landingPage.panel.inspections');

				remarketApp.success('Inspeção isenta liberada para agendamento.');
			}, remarketApp.handleError);
		}

		function exemptCheckout() {
			const postCheckout = ctrl.checkout;
			const sendCheckout = {
				    docManagement: postCheckout.docManagement,
					inspectionId: postCheckout.inspectionId,
					paymentMethod: postCheckout.paymentMethod.value
			};
			$http.post('/api/webapp/inspections/checkouts/exempt', sendCheckout).then(function() {
				remarketApp.go('landingPage.panel.inspections');
				remarketApp.success('Entraremos em contato em breve. Aguarde!');
			}, error => { 
				console.log(error);
			}).catch(error => {
				console.log(error);
			});
		}
		
		function bankSlipCheckout() {
			var postCheckout = JSON.parse(JSON.stringify(ctrl.bankslipCheckout));
			postCheckout.paymentMethod = 'BANK_SLIP';
			$http.post('/api/webapp/inspections/checkouts/bank-slip', postCheckout).then(function() {
				remarketApp.go('landingPage.panel.inspections');
				remarketApp.success('O boleto de pagamento foi enviado para o seu e-mail.');
				remarketApp.success('Entraremos em contato assim que nosso sistema identificar o pagamento. Aguarde!');
			}, remarketApp.handleError);
		}

		function creditCardCheckout() {
			var error = false;
			if (!ctrl.checkout.responsible) {
				remarketApp.error("Nome do titular do cartão inválido.");
				error = true;
			}
			if (!ctrl.cardNumber) {
				remarketApp.error("Número do cartão inválido. Formato esperado: 9999 9999 9999 9999.");
				error = true;
			}
			if (!ctrl.expirationDate) {
				remarketApp.error("Data de expiração do cartão inválida. Formato esperado: mm/aa.");
				error = true;
			}
			if (!ctrl.cvc) {
				remarketApp.error("Código de verificação do cartão (CVV) inválido. Formato esperado: 999.");
				error = true;
			}
			if (error) {
				return;
			}
			var expirationMonth = ctrl.expirationDate.substring(0, 2);
			var expirationYear = "20" + ctrl.expirationDate.substring(2, 4);
			var firstName = ctrl.checkout.responsible.substr(0, ctrl.checkout.responsible.indexOf(' '));
			var surname = ctrl.checkout.responsible.substr(ctrl.checkout.responsible.indexOf(' ') + 1);
			var creditCard = Iugu.CreditCard(ctrl.cardNumber, expirationMonth, expirationYear, firstName, surname, ctrl.cvc);
			cfpLoadingBar.start();
			
			Iugu.createPaymentToken(creditCard, function(data) {
				if (data.errors) {
					if (data.errors.first_name || data.errors.last_name) {
						remarketApp.error("Nome do titular do cartão inválido.");
					}
					if (data.errors.number) {
						remarketApp.error("Número do cartão inválido. Formato esperado: 9999 9999 9999 9999.");
					}
					if (data.errors.expiration) {
						remarketApp.error("Data de expiração do cartão inválida. Formato esperado: mm/aa.");
					}
					if (data.errors.verification_value) {
						remarketApp.error("Código de verificação do cartão (CVV) inválido. Formato esperado: 999.");
					}
					if (typeof data.errors == 'string') {
						remarketApp.error("Ocorreu um erro no seu pagamento. Entre em contato com o MotorMarket.");
					}
				} else {
					var creditCardCheckout = JSON.parse(JSON.stringify(ctrl.checkout));
					creditCardCheckout.token = data.id;
					
					$http.post('/api/webapp/inspections/checkouts/credit-card', creditCardCheckout).then(function() {
						remarketApp.success('Pagamento realizado com sucesso.');
						remarketApp.go('landingPage.panel.inspectionScheduling', {
							inspectionId: creditCardCheckout.inspectionId
						});
					}, function (response) {
						remarketApp.handleError(response);
					});
				}
				cfpLoadingBar.complete();
			});
		}

		ctrl.wizardSteps = inspectionService.wizardSteps;

		$scope.$on('remarket-login', init);

		init();
	};
	
	inspectionCheckoutController.$inject = ['$http', '$scope', '$stateParams', 'companyCreationService',
		'cfpLoadingBar', 'inspectionService'];
	
	remarketApp.controller('inspectionCheckoutController', inspectionCheckoutController);

	var inspectionCheckoutStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspectionCheckout', {
			url: '/inspections/checkout/:inspectionId',
			templateUrl: 'view/panel/inspection/checkout/inspection-checkout.html',
			controller: inspectionCheckoutController,
			controllerAs: 'inspectionCheckoutController'
		});
	};
	
	inspectionCheckoutStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionCheckoutStateProvider);
}());
