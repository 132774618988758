(function() {
	var remarketApp = angular.module('remarketApp');

	remarketApp.filter('auctionType', function () {
		return function (input) {
			switch (input) {
				case 'OPEN':
					return 'Aberto';
					break;
				case 'INTERNAL':
					return 'Interno';
					break;
				case 'CLIENT_INSTANCE':
					return 'Espelhamento';
					break;
			}
		};
	});
}());