(function() {
	var remarketApp = angular.module('remarketApp');
	var bidsController = function($http, $scope, $stateParams) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			ctrl.showVehicles = false;
			
			$http.get('/api/auctions/items/admin/' + $stateParams.auctionItemId).then(function(response) {
				ctrl.auctionItem = response.data;
			}, remarketApp.handleError);
			
			ctrl.bids = null;
			$http.get('/api/bids/item/' + $stateParams.auctionItemId).then(function(response) {
				ctrl.bids = response.data;
			}, remarketApp.handleError);
		};

		ctrl.showNoBidsLine = function() {
			return !ctrl.bids || ctrl.bids.length == 0;
		}
		
		ctrl.goBack = function() {
			return remarketApp.go('landingPage.panel.negotiation-details', {
				auctionId: ctrl.auctionItem.auction.id
			});
		};
		
		ctrl.toggleVehicles = function() {
			ctrl.showVehicles = !ctrl.showVehicles;
		};
		
		ctrl.canSell = function() {
			return ctrl.auctionItem && ctrl.auctionItem.status == 'NOT_SOLD';
		};
		
		ctrl.sell = function(bid) {
			var sellRequest = {
				"auctionItemId": ctrl.auctionItem.id,
				"bidId": bid.id
			};
			$http.post('/api/auctions/items/admin/sell/', sellRequest).then(function(response) {
				ctrl.auctionItem = response.data;
				remarketApp.success('O veículo foi vendido com sucesso.');
			}, remarketApp.handleError);
		};
		
		$scope.$on('remarket-login', init);

		init();
	};
	bidsController.$inject = ['$http', '$scope', '$stateParams'];
	remarketApp.controller('bidsController', bidsController);

	var bidsStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.bids', {
				url: '/bids/item/:auctionItemId',
				templateUrl: 'view/panel/bid/bids.html',
				controller: bidsController,
				controllerAs: 'bidsController'
			});
	};
	bidsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(bidsStateProvider);
}());
