(function() {
	var remarketApp = angular.module('remarketApp');

	var internalUserRegisterController = function($http, $stateParams) {
		var ctrl = this;
		ctrl.user = {};
		
		ctrl.uuid = $stateParams.uuid;

		function validateUser() {
			var ok = true;
			if (!ctrl.user.name || ctrl.user.name.length < 5) {
				remarketApp.error('O nome deve conter ao menos 5 caracateres.');
				ok = false;
			}
			if (!ctrl.user.cpf || ctrl.user.cpf.length != 11) {
				remarketApp.error('O cpf deve ser válido e estar no formato 000.000.000-00.');
				ok = false;
			}
			if (!ctrl.user.email || ctrl.user.email.length < 5) {
				remarketApp.error('O e-mail deve estar no formato exemplo@exemplo.com.');
				ok = false;
			}
			if (!ctrl.user.password || !ctrl.user.password) {
				return remarketApp.warning('Preencha a nova senha e a confirmação de nova senha.');
			}
			if (ctrl.user.password != ctrl.user.passwordConfirmation) {
				return remarketApp.warning('A confirmação de nova senha não coincide com a nova senha.');
			}
			
			return ok;
		}

		ctrl.register = function() {
			if (!validateUser()) {
				return;
			}
			$http.post('/api/webapp/users-internal/registration', 
					{
						"uuid": ctrl.uuid,
						"cpf": ctrl.user.cpf,
						"name": ctrl.user.name,
						"email": ctrl.user.email,
						"password": ctrl.user.password,
						"passwordConfirmation": ctrl.user.passwordConfirmation
					}
			).then(function() {
				remarketApp.success('Você acaba de criar sua conta na MotorMarket. Por favor, faça o login com o e-mail e senha cadastrados.');
				remarketApp.go('landingPage.login');
			}, remarketApp.handleError);
		}
	};
	internalUserRegisterController.$inject = ['$http', '$stateParams'];
	remarketApp.controller('internalUserRegisterController', internalUserRegisterController);

	var registerStateProvider = function ($stateProvider) {
		$stateProvider.state('landingPage.internal-user', {
			url: '/internal-user/:uuid',
			templateUrl: 'view/public/internal-user/internal-user.html',
			controller: internalUserRegisterController,
			controllerAs: 'internalUserRegisterController'
		});
	};
	registerStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(registerStateProvider);
}());