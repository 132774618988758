(function() {
	var remarketApp = angular.module('remarketApp');
	
	var submenuItemCtrl = function($attrs) {
		var ctrl = this;
		
		ctrl.uiSref = $attrs.uiSref;
		ctrl.label = $attrs.label;
	}
	submenuItemCtrl.$inject = ['$attrs'];
	
	remarketApp.component('submenuItem', {
		bindings: {
			uiSref: '@',
			label: '@'
		},
		templateUrl: 'component/submenu-item/submenu-item.html',
		controller: submenuItemCtrl
	});
}());