(function () {
	var remarketApp = angular.module('remarketApp');

	var inspectionSearchController = function($http) {
		var ctrl = this;

		ctrl.vehicles = [];
		ctrl.findVehicles = findVehicles;
		ctrl.closeVehicleDetails = closeDetails;

		function findVehicles() {
			if (!ctrl.plate || ctrl.plate.trim() == 0) {
				return;
			}
			$http.get('/api/vehicles/?plate=' + ctrl.plate).then(function(response) {
				ctrl.vehicles = response.data;
			}, remarketApp.handleError);
		}

		function closeDetails() {
			ctrl.vehicleId = null;
		}
	};
	inspectionSearchController.$inject = ['$http'];
	remarketApp.controller('inspectionSearchController', inspectionSearchController);

	var inspectionSearchStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspection-search', {
			url: '/inspection-search',
			templateUrl: 'view/panel/inspection/search/inspection-search.html',
			controller: inspectionSearchController,
			controllerAs: 'inspectionSearchController'
		});
	};
	inspectionSearchStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionSearchStateProvider);
}());
