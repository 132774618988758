(function() {
	var remarketApp = angular.module('remarketApp');

	var wizardCtrl = function($scope, $element, $q, $attrs) {
		var ctrl = this;

		var afterActive = false;
		//TODO: REMOVE
		if (ctrl.steps) {
			for (var i = 0; i < ctrl.steps.length; i++) {
				delete ctrl.steps[i].afterActive;
				delete ctrl.steps[i].active;
				if (ctrl.steps[i].value == $attrs.active) {
					ctrl.steps[i].active = 'active';
					afterActive = true;
					continue;
				}
				if (afterActive) {
					afterActive = false;
					ctrl.steps[i].afterActive = 'after-active';
				}
			}
		}
	};
	wizardCtrl.$inject = ['$scope', '$element', '$q', '$attrs'];

	remarketApp.component('wizard', {
		bindings: {
			steps: '<'
		},
		templateUrl: 'component/wizard/wizard.html',
		controller: wizardCtrl
	});
}());