(function() {
	var remarketApp = angular.module('remarketApp');

	var companyCreationService = function($http, $rootScope, Upload) {
		var service = this;

		function validate(company, socialContractFile) {
			var valid = true;
			if (!company.cnpj || company.cnpj.length != 14) {
				remarketApp.error('O CNPJ deve ser válido e estar no formato 00.000.000/0000-00.');
				valid = false;
			}
			if (!socialContractFile && !company.socialContractFile) {
				remarketApp.error('Você deve enviar, digitalizado, o último contrato social de sua empresa.');
				valid = false;
			}
			if (!company.companyName || company.companyName.length < 5) {
				remarketApp.error('Você deve preencher a razão social da empresa com pelo menos 5 caracteres.');
				valid = false;
			}
			if (!company.tradingName || company.tradingName.length < 5) {
				remarketApp.error('Você deve preencher o nome fantasia da empresa com pelo menos 5 caracteres.');
				valid = false;
			}
			// no company type means STORE, which has to select at least one interest
			if (!company.companyType && (!company.interests || company.interests.length == 0)) {
				remarketApp.error('Você deve escolher ao menos um segmento de interesse.');
				valid = false;
			}
			if (company.companyType == 'DEALER' && (!company.brands || company.brands.length == 0)) {
				remarketApp.error('Você deve informar a marca principal com a qual trabalha.');
				valid = false;
			}
			if (company.companyType == 'FLEET_OWNER' && !company.businessSegment) {
				remarketApp.error('Você deve informar o segmento de atuação da empresa.');
				valid = false;
			}
			return valid;
		}

		service.saveCompany = function(company) {
			var postCompany = JSON.parse(JSON.stringify(company));
			$http.post('/api/company/', postCompany).then(function() {
				remarketApp.success('Dados da empresa salvos com sucesso.');
			}, remarketApp.handleError);
		};

		service.finalizeDataStep = function(company, socialContractFile) {
			if (!validate(company, socialContractFile)) {
				return;
			}

			Upload.upload({
				url: '/api/company/contract/upload',
				method: 'POST',
				fields: {
					"Content-Type": socialContractFile.type != '' ? socialContractFile.type : 'application/octet-stream',
				},
				data: {
					'contract': socialContractFile
				}
			}).then(function(response) {
					service.company = response.data;
					service.company.cnpj = company.cnpj;
					service.company.companyName = company.companyName;
					service.company.tradingName = company.tradingName;
					service.company.interests = company.interests;
					service.company.brands = company.brands;
					service.company.businessSegment = company.businessSegment;

					remarketApp.go('landingPage.companyAddress');
				}, function () {
					remarketApp.error('Ocorreu um erro ao tentar enviar o contrato social.');
			});
		};

		service.finalizeAddressStep = function (company) {
			service.company = company;
			
			$http.post('/api/company/', service.company).then(function(response) {
				service.company = response.data;
				remarketApp.getUser().askCompanyData = false;
				remarketApp.success('Em até 48 horas iremos validar sua conta e lhe informar o resultado por e-mail. ' +
					'Por favor, aguarde.');
				remarketApp.logout();
			}, remarketApp.handleError);
		};

		service.getCompany = function(callback, companyId) {
			if (companyId) {
				return loadCompany(companyId, callback);
			}
			if (service.company) {
				return callback(service.company);
			}
			$http.get('/api/company/my-company').then(function (response) {
				service.company = response.data;
				callback(service.company);
			}, remarketApp.handleError);
		};

		service.activateCompany = function(company) {
			$http.post('/api/company/admin/activate/' + company.id).then(function() {
				remarketApp.success('A empresa foi ativada com sucesso.');
				company.accountBeingVerified = false;
				company.active = true;
			}, remarketApp.handleError);
		};

		service.deactivateCompany = function(company) {
			$http.post('/api/company/admin/deactivate/' + company.id).then(function() {
				remarketApp.success('A empresa foi desativada com sucesso.');
				company.active = false;
			}, remarketApp.handleError);
		};

		function loadCompany(companyId, callback) {
			$http.get('/api/company/admin/' + companyId).then(function(response) {
				callback(response.data);
			}, remarketApp.handleError);
		}

		$rootScope.$on('remarket-logout', function() {
			delete service.company;
		});
	};
	companyCreationService.$inject = ['$http', '$rootScope', 'Upload'];
	remarketApp.service('companyCreationService', companyCreationService);
}());