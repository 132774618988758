(function() {
	var remarketApp = angular.module('remarketApp');

	var individualAddressController = function(individualCreationService, $http) {
		var ctrl = this;
		ctrl.individual = {};
		
		function init() {
			individualCreationService.getIndividual(function (individual) {
				ctrl.individual = individual;
			});
		}

		ctrl.fulfillAddress = function() {
			if (!ctrl.individual.zipAddress || ctrl.individual.zipAddress.length != 8) {
				return remarketApp.info('O CEP deve ser válido e estar no formato 00000-000.');
			}

			$http.get('/api/address/' + ctrl.individual.zipAddress).then(function (response) {
				ctrl.cityState = response.data.city.name + ' - ' + response.data.city.state.name;
				ctrl.individual.streetAddress = response.data.street;
				ctrl.individual.district = response.data.district;
				ctrl.individual.cityId = response.data.city.id;
				remarketApp.info('Endereço carregado com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.save = function() {
			individualCreationService.finalizeAddressStep(ctrl.individual);
		};

		ctrl.back = function() {
			remarketApp.go('landingPage.individualData');
		};

		init();
	};
	individualAddressController.$inject = ['individualCreationService', '$http'];	remarketApp.controller('individualAddressController', individualAddressController);

	var individualAddressStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.individualAddress', {
			url:  '/individual-address',
			templateUrl: 'view/public/individual/individual-address.html',
			controller: individualAddressController,
			controllerAs: 'individualAddressController',
			onEnter: individualAddressController.init
		});
	};
	individualAddressStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(individualAddressStateProvider);
}());