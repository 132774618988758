(function() {
	var remarketApp = angular.module('remarketApp');

	var internalAuctionItemCtrl = function($scope, $http, auctionItemInterestsService) {
		var ctrl = this;
		ctrl.minimumBidDifference = null;
		
		$http.get('/api/configuration/auction').then(function(response) {
				ctrl.minimumBidDifference = response.data.minimumBidDifference;
				updateUI();
		}, remarketApp.handleError);

		var init = function () {
			ctrl.pictures = ctrl.item.vehicle.pictures;
			if (ctrl.pictures) {
				ctrl.currentPicture = ctrl.pictures[0];
			}

			updateUserOwnsHighestBid();
					
			updateUI();

			$scope.$watch(function() { return ctrl.item.highestBid }, function() {
				updateUI();
				
				if (!ctrl.item.vehicles) {
					ctrl.closeVehicleDetails();
				}
			});
			
			$scope.$watch(function() { return ctrl.item.highestConditionalBid }, function() {
				updateUI();
				
				if (!ctrl.item.vehicles) {
					ctrl.closeVehicleDetails();
				}
			});

			$scope.$watch(function() { return ctrl.item.programmedBids }, function() {
				ctrl.programmedBidConfigured = false;
				if (ctrl.item.programmedBids && ctrl.item.programmedBids.length >= 1) {
					var programmedBid = _.find(ctrl.item.programmedBids, function(programmedBid) {
						return programmedBid.userId == remarketApp.getUser().id;
					});
					ctrl.programmedBidConfigured = programmedBid != null;
					if (ctrl.programmedBidConfigured) {
						ctrl.programmedBid = programmedBid.maxBid;
					} else {
						ctrl.programmedBid = null;
						updateProgrammedBidValue();
					}
				}
				
				updateUI();
			});
		};
		
		var updateUI = function() {
			updateHasBids();
			updateBidsValues();
			updateProgrammedBidValue();
			updateUserOwnsHighestBid();
			updateUserOwnsHighestConditionalBid();
			updateTooltipButtonConditionalBid();
		}
		
		var updateBidsValues = function() {
			if (ctrl.item.highestBid) {
				ctrl.bidValue = ctrl.item.minimumPrice + ctrl.minimumBidDifference;
			} else {
				ctrl.bidValue = ctrl.item.minimumPrice;
			}
			
//			if (ctrl.item.conditionalPrice) {
//				if (ctrl.item.highestConditionalBid) {
//					ctrl.conditionalBidValue = ctrl.item.conditionalPrice + ctrl.minimumBidDifference;
//				} else {
//					ctrl.conditionalBidValue = ctrl.item.conditionalPrice;
//				}
//			}
		}

		var updateProgrammedBidValue = function() {
			if (!ctrl.programmedBid || ctrl.programmedBid <= ctrl.item.minimumPrice + ctrl.minimumBidDifference) {
				ctrl.programmedBid = ctrl.item.minimumPrice+ ctrl.minimumBidDifference;
			}
		};

		var updateUserOwnsHighestBid = function () {
			ctrl.userOwnsHighestBid = ctrl.item.highestBid && ctrl.item.highestBid.userId == remarketApp.getUser().id;
		};
		
		var updateHasBids = function() {
			ctrl.hasBids = ctrl.item && (ctrl.item.highestBid != null);
		};
		
		var updateUserOwnsHighestConditionalBid = function () {
			ctrl.userOwnsHighestConditionalBid = ctrl.item.highestConditionalBid && ctrl.item.highestConditionalBid.userId == remarketApp.getUser().id;
		};
		
		ctrl.disableConditionalPriceField = function() {
			return !ctrl.item.conditionalPrice ||
					(ctrl.item.conditionalPrice+ ctrl.minimumBidDifference >= ctrl.item.minimumPrice) || 
					ctrl.userOwnsHighestConditionalBid || 
					ctrl.hasBids;
		}
	
		var updateTooltipButtonConditionalBid = function () {
			if (ctrl.item.sold) {
				ctrl.tooltipButtonConditionalBid = 'Este veículo foi vendido.';
			}
			
			if (ctrl.hasBids) {
				ctrl.tooltipButtonConditionalBid = 'Este veículo já tem ofertas. Participe da negociação.';
			}
			
			if (ctrl.userOwnsHighestConditionalBid) {
				ctrl.tooltipButtonConditionalBid = 'A última oferta é sua.';
			}
		}
		
		ctrl.sendNextConditionalBid = function() {
			if (ctrl.item.sold || ctrl.hasBids || ctrl.userOwnsHighestConditionalBid) {
				remarketApp.error('Este item não pode receber mais ofertas.');
				return;
			}
			
			if (ctrl.item.highestConditionalBid && ctrl.item.highestConditionalBid.price >= ctrl.conditionalBidValue) {
				remarketApp.error('A oferta não cobre o valor atual para ofertas condicionais.');
				return;
			}
			
			if (ctrl.conditionalBidValue >= ctrl.item.minimumPrice || !ctrl.conditionalBidValue) {
				remarketApp.error('A oferta condicional ultrapassou o valor das ofertas normais do veículo. ' +
								  'Por favor, utilize o campo "Próxima Oferta" e participe da negociação.');
				return;
			}
			
			if (ctrl.item.highestConditionalBid && (ctrl.conditionalBidValue - ctrl.item.highestConditionalBid.price) < ctrl.minimumBidDifference) {
				remarketApp.error('As ofertas devem ter uma diferença de R$ ' + ctrl.minimumBidDifference + ' entre uma e outra. ' + 
								  'Você poderia oferecer R$' + (ctrl.item.highestConditionalBid.price+ ctrl.minimumBidDifference) + '.');
				return;
			}
			
			ctrl.item.conditionalPrice = ctrl.conditionalBidValue;
			
			ctrl.item.highestConditionalBid = {
				userId: remarketApp.getUser().id,
				price: ctrl.item.conditionalPrice
			};

			ctrl.item.$save();
			auctionItemInterestsService.addInterest(ctrl.item);
			updateUI();
		};

		ctrl.buyNow = function() {
			if (ctrl.item.sold) {
				return;
			}

			ctrl.item.minimumPrice = ctrl.item.buyNowPrice;
			ctrl.item.highestBid = {
				userId: remarketApp.getUser().id,
				price: ctrl.item.buyNowPrice
			};
			
			ctrl.item.sold = true;
			ctrl.item.$save();
		};

		ctrl.openVehicleDetails = function() {
			document.body.style.overflow = 'hidden';
			
			if (ctrl.isGetekInspection()) {
				ctrl.vehicleDetailsId = ctrl.item.vehicle.id;
			} else {
				ctrl.vehicleMobileDetailsId = ctrl.item.vehicle.externalInspectionId;
			}
		};

		ctrl.isGetekInspection = function() {
			return ctrl.item.vehicle.origin == 'GETEK';
		}

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
		};

		ctrl.goToPicture = function(index) {
			ctrl.currentPicture = ctrl.pictures[index];
		};
		
		ctrl.sendNextBid = function() {
			if (ctrl.item.sold || (ctrl.item.highestBid && ctrl.item.highestBid.userId == remarketApp.getUser().id) || !ctrl.bidValue) {
				return;
			}
			
			if (ctrl.bidValue < ctrl.item.minimumPrice) {
				remarketApp.error('A oferta não cobre a oferta atual.');
				return;
			}

			if (ctrl.bidValue >= ctrl.item.buyNowPrice) {
				ctrl.buyNow();
				return;
			}
			
			ctrl.item.minimumPrice = ctrl.bidValue;
			
			ctrl.item.highestBid = {
				userId: remarketApp.getUser().id,
				price: ctrl.item.minimumPrice
			};
			updateProgrammedBidValue();
			ctrl.item.$save();
			updateUserOwnsHighestBid();
			auctionItemInterestsService.addInterest(ctrl.item);
		};
		
		ctrl.addToProgrammedBid = function() {
			if (ctrl.item.sold) {
				return;
			}
			ctrl.programmedBid = ctrl.programmedBid + ctrl.minimumBidDifference;
		};

		ctrl.subtractFromProgrammedBid = function() {
			if (ctrl.item.sold) {
				return;
			}
			ctrl.programmedBid = ctrl.programmedBid - ctrl.minimumBidDifference;
			if (ctrl.programmedBid < ctrl.item.minimumPrice + ctrl.minimumBidDifference) {
				ctrl.programmedBid = ctrl.item.minimumPrice + ctrl.minimumBidDifference;
			}
		};

		ctrl.programBid = function() {
			if (ctrl.item.sold) {
				return;
			}
			if (!ctrl.item.programmedBids) {
				ctrl.item.programmedBids = [];
			}

			var sameBid = _.find(ctrl.item.programmedBids, function(programmedBid) {
				return programmedBid.maxBid == ctrl.programmedBid;
			}) != null;

			if (sameBid) {
				return remarketApp.error('O valor informado já foi configurado por outro usuário. Por favor, ' +
					'escolha um outro valor.');
			}

			ctrl.item.programmedBids.push({
				userId: remarketApp.getUser().id,
				maxBid: ctrl.programmedBid
			});
			ctrl.item.$save();
			remarketApp.success('Oferta programada configurada com sucesso.');
			ctrl.sendNextBid();
		};

		ctrl.removeProgrammedBid = function() {
			if (!ctrl.item.programmedBids) {
				return;
			}
			ctrl.item.programmedBids = _.reject(ctrl.item.programmedBids, function(programmedBid) {
				return programmedBid.userId == remarketApp.getUser().id;
			});
			ctrl.item.$save();
			remarketApp.success('Oferta programada removida com sucesso.');
		};

		ctrl.percentageBelowFipePrice = function() {
			var percentage = (1 - ctrl.item.minimumPrice / ctrl.item.fipePrice) * 100;
			if (percentage < 0) {
				return percentage * -1;
			}

			return percentage;
		}

		var getThumbnails = function(item) {
			var thumbnails = [];
			for (i = 0; i < item.pictures.length; i++) {
				if (item.pictures[i].thumbnailURL) {
					thumbnails.push(item.pictures[i].thumbnailURL);
				} else {
					thumbnails.push(item.pictures[i].url);
				}
			}

			return thumbnails;
		}

		ctrl.showThumbnail = function() {
			if (!ctrl.currentPicture) {
				return;
			}

			if (!ctrl.currentPicture.thumbnailURL) {
				return ctrl.currentPicture.url;
			} else {
				return ctrl.currentPicture.thumbnailURL;
			}
		}
		
		init();
	};
	internalAuctionItemCtrl.$inject = ['$scope', '$http', 'auctionItemInterestsService'];

	remarketApp.component('internalAuctionItem', {
		bindings: {
			item: '<'
		},
		templateUrl: 'component/internal-auction-item/internal-auction-item.html',
		controller: internalAuctionItemCtrl
	});
}());
