(function() {
	var remarketApp = angular.module('remarketApp');

	var fileExplorerController = function($scope) {
		var component = this;
		component.hidden = true;
		component.files = [
			{ filename: 'Linux', date: new Date(), url: 'http://e.cdn-hardware.com.br/static/00000000/img-582c9442.png.optimized.jpg' },
			{ filename: 'Lâmpada', date: new Date(), url: 'http://1.bp.blogspot.com/_kEE6w7wR6QE/THRk59zrxvI/AAAAAAAAAF4/Nyg3aWrAX1o/s1600/icone.png' },
			{ filename: 'Agenda', date: new Date(), url: 'http://rowe.com.br/portfolio/wp-content/gallery/icones/icones_44.jpg' }
		];

		component.toggle = function() {
			component.hidden = !component.hidden;
		};

		component.addImage = function() {
			$scope.$broadcast('execCommand', {
				command: 'insertHTML',
				options: '<img src="http://e.cdn-hardware.com.br/static/00000000/img-582c9442.png.optimized.jpg" />'
			});
		};
	};
	fileExplorerController.$inject = ['$scope'];

	remarketApp.component('fileExplorer', {
		templateUrl: 'component/file-explorer/file-explorer.html',
		controller: fileExplorerController
	});
}());