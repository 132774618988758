(function() {
	var remarketApp = angular.module('remarketApp');

	var inputDateCtrl = function($scope, $timeout) {
		var ctrl = this;

		if (ctrl.object[ctrl.property]) {
			ctrl.model = moment(ctrl.object[ctrl.property]).format('DD/MM/YYYY');
		}
		
		ctrl.isFunction = function (object) {
			return typeof(object) == 'function';
		}

		$scope.$watch(function() {
				return ctrl.object[ctrl.property];
			}, function() {
			if (ctrl.object[ctrl.property]) {
				ctrl.model = moment(ctrl.object[ctrl.property]).format('DD/MM/YYYY');
			} else {
				ctrl.model = null;
			}
		});

		$scope.$watch(function() {
			if (ctrl.isFunction(ctrl.minDate)) {
				return ctrl.minDate.call();
			} else {
				return ctrl.minDate;
			}
		}, function() {
			$timeout(function() {
				if (ctrl.picker && ctrl.minDate) {
					if (ctrl.isFunction(ctrl.minDate)) {
						ctrl.picker.setMinDate(moment(ctrl.minDate.call()).toDate());
					} else {
						ctrl.picker.setMinDate(moment(ctrl.minDate).toDate());
					}
				}
			});
		});
		
		ctrl.logThis = function(objectToLog) {
			return objectToLog ? moment(objectToLog).format("DD-MM-YYYY HH:mm:ss:SSS") : null;
		}

		$scope.$watch(function() {
			if (ctrl.isFunction(ctrl.maxDate)) {
				return ctrl.maxDate.call();
			} else {
				return ctrl.maxDate;
			}
		}, function() {
			$timeout(function() {
				if (ctrl.picker && ctrl.maxDate) {
					if (ctrl.isFunction(ctrl.maxDate)) {
						ctrl.picker.setMaxDate(moment(ctrl.maxDate.call()).toDate());
					} else {
						ctrl.picker.setMaxDate(moment(ctrl.maxDate).toDate());
					}
				}
			});
		});
		
		$scope.$watch(function() {
			return ctrl.disableWeekends;
		}, function() {
			$timeout(function() {
				if (ctrl.disableWeekends) {
					ctrl.picker.config().disableWeekends = ctrl.disableWeekends;
				}
			});
		});
		
		ctrl.onPikadaySelect = function onPikadaySelect(pikaday) {
			ctrl.object[ctrl.property] = moment(pikaday.toString()).format('YYYY-MM-DD');
			
			if (ctrl.change) {
				ctrl.change.call();
			}
		};
	};
	inputDateCtrl.$inject = ['$scope', '$timeout'];

	remarketApp.component('inputDate', {
		bindings: {
			object: '<',
			property: '@',
			inputClass: '@',
			placeholder: '@',
			minDate: '<',
			maxDate: '<',
			disableWeekends: '<',
			change: '<'
		},
		templateUrl: 'component/input-date/input-date.html',
		controller: inputDateCtrl
	});
}());