(function() {
	var remarketApp = angular.module('remarketApp');

	var companyDataController = function(companyCreationService, $scope, fipeService) {
		var ctrl = this;
		ctrl.company = {};

		ctrl.segments = [
			{ value: 'AUTOMOBILE', description: 'Automóveis' },
			{ value: 'TRUCK', description: 'Caminhões' },
			{ value: 'HEAVY_MACHINERY', description: 'Máquinas pesadas' }
		];

		ctrl.save = function() {
			companyCreationService.finalizeDataStep(ctrl.company, ctrl.socialContractFile);
		};

		function init() {
			companyCreationService.getCompany(function(company) {
				var companyType = ctrl.company.companyType;
				ctrl.company = company || { companyType: companyType };
				delete ctrl.company.interests;
			});
			
			if (remarketApp.getUser()) {
				ctrl.company.companyType = remarketApp.getUser().companyType;
			}
			
			ctrl.availableBrands = fipeService.getBrands();
			if (ctrl.availableBrands == null) {
				$scope.$on('remarket-brands-loaded', function(event, brands){
					ctrl.availableBrands = brands;
				});
			}
		}

		$scope.$on('remarket-login', function(event, user){
			ctrl.company.companyType = user.companyType;
		});

		ctrl.setSelectedSegments = function (interests) {
			delete ctrl.company.interests;
			ctrl.company.interests = [];
			for (var i = 0; i < interests.length; i++) {
				ctrl.company.interests.push(interests[i].value);
			}
		};
		
		ctrl.setSelectedBrands = function (brands) {
			delete ctrl.company.brands;
			ctrl.company.brands = [];
			for (var i = 0; i < brands.length; i++) {
				ctrl.company.brands.push(brands[i].id);
			}
		};

		init();
	};
	
	companyDataController.$inject = ['companyCreationService', '$scope', 'fipeService'];
	remarketApp.controller('companyDataController', companyDataController);

	var companyDataStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.companyData', {
			url:  '/company-data',
			templateUrl: 'view/public/company/company-data.html',
			controller: companyDataController,
			controllerAs: 'companyDataController'
		});
	};
	
	companyDataStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(companyDataStateProvider);
}());