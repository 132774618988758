(function() {
	var remarketApp = angular.module('remarketApp');
	
	var fipeService = function($http, $rootScope) {
		var service = this;
		
		service.getBrands = function() {
			if (service.brands) {
				return service.brands;
			}
			
			$http.get('/api/fipe/brands/CAR').then(function(response) {
				service.brands = response.data;
				$rootScope.$broadcast('remarket-brands-loaded', service.brands);
			}, function (err) {
				console.log(err);
				return remarketApp.error('Não foi possível carregar a lista de marcas.');
			});
		};

		service.getBrandsCB = function(callback) {
			if (service.brands != null) {
				return callback(service.brands);
			}
			$http.get('/api/fipe/brands/CAR').then(function (response) {
				service.brands = response.data;
				callback(service.brands);
			}, function () {
				console.log(err);
				return remarketApp.error('Não foi possível carregar a lista de marcas.');
			});
		};

		service.getModels = function(brandId, callback) {
			$http.get('/api/fipe/brands/' + brandId + '/models/').then(function (response) {
				callback(response.data);
			}, function () {
				return remarketApp.error('Não foi possível carregar a lista de modelos.');
			});
		};

		service.getVersions = function(modelId, callback) {
			$http.get('/api/fipe/models/' + modelId + '/versions/').then(function (response) {
				callback(response.data);
			}, function () {
				return remarketApp.error('Não foi possível carregar a lista de versões.');
			});
		};
	};
	
	fipeService.$inject = ['$http', '$rootScope'];
	remarketApp.service('fipeService', fipeService);
}());
