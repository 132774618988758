(function () {
	var remarketApp = angular.module('remarketApp');

	var inspectionItemsCtrl = function($scope, $stateParams,
										fipeService, $timeout, inspectionService) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			fipeService.getBrandsCB(function(brands) {
				ctrl.availableBrands = brands;
				ctrl.brandsLoaded = true;
			});

			if ($stateParams.inspectionId) {
				inspectionService.loadInspection($stateParams.inspectionId, function(inspection) {
					ctrl.inspection = inspection;
				});
			} else {
				ctrl.modelsLoaded = false;
				ctrl.inspection = {
					inspectionItems: [],
					inspectionStatus: 'NEW'
				};
			}
		}

		ctrl.loadModels = function(option) {
			ctrl.unselectModel();
			ctrl.selectedBrand = option;
			fipeService.getModels(ctrl.selectedBrand.id, function(models) {
				ctrl.availableModels = models;
				ctrl.modelsLoaded = true;
			});
		};

		ctrl.loadVersions = function(option) {
			ctrl.unselectVersion();
			ctrl.selectedModel = option;
			fipeService.getVersions(ctrl.selectedModel.id, function(versions) {
				ctrl.availableVersions = versions;

				for (i = 0; i < ctrl.availableVersions.length; i++) {
					version  = ctrl.availableVersions[i];
					version.name = version.year + ' ' + version.fuelType;
				}

				ctrl.versionsLoaded = true;
			});
		};

		ctrl.unselectModel = function() {
			ctrl.selectedModel = null;
			ctrl.availableModels = null;
			ctrl.modelsLoaded = false;
			ctrl.unselectVersion();
		};

		ctrl.selectVersion = function(option) {
			ctrl.vehicle = option;
		};

		ctrl.unselectVersion = function() {
			ctrl.vehicle = null;
			ctrl.availableVersions = null;
			ctrl.versionsLoaded = false;
		};

		ctrl.addItem = function() {
			if (ctrl.vehicle == null) {
				remarketApp.error('Por favor, selecione a marca, modelo e versão dos veículos que serão vistoriados.');
				return;
			}
			ctrl.inspection.inspectionItems.push({
				versionId: ctrl.vehicle.id,
				versionName: ctrl.selectedBrand.name + ' ' + ctrl.selectedModel.name + ' ' + ctrl.vehicle.name,
				quantity: 0
			});
			ctrl.unselectModel();
			ctrl.selectedBrand = null;
			delete ctrl.quantity;
		};

		ctrl.removeItem = function($index) {
			ctrl.inspection.inspectionItems.splice($index, 1);
		};

		ctrl.editPlates = function(item) {
			if (item.plates) {
				item.platesBeingEdited = item.plates.join(', ');
			} else {
				item.platesBeingEdited = '';
			}
			item.editingPlates = true;
		};

		ctrl.itemsPasted = function(item) {
			$timeout(function() {
				var result = formatPlates(item.platesBeingEdited);
				if (!result.valid) {
					remarketApp.error('Existem uma ou mais placas inválidas, por favor, verifique as placas.');
				}
				item.platesBeingEdited = result.plates.join(', ');
			});
		};
		
		ctrl.savePlates = function(item) {
			var result = formatPlates(item.platesBeingEdited);
			if (!result.valid) {
				remarketApp.error('Existem uma ou mais placas inválidas, por favor, verifique as placas.');
				return;
			}

			item.editingPlates = false;
			delete item.platesBeingEdited;
			item.plates = result.plates;
			item.quantity = item.plates.length;
		};

		function formatPlates(plates) {
			plates = plates.replace(/-/g, '');
			plates = plates.toUpperCase();
			plates = plates.replace(/\s\s+/g, ' ');
			plates = plates.replace(/\n/g, " ");
			plates = plates.replace(/;/g, ' ');
			plates = plates.replace(/,/g, ' ');
			plates = plates.replace(/\s\s+/g, ' ');
			plates = plates.trim();
			if (plates) {
				plates = plates.split(" ");
			} else {
				plates = [];
			}
			var valid = true;
			var platesSet = new buckets.Set();
			for (var i = 0; i < plates.length; i++) {
				var plate = plates[i];
				if (!validPlate(plate)) {
					valid = false;
				}
				platesSet.add(plate);
			}
			return {
				plates: platesSet.toArray(),
				valid: valid
			};
		}

		function validPlate(plate) {
			if (plate.length != 7) {
				return false;
			}
			return /^[A-Z]{3}[0-9]{4}$/.test(plate);
		}

		function validateItemsQuantities() {
			for (i = 0; i < ctrl.inspection.inspectionItems.length; i++) {
				if (ctrl.inspection.inspectionItems[i].plates && ctrl.inspection.inspectionItems[i].plates.length > 0) {
					return true;
				}
			}

			remarketApp.error('Não foram adicionadas placas aos veículos.');

			return false;
		}

		ctrl.back = function() {
			inspectionService.wizardSteps[0].onClick();
		};

		ctrl.next = function() {
			if (validateItemsQuantities()) {
				inspectionService.wizardSteps[2].onClick();
			}
		};
		
		ctrl.wizardSteps = inspectionService.wizardSteps;

		$scope.$on('remarket-login', init);

		init();
	};
	inspectionItemsCtrl.$inject = ['$scope', '$stateParams',
		'fipeService', '$timeout', 'inspectionService'];
	remarketApp.controller('inspectionItemsCtrl', inspectionItemsCtrl);

	var inspectionStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspections-items-edit', {
			url: '/inspections/items/edit/:inspectionId',
			templateUrl: 'view/panel/inspection/inspection-items.html',
			controller: inspectionItemsCtrl,
			controllerAs: 'inspectionItemsCtrl'
		});
	};
	inspectionStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionStateProvider);
}());