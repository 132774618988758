(function() {
	var remarketApp = angular.module('remarketApp');

	var internalAuctionBuyerCtrl = function($firebaseObject, $scope, $interval, envConfig) {
		var ctrl = this;
		
		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			ctrl.emptyNotification = 'Carregando lotes...';
			ctrl.auctionRunning = false;
			
			firebase.database()
					.ref(envConfig.envFirebase)
					.child('internal-auction/' + remarketApp.getUser().companyId)
					.on('value', function(snapshot) {
				ctrl.auctionRunning = snapshot.exists();
				ctrl.auction = snapshot.val();
				reloadDescription();
			});
		}

		function reloadDescription() {
			ctrl.vehicles = [];
			if (!ctrl.auctionRunning) {
				ctrl.emptyNotification = 'Nenhum veículo em negociação';
				return;
			}
			ctrl.startDate = new Date(ctrl.auction.startDate);
			ctrl.endDate   = new Date(ctrl.auction.endDate);

			updateCountdown();
			$interval(updateCountdown, 1000);
			
			var numberOfItems = ctrl.auction.vehicles == null ? 0 : Object.keys(ctrl.auction.vehicles).length;
			ctrl.emptyNotification = numberOfItems + (numberOfItems > 1 ? ' veículos' : ' veículo') + ' em negociação';

			if (numberOfItems > 0) {
				_.forEach(ctrl.auction.vehicles, function(vehicle) {
					var vehicleAux = $firebaseObject(firebase.database()
						.ref(envConfig.envFirebase).child('internal-auction/' + remarketApp.getUser().companyId + '/vehicles/' + vehicle.id));

					vehicleAux.$loaded().then(function() {
						ctrl.vehicles.push(vehicleAux);
					});
				});
			}
		}

		function updateCountdown() {
			var diff = moment.duration(ctrl.auction.endDate - (new Date()).getTime());
			ctrl.countdown = 'Negociações encerram em ' + diff.days() + ' dias, ' + diff.hours() + ' horas, ' + diff.minutes()
										+ ' minutos e ' + diff.seconds() + ' segundos.';
		}

		$scope.$on('remarket-login', init);

		init();
	};
	internalAuctionBuyerCtrl.$inject = ['$firebaseObject', '$scope', '$interval', 'envConfig'];
	remarketApp.controller('internalAuctionBuyerCtrl', internalAuctionBuyerCtrl);

	var internalAuctionStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.internal-auction', {
			url: '/internal-auction',
			templateUrl: 'view/panel/internal-auction/internal-auction.html',
			controller: internalAuctionBuyerCtrl,
			controllerAs: 'internalAuctionBuyerCtrl'
		});
	};
	internalAuctionStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(internalAuctionStateProvider);
}());