(function() {
	var remarketApp = angular.module('remarketApp');

	var mainController = function($scope, $location, envConfig) {
		var ctrl = this;

		$scope.$on('remarket-login', function(event, user){
			ctrl.user = user;
		});

		$scope.$on('remarket-logout', function(){
			delete ctrl.user;
		});

		ctrl.logout = remarketApp.logout;
		
		ctrl.isAdmin = remarketApp.isAdmin;

		ctrl.envConfig = envConfig;
		
		ctrl.isDev = function () {
			return ctrl.envConfig.env == 'development';
		};

		ctrl.showNavigationHeader = function() {
			return !ctrl.clientInstance || ctrl.user;
		};

		ctrl.useBaseTheme = function() {
			return !ctrl.clientInstance || !$location.path().endsWith("/");
		}

		ctrl.useClientTheme = function() {
			return ctrl.clientInstance && $location.path().endsWith("/");
		}

		ctrl.useAssetCss = function() {
			if (ctrl.useBaseTheme()) {
				return '/assets/deps.min.css';
			}

			return null;
		}

		ctrl.getCssUrl = function() {
			if (ctrl.clientInstance && $location.path() == '/') {
				return '/css/theme.css';
			}

			return '/css/remarket-app.css';
		}

		ctrl.useBaseFonts = function() {
			if (ctrl.useBaseTheme()) {
				return 'https://fonts.googleapis.com/css?family=Exo+2:400,600,700|Roboto:400,300,500,700';
			}

			return null;
		}

		ctrl.useBootstrap = function() {
			if (ctrl.useBaseTheme()) {
				return 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css';
			}

			return null;
		}

		ctrl.hasLogo = function() {
			var img = new Image();
			img.onload = good; 
			img.onerror = bad;
			img.src = imageSrc;
		}
		
		ctrl.isProduction = remarketApp.isProduction;

		ctrl.clientInstance = remarketApp.clientInstance;
	};
	mainController.$inject = ['$scope', '$location', 'envConfig'];
	remarketApp.controller('baseCtrl', mainController);

	var publicTemplateStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage', {
				url: '',
				abstract: true,
				controller: mainController,
				controllerAs: 'baseCtrl',
				templateUrl: 'view/public/base.html'
			});
	};
	publicTemplateStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(publicTemplateStateProvider);
}());
