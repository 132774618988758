(function() {
	var remarketApp = angular.module('remarketApp');

	var usersController = function($http, growl, FileSaver, Blob, userProfileTypeEnumService) {
		var ctrl = this;

		ctrl.profileTypes = userProfileTypeEnumService.getProfileTypes();

		ctrl.company = null;
		ctrl.name = null;
		ctrl.email = null;
		ctrl.type = null;
		ctrl.active = null;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;

		function init() {
			ctrl.load();
		}

		ctrl.setActive = function(active) {
			ctrl.active = active;
			ctrl.load();
		};

		ctrl.setType = function(type) {
			ctrl.type = type;
			ctrl.load();
		};

		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}

		ctrl.pagination = function() {
			ctrl.load();
		}

		ctrl.hasResults = function() {
			return ctrl.users && ctrl.users.length > 0;
		}

		ctrl.load = function() {
			ctrl.users = {};
			
			$http.get('/api/webapp/users/admin/findAllPaginated', {
				params: {
					company : ctrl.company,
					name : ctrl.name,
					email : ctrl.email,
					type : ctrl.type,
					active : ctrl.active,
					page: ctrl.currentPage, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.users = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		};
		
		ctrl.exportSpreadsheet = function() {
			$http.get('/api/webapp/users/admin/spreadsheet', {responseType: 'arraybuffer'}).then(function(response) {
				var data = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
			    FileSaver.saveAs(data, response.headers("filename"));
			}, remarketApp.handleError);
		}
		
		ctrl.resendSignUpMail = function(userId) {
			$http.post('/api/webapp/users/admin/send-new-user-mail', userId).then(function(response) {
				remarketApp.success('Email enviado com sucesso.');
			}, remarketApp.handleError);
		};

		init();
	};
	usersController.$inject = ['$http', 'growl', 'FileSaver', 'Blob', 'userProfileTypeEnumService'];
	remarketApp.controller('usersController', usersController);

	var userStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.users', {
				url: '/users',
				templateUrl: 'view/panel/user/users.html',
				controller: usersController,
				controllerAs: 'usersController'
			});
	};
	userStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(userStateProvider);
}());
