(function() {
	var remarketApp = angular.module('remarketApp');
	var configurationController = function($http, $scope) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			$http.get('/api/configuration/').then(function(response) {
				ctrl.configuration = response.data;
			}, remarketApp.handleError);
		}

		ctrl.save = function() {
			$http.post('/api/configuration/', ctrl.configuration).then(function() {
				remarketApp.success('Configuração alterada com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.startNextAuction = function() {
			$http.post('/api/auctions/admin/start-next-auction').then(function() {
				remarketApp.success('Leilão iniciado com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.stopCurrentAuction = function() {
			$http.post('/api/auctions/admin/stop-auction').then(function() {
				remarketApp.success('Leilão encerrado com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.reloadAuctionItems = function() {
			$http.post('/api/auctions/admin/reload-auction-items').then(function() {
				remarketApp.success('Itens recarregados com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.reloadInternalAuctionItems = function() {
			$http.post('/api/internal-auctions/admin/reload-auction-items').then(function() {
				remarketApp.success('Itens recarregados com sucesso.');
			}, remarketApp.handleError);
		};
		
		ctrl.startNextInternalAuctions = function() {
			$http.post('/api/internal-auctions/admin/start-next-auctions').then(function() {
				remarketApp.success('Leilões internos inicializados com sucesso.');
			}, remarketApp.handleError);
		};
		
		ctrl.stopCurrentInternalAuctions = function() {
			$http.post('/api/internal-auctions/admin//stop-current-auctions').then(function() {
				remarketApp.success('Leilões internos encerrados com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.sendScheduledItemsEmail = function() {
			$http.post('/api/admin/send-scheduled-items-email').then(function() {
				remarketApp.success('Itens enviados com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.checkGetek = function() {
			console.log('1');
			$http.post('/api/getek/run-getek-integration').then(sucesso => {
				console.log('2');
				console.log(sucesso);
				remarketApp.success('Integração realizada com sucesso.');
			}, erro => {
				console.log('3');
				console.log(erro);
				remarketApp.handleError
			});
		};

		ctrl.startNextClientInstanceAuctions = function() {
			$http.post('/api/auctions/client/admin/start/next').then(function() {
				remarketApp.success('Inicialização dos leilões efetuada com sucesso.');
			}, remarketApp.handleError);
		};

		ctrl.stopCurrentClientInstanceAuctions = function() {
			$http.post('/api/auctions/client/admin/stop/current').then(function() {
				remarketApp.success('Finalização dos leilões efetuada com sucesso.');
			}, remarketApp.handleError);
		};

		$scope.$on('remarket-login', init);
		init();
	};
	configurationController.$inject = ['$http', '$scope'];
	remarketApp.controller('configurationController', configurationController);

	var configurationStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.configuration', {
				url: '/configuration',
				templateUrl: 'view/panel/configuration/configuration.html',
				controller: configurationController,
				controllerAs: 'configurationController'
			});
	};
	configurationStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(configurationStateProvider);
}());
