(function() {
	var remarketApp = angular.module('remarketApp');
	
	var inspectionScheduleCtrl = function($scope, inspectionService, $stateParams, $http) {
		var ctrl = this;
		
		ctrl.wizardSteps = inspectionService.wizardSteps;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			inspectionService.loadSchedulingDetails($stateParams.inspectionId, function(schedulingDetails) {
				ctrl.schedulingDetails = schedulingDetails;

				if (ctrl.schedulingDetails.inspectionStatus == 'NEW' ||
					ctrl.schedulingDetails.inspectionStatus == 'PENDING_PAYMENT') {
					remarketApp.error('Esta vistoria não está pronta para ser agendada.');
					remarketApp.go('landingPage.panel.inspections');
				}
			});
		}
		
		ctrl.goBack = function() {
			remarketApp.go('landingPage.panel.inspections');
		};

		ctrl.batchDateUpdate = function() {
			var modifiedItems = false;
			_(ctrl.schedulingDetails.items).forEach(function(item) {
				if (item.selected) {
					item.desiredInspectionDate = ctrl.batchDate;
					item.selected = false;
					modifiedItems = true;
				}
			});
			
			if (!modifiedItems) {
				remarketApp.warning('Não foi selecionado um grupo de veículos para marcar a data de vistoria.');
			} else {
				ctrl.batchDate = null;
			}
		};

		ctrl.selectAllItems = function() {
			_(ctrl.schedulingDetails.items).forEach(function(item) {
				item.selected = ctrl.selectAll;
			});
		};

		ctrl.schedule = function() {
			$http.post('/api/webapp/inspections/scheduling/', {
				inspectionId: ctrl.schedulingDetails.id,
				items: ctrl.schedulingDetails.items
			}).then(function() {
				remarketApp.go('landingPage.panel.inspections');
				remarketApp.success('Agendamento realizado com sucesso.');
			},
			error => {
				console.log(error);
				remarketApp.handleError
			}
);
		};

		$scope.$on('remarket-login', init);

		init();
	};
	
	inspectionScheduleCtrl.$inject = ['$scope', 'inspectionService', '$stateParams', '$http'];
	remarketApp.controller('inspectionScheduleCtrl', inspectionScheduleCtrl);

	var inspectionScheduleStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspectionScheduling', {
			url: '/inspections/schedule/:inspectionId',
			templateUrl: 'view/panel/inspection/schedule/inspection-schedule.html',
			controller: inspectionScheduleCtrl,
			controllerAs: 'inspectionScheduleCtrl'
		});
	};
	inspectionScheduleStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionScheduleStateProvider);
}());
