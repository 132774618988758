(function() {
	var remarketApp = angular.module('remarketApp');

	var inspectionService = function($http) {
		var service = this;

		service.currentInspection = null;

		service.loadInspection = function (inspectionId, callback) {
			$http.get('/api/webapp/inspections/' + inspectionId).then(function(response) {
				service.currentInspection = response.data;
				callback(service.currentInspection);
			}, remarketApp.handleError);
		};
		
		service.loadSchedulingDetails = function(inspectionId, callback) {
			$http.get('/api/webapp/inspections/scheduling/' + inspectionId).then(function(response) {
				service.currentInspection = response.data;
				callback(response.data);
			}, remarketApp.handleError);
		};

		service.newInspection = function() {
			service.currentInspection = {
				inspectionItems: [],
				inspectionStatus: 'NEW'
			};
			return service.currentInspection;
		};
		
		service.resetCheckout = function(callback) {
			$http.post('/api/webapp/inspections/reset-checkout/' + service.currentInspection.id).then(function(response) {
				service.currentInspection = response.data;
				callback(service.currentInspection);
			}, remarketApp.handleError);
		};

		function saveInspection(callback) {
			$http.post('/api/webapp/inspections/', service.currentInspection).then(function(response) {
				service.currentInspection = response.data;
				callback(service.currentInspection);
			}, remarketApp.handleError);
		}

		service.wizardSteps = [
			{
				value: 'ADDRESS',
				title: 'Localização',
				icon: 'map-marker',
				description: 'Informe onde os vistoriadores encontrarão os veículos.',
				onClick: function() {
					if (service.currentInspection.inspectionStatus != 'NEW') {
						remarketApp.go('landingPage.panel.inspections-address-edit', {
							inspectionId: service.currentInspection.id
						});
						return;
					}
					saveInspection(function(inspection) {
						remarketApp.go('landingPage.panel.inspections-address-edit', {
							inspectionId: inspection.id
						});
					});
				}
			}, {
				value: 'VEHICLES',
				title: 'Veículos',
				icon: 'car',
				description: 'Preencha os dados dos veículos que serão vistoriados.',
				onClick: function() {
					if (service.currentInspection.inspectionStatus != 'NEW') {
						remarketApp.go('landingPage.panel.inspections-items-edit', {
							inspectionId: service.currentInspection.id
						});
						return;
					}
					saveInspection(function(inspection) {
						remarketApp.go('landingPage.panel.inspections-items-edit', {
							inspectionId: inspection.id
						});
					});
				}
			}, {
				value: 'CHECKOUT',
				title: 'Pagamento',
				icon: 'credit-card',
				description: 'Realize o pagamento dos serviços contratados.',
				onClick: function() {
					if (service.currentInspection.inspectionStatus != 'NEW') {
						remarketApp.go('landingPage.panel.inspectionCheckout', {
							inspectionId: service.currentInspection.id
						});
						return;
					}
					saveInspection(function(inspection) {
						remarketApp.go('landingPage.panel.inspectionCheckout', {
							inspectionId: inspection.id
						});
					});
				}
			}, {
				value: 'SCHEDULING',
				title: 'Agendamento',
				icon: 'calendar-check-o',
				description: 'Agende a data da vistoria dos veículos.',
				onClick: function() {
					if (service.currentInspection.inspectionStatus == 'NEW') {
						remarketApp.error('O agendamento só pode ser realizado após a constatação do pagamento da vistoria.');
						return;
					}
					if (service.currentInspection.inspectionStatus == 'PENDING_PAYMENT') {
						remarketApp.error('O pagamento desta vistoria ainda não foi identificado. Caso você tenha pago ' +
							'por boleto, a identificação do pagamento pode demorar até 48 horas úteis.');
						return;
					}
					remarketApp.go('landingPage.panel.inspectionScheduling', {
						inspectionId: service.currentInspection.id
					});
				}
			}
		];
	};
	inspectionService.$inject = ['$http'];
	remarketApp.service('inspectionService', inspectionService);
}());