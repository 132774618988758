(function() {
	var remarketApp = angular.module('remarketApp');
	var usersInternalInviteController = function($http, growl, enumService) {
		var ctrl = this;

		ctrl.profileOptions = [
			{value: null, label: 'Selecione'},
			{value: 'ADMIN', label: 'Administrador'},
			{value: 'INSPECTIONER', label: 'Inspecionador'}
		];
		ctrl.selectedProfileType = ctrl.profileOptions[0];
		
		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			ctrl.mailAddresses = null;
			
			if (remarketApp.getUser().companyType == 'FLEET_OWNER') {
				ctrl.profileOptions.push({value: 'INTERNAL_BUYER', label: 'Comprador Interno'})
			}
		}
		
		ctrl.sendInvites = function() {
			if (!ctrl.mailAddresses) {
				remarketApp.error('Informe os endereços de e-mail para onde serão enviados os convites.');
				return;
			}
			
			if (!ctrl.selectedProfileType || ctrl.selectedProfileType.value == null) {
				remarketApp.error('Informe o Tipo de Acesso dos Usuários convidados.');
				return;
			}
			
			var invites = {
				profileType: ctrl.selectedProfileType.value,
				mailAddresses: ctrl.mailAddresses
			};
			
			$http.post('/api/webapp/users-internal/send-invites', invites).then(function(response) {
				remarketApp.success('Acabamos de enviar os convites com instruções de cadastro.');
				remarketApp.go('landingPage.panel.users-internal');
			}, remarketApp.handleError);
		}
		
		init();
	};
	usersInternalInviteController.$inject = ['$http', 'growl', 'enumService'];
	remarketApp.controller('usersInternalInviteController', usersInternalInviteController);

	var userStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.panel.users-internal-invite', {
				url: '/users-internal-invite',
				templateUrl: 'view/panel/users-internal/users-internal-invite.html',
				controller: usersInternalInviteController,
				controllerAs: 'usersInternalInviteController'
			});
	};
	userStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(userStateProvider);
}());
