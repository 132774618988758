(function() {
	var remarketApp = angular.module('remarketApp');
	var retrievePasswordController = function($http) {
		var ctrl = this;
		ctrl.retrievePassword = function () {
			if (!ctrl.email) {
				return remarketApp.warning('Preencha o seu e-mail.');
			}
			$http.post('/api/webapp/users/retrieve-password', ctrl.email).then(function() {
				remarketApp.info('Acabamos de enviar instruções para o seu e-mail ' +
					'informando como gerar uma nova senha.');
				remarketApp.go('landingPage.login');
			}, remarketApp.handleError);
		};
	};
	retrievePasswordController.$inject = ['$http'];
	remarketApp.controller('retrievePasswordController', retrievePasswordController);

	var retrievePasswordStateProvider = function($stateProvider) {
		$stateProvider
			.state('landingPage.retrievePassword', {
				url: '/retrieve-password',
				templateUrl: 'view/public/login/retrieve-password.html',
				controller: retrievePasswordController,
				controllerAs: 'retrievePasswordCtrl'
			});
	};
	retrievePasswordStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(retrievePasswordStateProvider);
}());
