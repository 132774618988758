(function () {
	var remarketApp = angular.module('remarketApp');

	var vehicleTradeHistoryController = function($http, $scope, enumService) {
		var ctrl = this;

		ctrl.totalPrice = 0;
		ctrl.totalFipePrice = 0;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			
			$http.get('/api/trades/vehicles/current-month').then(function(response) {
				ctrl.vehicles = response.data;
				ctrl.totalPrice = 0;
				ctrl.totalFipePrice = 0;
				_.forEach(ctrl.vehicles, function(vehicle) {
					ctrl.totalPrice += vehicle.price;
					ctrl.totalFipePrice += vehicle.fipePrice;
				});
			}, remarketApp.handleError);
		}

		$scope.$on('remarket-login', init);
		init();
	};
	vehicleTradeHistoryController.$inject = ['$http', '$scope', 'enumService'];
	remarketApp.controller('vehicleTradeHistoryController', vehicleTradeHistoryController);

	var tradeHistoryStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.vehicleTradeHistory', {
			url: '/vehicle-trade-history',
			templateUrl: 'view/panel/vehicle-trade-history/vehicle-trade-history.html',
			controller: vehicleTradeHistoryController,
			controllerAs: 'vehicleTradeHistoryController'
		});
	};
	tradeHistoryStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(tradeHistoryStateProvider);
}());