(function () {
	var remarketApp = angular.module('remarketApp');

	var companyDashboardController = function($http, $scope) {
		var ctrl = this;
		
		ctrl.loadingData = true;
		ctrl.loadingDataLabel = 'Carregando indicadores...';

		ctrl.states = [
			{ value: 'BR', label: 'Estado - Todos' },
			{ value: 'AC', label: 'Estado - Acre' },
			{ value: 'AL', label: 'Estado - Alagoas' },
			{ value: 'AM', label: 'Estado - Amazonas' },
			{ value: 'AP', label: 'Estado - Amapá' },
			{ value: 'BA', label: 'Estado - Bahia' },
			{ value: 'CE', label: 'Estado - Ceará' },
			{ value: 'DF', label: 'Estado - Distrito Federal' },
			{ value: 'ES', label: 'Estado - Espírito Santo' },
			{ value: 'GO', label: 'Estado - Goiás' },
			{ value: 'MA', label: 'Estado - Maranhão' },
			{ value: 'MG', label: 'Estado - Minas Gerais' },
			{ value: 'MS', label: 'Estado - Mato Grosso do Sul' },
			{ value: 'MT', label: 'Estado - Mato Grosso' },
			{ value: 'PA', label: 'Estado - Pará' },
			{ value: 'PB', label: 'Estado - Paraíba' },
			{ value: 'PE', label: 'Estado - Pernambuco' },
			{ value: 'PI', label: 'Estado - Piauí' },
			{ value: 'PR', label: 'Estado - Paraná' },
			{ value: 'RJ', label: 'Estado - Rio de Janeiro' },
			{ value: 'RN', label: 'Estado - Rio Grande do Norte' },
			{ value: 'RO', label: 'Estado - Rondônia' },
			{ value: 'RR', label: 'Estado - Roraima' },
			{ value: 'RS', label: 'Estado - Rio Grande do Sul' },
			{ value: 'SC', label: 'Estado - Santa Catarina' },
			{ value: 'SE', label: 'Estado - Sergipe' },
			{ value: 'SP', label: 'Estado - São Paulo' },
			{ value: 'TO', label: 'Estado - Tocantins' }
		];
		ctrl.selectedState = ctrl.states[0];
		
		ctrl.dashboardTypes = [
			{ label: 'Período - Mês atual', value: 'current-month' },
			{ label: 'Período - Últimos 30 dias', value: 'last-30-days' },
			{ label: 'Período - Últimos 6 meses', value: 'last-6-months' },
			{ label: 'Período - Últimos 12 meses', value: 'last-12-months' }
		];
		ctrl.selectedDashboardType = ctrl.dashboardTypes[0];

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			ctrl.reloadDashboard();
		}
		
		ctrl.reloadDashboard = function () {
			ctrl.loadingData = true;
			$http.get('/api/dashboard/company/' + ctrl.selectedDashboardType.value + '/' + ctrl.selectedState.value).then(function(response) {
				loadCompanyExchangeChart(response.data.tradeHistory);
				loadCompanyTradeHistoryPerModelChart(response.data.tradeHistoryPerModel);
				ctrl.amountPaid = response.data.amountPaid;
				ctrl.vehiclesBought = response.data.vehiclesBought;
				ctrl.loadingData = false;
			}, remarketApp.handleError);
		};

		function loadCompanyExchangeChart(tradeHistory) {
			var yAxis = [{
				title: {
					text: 'Quantidade de veículos'
				}
			}, {
				title: {
					text: 'Valor em R$'
				},
				opposite: true
			}];
			tradeHistory.series[0].name = 'Quantidade de veículos';
			tradeHistory.series[0].type = 'column';
			tradeHistory.series[1].name = 'Valor em R$';
			tradeHistory.series[1].yAxis = 1;
			loadChart('company-exchange-chart', undefined, 'Histórico de negociações', tradeHistory.categories,
				yAxis, tradeHistory.series);
		}

		function loadCompanyTradeHistoryPerModelChart(tradeHistoryPerModel) {
			var yAxis = {
				title: {
					text: 'Quantidade'
				}
			};
			_.each(tradeHistoryPerModel.data, function (entry) {
				entry.y = entry.value;
			});
			var series = [{
				data: tradeHistoryPerModel.data
			}];
			loadChart('company-models-bought-chart', 'column', 'Negociações por modelo', tradeHistoryPerModel.categories,
				yAxis, series, true);
		}

		function loadChart(elementId, chartType, title, categories, yAxis, series, disableLegend) {
			Highcharts.chart(elementId, {
				chart: {
					backgroundColor: null,
					height: 300,
					type: chartType
				},
				title: {
					text: title
				},
				xAxis: {
					categories: categories
				},
				yAxis: yAxis,
				legend: {
					labelFormatter: function () {
						if (this.name.length > 20) {
							return this.name.substr(0,20) + '...';
						}
						return this.name;
					},
					enabled: !disableLegend
				},
				series: series,
				credits: {
					enabled: false
				}
			});
		}

		$scope.$on('remarket-login', init);
		init();
	};
	companyDashboardController.$inject = ['$http', '$scope'];
	remarketApp.controller('companyDashboardController', companyDashboardController);

	var companyDashboardStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.company-dashboard', {
			url: '/company-dashboard',
			templateUrl: 'view/panel/company-dashboard/company-dashboard.html',
			controller: companyDashboardController,
			controllerAs: 'companyDashboardController'
		});
	};
	companyDashboardStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(companyDashboardStateProvider);
}());