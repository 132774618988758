(function() {
	var remarketApp = angular.module('remarketApp');

	remarketApp.filter('localDateTime', function () {
		function pad(num, size) {
			var s = "00000" + num;
			return s.substr(s.length - size);
		}

		return function (input) {
			return pad(input.dayOfMonth, 2) + '/' + pad(input.monthValue, 2) + '/' + (input.year + '').substring(2) + ' ' +
				pad(input.hour, 2) + ':' + pad(input.minute, 2);
		};
	});
}());