(function() {
	var remarketApp = angular.module('remarketApp');
	
	var submenuCtrl = function($attrs, $location, $scope) {
		var ctrl = this;
		ctrl.hidden = true;
		ctrl.parents = $attrs.parents.replace(" ", "").split(",");
		
		ctrl.show = function(path) {
			//FIXME: when logging with a non-admin profile, it reloads the parents attribute
			if (ctrl.parents.indexOf(",") >= 0) {
				ctrl.parents = ctrl.parents.replace(" ", "").split(",");
			}
			ctrl.hidden = ctrl.parents.indexOf(path) < 0;
		};
		
		$scope.$on('$locationChangeSuccess', function(event, current, previous) {
			ctrl.show($location.url());
		});
		
		ctrl.show($location.url());
	};
	submenuCtrl.$inject = ['$attrs', '$location', '$scope'];
	
	remarketApp.component('submenu', {
		bindings: {
			parents: '@'
		},
		templateUrl: 'component/submenu/submenu.html',
		transclude: true,
		controller: submenuCtrl
	});
}());