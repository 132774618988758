(function () {
	var remarketApp = angular.module('remarketApp');

	var clientInstanceNegotiationCtrl = function($http, $scope) {
		var ctrl = this;

		ctrl.status = null;
		ctrl.company = null;
		ctrl.currentPage = 1;
		ctrl.numPages = 0;
		ctrl.totalItems = 0;
		ctrl.itemsPerPage = 20;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			ctrl.load();
			
			ctrl.updateTradableCount();
		}

		ctrl.load = function() {
			$http.get('/api/auctions/client/', {
				params: {
					status: ctrl.status,
					page: ctrl.currentPage - 1, 
					size: ctrl.itemsPerPage
				}
			}).then(function(response) {
				ctrl.negotiations = response.data.content;
				ctrl.numPages = response.data.totalPages;
				ctrl.totalItems = response.data.totalElements;
			}, remarketApp.handleError);
		}

		ctrl.pagination = function() {
			ctrl.load();
		}

		ctrl.setStatus = function(status) {
			ctrl.status = status;
			ctrl.load();
		}
		
		ctrl.changePageSize = function(size) {
			ctrl.currentPage = 1;
			ctrl.itemsPerPage = size;
			ctrl.load();
		}

		ctrl.updateTradableCount = function() {
			$http.get('/api/vehicles/tradable/count').then(function(response) {
				ctrl.notTrading = response.data.total;
			}, remarketApp.handleError);
		};
		
		ctrl.showVisualizeButton = function(negotiation) {
			return negotiation.status != 'SCHEDULED';
		}
		
		ctrl.showEditButton = function(negotiation) {
			return negotiation.status == 'SCHEDULED';
		}
		
		ctrl.showRemoveButton = function(negotiation) {
			return negotiation.status == 'SCHEDULED';
		}

		ctrl.showEmptyResultsMessage = function() {
			return !ctrl.negotiations || ctrl.negotiations.length == 0;
		}
		
		ctrl.deleteScheduledAuction = function(id) {
			$http.delete('/api/auctions/client/' + id).then(function(response) {
				remarketApp.success('Negociação removida com sucesso.');
				ctrl.load();
			}, remarketApp.handleError);
		}
		
		$scope.$on('remarket-login', init);
		init();
	};
	remarketApp.controller('clientInstanceNegotiationCtrl', clientInstanceNegotiationCtrl);

	var clientInstanceNegotiationsStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.negotiations-client-instance', {
			url: '/negotiations/client-instance',
			templateUrl: 'view/panel/negotiation/client-instance/client-instance-negotiation.html',
			controller: clientInstanceNegotiationCtrl,
			controllerAs: 'clientInstanceNegotiationCtrl'
		});
	};
	clientInstanceNegotiationsStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(clientInstanceNegotiationsStateProvider);
}());