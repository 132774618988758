(function () {
	var remarketApp = angular.module('remarketApp');

	var auctionItemBatchCtrl = function($http, $scope, $stateParams, auctionItemService, $filter, $state) {
		var ctrl = this;
		ctrl.vehiclesSelectedForSale = [];
		ctrl.minimumPrice = 0;

		ctrl.title = '';

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			if (remarketApp.isAdmin()) {
				ctrl.title = 'Detalhes da venda';
			} else {
				ctrl.title = 'Configuração da venda';
			}

			ctrl.spare = $state.is('landingPage.panel.spare-auction-items-new-batch');
			
			if ($stateParams.auctionItemId) {
				$http.get('/api/auctions/items/edit/' + $stateParams.auctionItemId).then(function(response) {
					ctrl.auctionItem = response.data;
					
					if (ctrl.auctionItem.auction) {
						ctrl.auctionItem.auctionId = ctrl.auctionItem.auction.id;
					}

					if (!remarketApp.isAdmin()) {
						$http.get('/api/vehicles/tradable').then(function (response) {
							ctrl.vehicles = getSelectableVehicles(response.data);
							for (var i = 0; i < ctrl.auctionItem.vehicles.length; i++) {
								ctrl.vehiclesSelectedForSale.push({
									vehicleId: ctrl.auctionItem.vehicles[i].id,
									plate: ctrl.auctionItem.vehicles[i].plate,
									price: ctrl.auctionItem.vehicles[i].price,
									fipePrice: ctrl.auctionItem.vehicles[i].fipePrice,
									damageValue: ctrl.auctionItem.vehicles[i].damageValue,
									description: ctrl.auctionItem.vehicles[i].description,
									origin: ctrl.auctionItem.vehicles[i].origin,
									externalInspectionId: ctrl.auctionItem.vehicles[i].externalInspectionId
								});
								ctrl.minimumPrice += ctrl.auctionItem.vehicles[i].price;
							}
							delete ctrl.auctionItem.vehicles;
						});
					} else {
						for (var i = 0; i < ctrl.auctionItem.vehicles.length; i++) {
							ctrl.vehiclesSelectedForSale.push({
								vehicleId: ctrl.auctionItem.vehicles[i].id,
								plate: ctrl.auctionItem.vehicles[i].plate,
								price: ctrl.auctionItem.vehicles[i].price,
								fipePrice: ctrl.auctionItem.vehicles[i].fipePrice,
								damageValue: ctrl.auctionItem.vehicles[i].damageValue,
								description: ctrl.auctionItem.vehicles[i].description,
								origin: ctrl.auctionItem.vehicles[i].origin,
								externalInspectionId: ctrl.auctionItem.vehicles[i].externalInspectionId
							});
							ctrl.minimumPrice += ctrl.auctionItem.vehicles[i].price;
						}
					}
				}, remarketApp.handleError);
			} else {
				$http.get('/api/vehicles/tradable').then(function (response) {
					ctrl.vehicles = getSelectableVehicles(response.data);
					ctrl.auctionItem = {
						vehicleIds: []
					};
					if (ctrl.vehicles.length == 0) {
						remarketApp.warning('Não foram encontrados veículos vistoriados e prontos para serem negociados.');
					}
					if (ctrl.spare) {
						ctrl.vehiclesSelectedForSale = auctionItemService.vehiclesSelectedForSale || [];
					}
				}, remarketApp.handleError);
			}
		}

		function getSelectableVehicles(vehicles) {
			var selectable = _.filter(vehicles, function(v) { return v.updatable; });
			
			for (var i = 0; i < selectable.length; i++) {
				selectable[i].selectorDescription = $filter('plate')(selectable[i].plate) + ' - ' + selectable[i].description;
			}

			return selectable;
		}

		$scope.$on('remarket-login', init);
		
		ctrl.setSelectedVehicles = function(selectedVehicles) {
			ctrl.selectedVehicles = selectedVehicles;
		};

		ctrl.addVehicles = function() {
			for (var i = 0; i < ctrl.selectedVehicles.length; i++) {
				var selectedVehicle = ctrl.selectedVehicles[i];
				var contains = _.findWhere(ctrl.vehiclesSelectedForSale, { plate: selectedVehicle.plate }) != null;
				if (!contains) {
					ctrl.vehiclesSelectedForSale.push(selectedVehicle);
					ctrl.minimumPrice += selectedVehicle.price;

					let index = _.findIndex(ctrl.vehicles, function(vehicle){ return vehicle.plate == selectedVehicle.plate });
					if (index > -1) {
						ctrl.vehicles.splice(index, 1);
					}
				}
			}
		};

		ctrl.removeVehicle = function(index) {
			ctrl.minimumPrice -= ctrl.vehiclesSelectedForSale[index].price;
			var vehicle = ctrl.vehiclesSelectedForSale[index];
			if (!vehicle.selectorDescription) {
				vehicle.selectorDescription = $filter('plate')(vehicle.plate) + ' - ' + vehicle.description;
			}

			ctrl.vehicles.push(vehicle);
			
			ctrl.vehicles = _.sortBy(ctrl.vehicles, "plate");

			ctrl.vehiclesSelectedForSale.splice(index, 1);
		};
		
		ctrl.showVehicleDetailsButton = function(vehicle) {
			return vehicle.origin == 'GETEK';
		}
		
		ctrl.showVisualizeMobileButton = function(vehicle) {
			return vehicle.origin == 'MOBILE';
		}

		ctrl.loadVehicleDetails = function(vehicle) {
			document.body.style.overflow = 'hidden';
			ctrl.vehicleDetailsId = vehicle.vehicleId;
		};
		
		ctrl.loadMobileInspectionDetails = function(vehicle) {
			document.body.style.overflow = 'hidden';
			ctrl.mobileInspectionId = vehicle.externalInspectionId;
		};

		ctrl.closeVehicleDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.vehicleDetailsId = null;
		};
		
		ctrl.closeMobileInspectionDetails = function() {
			document.body.style.overflow = 'visible';
			ctrl.mobileInspectionId = null;
		}; 

		ctrl.goBack = function() {
			if (!ctrl.isEditable()) {
				return remarketApp.go('landingPage.panel.auction-items');
			}
			if ($stateParams.auctionItemId) {
				remarketApp.go('landingPage.panel.auction-items-edit-pricing', {
					auctionItemId: $stateParams.auctionItemId
				});
			} else if (!ctrl.spare) {
				remarketApp.go('landingPage.panel.auction-items-new-pricing');
			} else {
				remarketApp.go('landingPage.panel.spare-auction-items-new-pricing');
			}
		};

		ctrl.next = function() {
			if (ctrl.spare) {
				auctionItemService.holdSpareAuctionItems(ctrl.vehiclesSelectedForSale);
			} else {
				ctrl.auctionItem.minimumPrice = ctrl.minimumPrice;
				auctionItemService.saveAuctionItem(ctrl.auctionItem, ctrl.vehiclesSelectedForSale);
			}
		};
		
		ctrl.isEditable = function() {
			if (remarketApp.isAdmin()) {
				return false;
			}
			if (!ctrl.auctionItem) {
				return true;
			}
			return !ctrl.auctionItem.status || 
				ctrl.auctionItem.status == 'NEW' || ctrl.auctionItem.status == 'SCHEDULED';
		};
		
		ctrl.wizardSteps = auctionItemService.wizardSteps;
		ctrl.wizardSteps[0].onClick = ctrl.goBack;
		ctrl.wizardSteps[2].onClick = ctrl.next;

		init();
	};
	auctionItemBatchCtrl.$inject = ['$http', '$scope', '$stateParams', 'auctionItemService', '$filter', '$state'];
	remarketApp.controller('auctionItemBatchCtrl', auctionItemBatchCtrl);

	var auctionItemBatchConfigStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.auction-items-new-batch', {
			url: '/auction-items/vehicles/new',
			templateUrl: 'view/panel/auction-item/auction-item-batch.html',
			controller: auctionItemBatchCtrl,
			controllerAs: 'auctionItemBatchCtrl'
		}).state('landingPage.panel.auction-items-edit-batch', {
			url: '/auction-items/:auctionItemId',
			templateUrl: 'view/panel/auction-item/auction-item-batch.html',
			controller: auctionItemBatchCtrl,
			controllerAs: 'auctionItemBatchCtrl'
		}).state('landingPage.panel.spare-auction-items-new-batch', {
			url: '/auction-items/spare/vehicles/new',
			templateUrl: 'view/panel/auction-item/auction-item-batch.html',
			controller: auctionItemBatchCtrl,
			controllerAs: 'auctionItemBatchCtrl'
		});
	};
	auctionItemBatchConfigStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(auctionItemBatchConfigStateProvider);
}());