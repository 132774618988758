(function() {
	var remarketApp = angular.module('remarketApp');
	
	var auctionItemService = function($http) {
		var service = this;
		
		service.wizardSteps = [
			{
				value: 'PRICING',
				title: 'Precificação',
				icon: 'usd',
				description: 'Precifique os veículos vistoriados.'
			}, {
				value: 'BATCH',
				title: 'Lote',
				icon: 'car',
				description: 'Selecione os veículos que serão negociados.'
			}, {
				value: 'SCHEDULING',
				title: 'Agendamento',
				icon: 'calendar-check-o',
				description: 'Selecione o período em que esse lote será negociado.'
			}
		];
		
		service.loadPriceableVehicles = function(callback) {
			var endpoint = '/api/vehicles/priceable';

			$http.get(endpoint).then(function(response) {
				callback(response.data);
			}, remarketApp.handleError);
		};
		
		service.loadTradableVehicles = function(callback) {
			var endpoint = '/api/vehicles/tradable';
			$http.get(endpoint).then(function(response) {
				callback(response.data);
			}, remarketApp.handleError);
		};
		
		service.savePricing = function(vehicles, nextStep, params) {
			if (remarketApp.isAdmin()) {
				return remarketApp.go(nextStep, params);
			}
			$http.post('/api/vehicles/pricing', vehicles).then(function() {
				remarketApp.go(nextStep, params);
			}, remarketApp.handleError);
		};
		
		service.saveAuctionItem = function(auctionItem, vehiclesSelectedForSale) {
			if (remarketApp.isAdmin()) {
				return remarketApp.go('landingPage.panel.auction-items-scheduling-batch', {
					auctionItemId: auctionItem.id
				});
			}
			
			var auctionItemEndpoint = '/api/auctions/items';
			if (auctionItem.id) {
				auctionItemEndpoint += '/' + auctionItem.id;
			} else {
				auctionItemEndpoint += '/batch';
			}

			auctionItem.vehicleIds = [];
			for (var i = 0; i < vehiclesSelectedForSale.length; i++) {
				auctionItem.vehicleIds.push(vehiclesSelectedForSale[i].vehicleId);
			}

			$http.post(auctionItemEndpoint, auctionItem).then(function(response) {
				let id = null;
				if (auctionItem.id) {
					id = auctionItem.id;
				} else {
					id = response.data.id;
				}

				remarketApp.go('landingPage.panel.auction-items-scheduling-batch', {
					auctionItemId: id
				});

			}, remarketApp.handleError);
		};
		
		service.holdSpareAuctionItems = function(vehiclesSelectedForSale) {
			if (!vehiclesSelectedForSale || vehiclesSelectedForSale.length == 0) {
				return remarketApp.error('Primeiro você deve selecionar quais os veículos você quer negociar.');
			}
			service.vehiclesSelectedForSale = vehiclesSelectedForSale;
			remarketApp.go('landingPage.panel.spare-auction-items-scheduling-batch');
		};

		service.getSelectedVehicles = function () {
			if (!service.vehiclesSelectedForSale) {
				remarketApp.error('Selecione ao menos um veículo.');
				return remarketApp.go('landingPage.panel.spare-auction-items-new-batch');
			}
			return service.vehiclesSelectedForSale;
		};
		
		service.createSpareAuctionItems = function(auction) {
			if (!auction) {
				remarketApp.error('Selecione um período de venda.');
				return;
			}
			var spareAuctionItem = {
				auctionItemVehicles: [],
				auctionId: auction.id
			};
			
			for (var i = 0; i < service.vehiclesSelectedForSale.length; i++) {
				spareAuctionItem.auctionItemVehicles.push({
					vehicleId: service.vehiclesSelectedForSale[i].vehicleId,
					showToIndividuals: service.vehiclesSelectedForSale[i].showToIndividuals
				});
			}
			
			$http.post('/api/auctions/items/spare', spareAuctionItem).then(function() {
				delete service.vehiclesSelectedForSale;
				remarketApp.success('Vendas avulsas salvas com sucesso.');
				remarketApp.go('landingPage.panel.auction-items');
			}, remarketApp.handleError);
		};
		
		service.schedule = function(selectedAuction, auctionItem) {
			if (!selectedAuction) {
				remarketApp.error('Selecione um período de venda.');
				return;
			}
			
			$http.post('/api/auctions/items/schedule/' + auctionItem.id, selectedAuction.id).then(function() {
				remarketApp.success('Venda agendada com sucesso.');
				remarketApp.go('landingPage.panel.auction-items');
			}, remarketApp.handleError);
		}
	};
	auctionItemService.$inject = ['$http'];
	remarketApp.service('auctionItemService', auctionItemService);
}());