(function () {
	var remarketApp = angular.module('remarketApp');

	var inspectionReviewCtrl = function($http, $scope, $stateParams) {
		var ctrl = this;

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}

			if ($stateParams.inspectionId) {
				$http.get('/api/webapp/inspections/review/' + $stateParams.inspectionId).then(function(response) {
					ctrl.inspection = response.data;

					ctrl.inspectedItems = [];
					ctrl.nonInspectedItems = [];

					for (var i = 0, len = ctrl.inspection.inspectionItems.length; i < len; i++) {
						item = ctrl.inspection.inspectionItems[i];
						if (item.inspectionDate) {
							ctrl.inspectedItems.push(item);
						} else {
							ctrl.nonInspectedItems.push(item);
						}
					}
				}, remarketApp.handleError);
			}
		}

		ctrl.closeVehicleDetails = function() {
			ctrl.vehicleDetailsId = null;
		};

		ctrl.hasNonInspectedItems = function() {
			return !_.isEmpty(ctrl.nonInspectedItems);
		}

		init();
	};
	inspectionReviewCtrl.$inject = ['$http', '$scope', '$stateParams'];
	remarketApp.controller('inspectionReviewCtrl', inspectionReviewCtrl);

	var inspectionReviewStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.inspection-review', {
			url: '/inspections/review/:inspectionId',
			templateUrl: 'view/panel/inspection/inspection-review.html',
			controller: inspectionReviewCtrl,
			controllerAs: 'inspectionReviewCtrl'
		});
	};
	inspectionReviewStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(inspectionReviewStateProvider);
}());