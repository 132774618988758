//This interceptor adds the localhost:8080 when the app calls a relative URL
(function() {
	var remarketApp = angular.module('remarketApp');
	var urlInterceptor = function(envConfig) {
		var service = function() {
			return {
				'request': function(config) {
					if (config.url.indexOf('/api/address') == 0) {
						config.url = envConfig.addressServicePort + config.url;
					} else if (config.url.indexOf('/api/auction') == 0) {
						config.url = envConfig.auctionServicePort + config.url;
					} else if (config.url.indexOf('/api/internal-auctions') == 0) {
						config.url = envConfig.auctionServicePort + config.url;
					} else if (config.url.indexOf('/api/company') == 0) {
						config.url = envConfig.companyServicePort + config.url;
					} else if (config.url.indexOf('/api/configuration') == 0) {
						config.url = envConfig.configurationServicePort + config.url;
					} else if (config.url.indexOf('/api/fipe') == 0) {
						config.url = envConfig.fipeServicePort + config.url;
					} else if (config.url.indexOf('/api/getek') == 0) {
						config.url = envConfig.getekServicePort + config.url;
					} else if (config.url.indexOf('/api/webapp/inspection') == 0) {
						config.url = envConfig.inspectionServicePort + config.url;
					} else if (config.url.indexOf('/api/webapp/users') == 0) {
						config.url = envConfig.userServicePort + config.url;
					} else if (config.url.indexOf('/api/vehicles') == 0) {
						config.url = envConfig.vehicleServicePort + config.url;
					}

					return config;
				}
			};
		};
		this.$get = service;
	};
	urlInterceptor.$inject = ['envConfig'];
	remarketApp.provider('urlInterceptor', urlInterceptor);
}());