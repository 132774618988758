(function () {
	var remarketApp = angular.module('remarketApp');

	var storeDashboardController = function($http, $scope) {
		var ctrl = this;
		
		ctrl.loadingData = true;
		ctrl.loadingDataLabel = 'Carregando indicadores...';
		
		ctrl.dashboardTypes = [
			{ label: 'Período - Mês atual', value: 'current-month' },
			{ label: 'Período - Últimos 30 dias', value: 'last-30-days' },
			{ label: 'Período - Últimos 6 meses', value: 'last-6-months' },
			{ label: 'Período - Últimos 12 meses', value: 'last-12-months' }
		];
		ctrl.selectedDashboardType = ctrl.dashboardTypes[0];

		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			ctrl.reloadDashboard();
		}
		
		ctrl.reloadDashboard = function () {
			ctrl.loadingData = true;
			$http.get('/api/dashboard/store/' + ctrl.selectedDashboardType.value).then(function(response) {
				loadStoreExchangeChart(response.data.tradeHistory);
				loadStoretradeHistoryPerModelChart(response.data.tradeHistoryPerModel);
				ctrl.amountPaid = response.data.amountPaid;
				ctrl.vehiclesBought = response.data.vehiclesBought;
				ctrl.loadingData = false;
			}, remarketApp.handleError);
		};

		function loadStoreExchangeChart(tradeHistory) {
			var yAxis = [{
				title: {
					text: 'Quantidade de veículos'
				}
			}, {
				title: {
					text: 'Valor em R$'
				},
				opposite: true
			}];
			tradeHistory.series[0].name = 'Quantidade de veículos';
			tradeHistory.series[0].type = 'column';
			tradeHistory.series[1].name = 'Valor em R$';
			tradeHistory.series[1].yAxis = 1;
			loadChart('store-exchange-chart', undefined, 'Histórico de negociações', tradeHistory.categories,
				yAxis, tradeHistory.series);
		}

		function loadStoretradeHistoryPerModelChart(tradeHistoryPerModel) {
			var yAxis = {
				title: {
					text: 'Quantidade'
				}
			};
			_.each(tradeHistoryPerModel.data, function (entry) {
				entry.y = entry.value;
			});
			var series = [{
				data: tradeHistoryPerModel.data
			}];
			loadChart('store-models-bought-chart', 'column', 'Negociações por modelo', tradeHistoryPerModel.categories,
				yAxis, series, true);
		}

		function loadChart(elementId, chartType, title, categories, yAxis, series, disableLegend) {
			Highcharts.chart(elementId, {
				chart: {
					backgroundColor: null,
					height: 300,
					type: chartType
				},
				title: {
					text: title
				},
				xAxis: {
					categories: categories
				},
				yAxis: yAxis,
				legend: {
					labelFormatter: function () {
						if (this.name.length > 20) {
							return this.name.substr(0,20) + '...';
						}
						return this.name;
					},
					enabled: !disableLegend
				},
				series: series,
				credits: {
					enabled: false
				}
			});
		}

		$scope.$on('remarket-login', init);
		init();
	};
	storeDashboardController.$inject = ['$http', '$scope'];
	remarketApp.controller('storeDashboardController', storeDashboardController);

	var storeDashboardStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.store-dashboard', {
			url: '/store-dashboard',
			templateUrl: 'view/panel/store-dashboard/store-dashboard.html',
			controller: storeDashboardController,
			controllerAs: 'storeDashboardController'
		});
	};
	storeDashboardStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(storeDashboardStateProvider);
}());