(function() {
	var remarketApp = angular.module('remarketApp');

	var adminDashboardCtrl = function($http, $scope) {
		var ctrl = this;
		ctrl.loadingDataLabel = 'Carregando...';
		
		ctrl.reloadDashboard = function() {
			ctrl.loadingData = true;
			$http.get('/api/dashboard/admin/historical-data').then(function(response) {
				ctrl.dashboardData = response.data;
				loadActiveUsersChart();
				loadActiveCompaniesChart();
				loadHistoryPerStateAndCity();
				loadHistoryPerModelChart();
				ctrl.loadingData = false;
			}, remarketApp.handleError);
		};

		function loadActiveUsersChart() {
			var data =  [{
				name: 'Lojistas com lance',
				y: ctrl.dashboardData.storeUsersWithBid
			}, {
				name: 'Lojistas sem lance',
				y: ctrl.dashboardData.storeUsers - ctrl.dashboardData.storeUsersWithBid
			}];
			loadPieChart('admin-dashboard-store-users', 'Cadastro de lojistas', data, ctrl.dashboardData.storeUsers);
		}

		function loadActiveCompaniesChart() {
			var data =  [{
				name: 'Empresas com itens negociados',
				y: ctrl.dashboardData.companyUsersWithAuctionItem
			}, {
				name: 'Empresas sem itens negociados',
				y: ctrl.dashboardData.companyUsers - ctrl.dashboardData.companyUsersWithAuctionItem
			}];
			loadPieChart('admin-dashboard-company-users', 'Cadastro de empresas', data, ctrl.dashboardData.companyUsers);
		}

		function loadPieChart(elementId, title, data, total) {
			Highcharts.chart(elementId, {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
					backgroundColor: null
				},
				title: {
					text: title
				},
				tooltip: {
					pointFormat: '<b>{point.percentage:.1f}%</b> ({point.y}/' + total + ')'
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false
						},
						showInLegend: true
					}
				},
				series: [{
					name: '',
					colorByPoint: true,
					data: data
				}],
				credits: {
					enabled: false
				}
			});
		}

		function loadHistoryPerStateAndCity() {
			var yAxis = {
				title: {
					text: 'Quantidade'
				}
			};

			var drilldown = {
				series: []
			};

			_.each(ctrl.dashboardData.historyPerState.data, function (state) {
				state.y = state.value;
				state.drilldown = state.name;
				var stateDrilldown = {
					name: state.name,
					id: state.name,
					data: []
				};
				drilldown.series.push(stateDrilldown);

				_.each(ctrl.dashboardData.historyPerCity.data, function (city) {
					city.y = city.value;
					if (city.group == state.name) {
						stateDrilldown.data.push([city.name, city.value]);
					}
				});
			});

			var series = [{
				data: ctrl.dashboardData.historyPerState.data
			}];
			
			loadColumnChart('history-per-state', 'Vendas por região', 'Clique em uma região para ver detalhes', null,
				yAxis, series, true, drilldown);
		}

		function loadHistoryPerModelChart() {
			var yAxis = {
				title: {
					text: 'Quantidade'
				}
			};
			_.each(ctrl.dashboardData.historyPerModel.data, function (entry) {
				entry.y = entry.value;
			});
			var series = [{
				data: ctrl.dashboardData.historyPerModel.data
			}];

			loadColumnChart('history-per-model', 'Negociações por modelo', null,
				ctrl.dashboardData.historyPerModel.categories, yAxis, series, true);
		}

		function loadColumnChart(elementId, title, subtitle, categories, yAxis, series, disableLegend, drilldown) {
			var chart = {
				chart: {
					backgroundColor: null,
					height: 300,
					type: 'column'
				},
				title: {
					text: title
				},
				subtitle: {
					text: subtitle
				},
				tooltip: {
					formatter: function() {
						return 'Veículos vendidos' + ': ' + this.y;
					}
				},
				xAxis: {
					type: 'category',
					categories: categories
				},
				yAxis: yAxis,
				legend: {
					labelFormatter: function () {
						if (this.name.length > 20) {
							return this.name.substr(0,20) + '...';
						}
						return this.name;
					},
					enabled: !disableLegend
				},
				series: series,
				drilldown: drilldown,
				credits: {
					enabled: false
				}
			};
			if (!drilldown) {
				delete chart.drilldown;
			}
			Highcharts.chart(elementId, chart);
		}
		
		function init() {
			if (!remarketApp.getUser()) {
				return;
			}
			ctrl.reloadDashboard();
		}
		$scope.$on('remarket-login', init);
		init();
	};
	adminDashboardCtrl.$inject = ['$http', '$scope'];
	remarketApp.controller('adminDashboardCtrl', adminDashboardCtrl);

	var adminDashboardStateProvider = function($stateProvider) {
		$stateProvider.state('landingPage.panel.admin-dashboard', {
			url: '/dashboard/admin',
			templateUrl: 'view/panel/dashboard/admin/dashboard.html',
			controller: adminDashboardCtrl,
			controllerAs: 'adminDashboardCtrl'
		});
	};
	adminDashboardStateProvider.$inject = ['$stateProvider'];
	remarketApp.config(adminDashboardStateProvider);
}());