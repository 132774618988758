(function () {
	var remarketApp = angular.module('remarketApp');

	var notificationLinkCtrl = function($state) {
		var ctrl = this;

		ctrl.link = getLink(ctrl.notification);

		function getLink(notification) {
			switch (notification.notificationType) {
				case 'COMPANY_CREATION':
					return $state.href('landingPage.panel.company-edit', {
						companyId: notification.entityId
					});
				case 'INSPECTION_CHECKOUT':
					return $state.href('landingPage.panel.inspections-items-edit', {
						inspectionId: notification.entityId
					});
				default:
					return null;
			}
		}
	};
	notificationLinkCtrl.$inject = ['$state'];

	remarketApp.component('notificationLink', {
		bindings: {
			notification: '<'
		},
		templateUrl: 'component/notification-link/notification-link.html',
		controller: notificationLinkCtrl
	});
}());