(function() {
	var remarketApp = angular.module('remarketApp');

	remarketApp.filter('plate', function () {
		return function (input) {
			if (!input) {
				return '';
			}
			if (input.length != 7) {
				return 'Placa desconhecida';
			}
			return input.substring(0, 3) + '-' + input.substring(3);
		};
	});
}());