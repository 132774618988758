(function() {
	var remarketApp = angular.module('remarketApp');

	var inspectionDetailsCtrl = function($attrs, $http, $scope) {
		var ctrl = this;
		ctrl.closeIcon = $attrs.closeIcon || 'fa fa-angle-double-left';
		ctrl.closeMessage = $attrs.closeMessage || 'Voltar';
		
		ctrl.goBack = function() {
			ctrl.inspectionId = null;
			ctrl.onClose();
		};
		
		ctrl.goToPicture = function(index) {
			ctrl.currentPicture = ctrl.pictures[index];
		};
		
		ctrl.showPartPhoto = function(item) {
			return item.photo && item.photo.photo;
		}
		
		function clearPictures() {
			delete ctrl.pictures;
			delete ctrl.currentPicture;
		}
		
		function loadPictures() {
			ctrl.pictures = [];
			
			if (ctrl.inspection.frontPicture) {
				ctrl.pictures.push(ctrl.inspection.frontPicture);
			}
			
			if (ctrl.inspection.backPicture) {
				ctrl.pictures.push(ctrl.inspection.backPicture);
			}
			
			if (ctrl.inspection.rightPicture) {
				ctrl.pictures.push(ctrl.inspection.rightPicture);
			}
			
			if (ctrl.inspection.leftPicture) {
				ctrl.pictures.push(ctrl.inspection.leftPicture);
			}
			
			if (ctrl.inspection.carDocumentPicture) {
				ctrl.pictures.push(ctrl.inspection.carDocumentPicture);
			}
			
			if (ctrl.inspection.preCautionaryInspection) {
				ctrl.pictures.push(ctrl.inspection.preCautionaryInspection);
			}
			
			ctrl.currentPicture = ctrl.pictures[0];
		}

		ctrl.showThumbnail = function() {
			if (!ctrl.currentPicture) {
				return;
			}

			if (!ctrl.currentPicture.photoThumbnail) {
				return ctrl.currentPicture.photo;
			} else {
				return ctrl.currentPicture.photoThumbnail;
			}
		}

		$scope.$watch(function() {
			return ctrl.inspectionId;
		}, function() {
			if (ctrl.inspectionId) {
				clearPictures();
				$http.get('/api/inspection/' + ctrl.inspectionId).then(function(response) {
					ctrl.inspection = response.data;
					ctrl.description = ctrl.inspection.version.brand.name + ' ' + ctrl.inspection.version.model.name + ' ' + 
									   ctrl.inspection.version.version.year + ' ' +ctrl.inspection.version.version.fuelType;
					loadPictures();
				}, remarketApp.handleError);
			}
		});
	};
	inspectionDetailsCtrl.$inject = ['$attrs', '$http', '$scope'];

	remarketApp.component('inspectionDetails', {
		bindings: {
			inspectionId: '<',
			onClose: '&',
			closeMessage: '<',
			closeIcon: '@',
		},
		templateUrl: 'component/inspection-details/inspection-details.html',
		controller: inspectionDetailsCtrl
	});
}());
